import { graphql } from 'gatsby';
import React from 'react';
import CtaCardLayout from '@presentation/CtaCard';
import getCtaModel from '@utils/getCtaModel';
import { CtaCardTheme } from '@presentation/CtaCard/CtaCard.types';
const CtaCard = ({ data }) => {
    const [cta1Text, cta1To, cta1IsExternal] = getCtaModel(data.elements?.text_1?.value, data.elements?.link_1__external?.value, data.elements?.link_1__internal?.linked_items?.[0]);
    const [cta2Text, cta2To, cta2IsExternal] = getCtaModel(data.elements?.text_2?.value, data.elements?.link_2__external?.value, data.elements?.link_2__internal?.linked_items?.[0]);
    const eventName1 = data.elements?.event_name_1?.value;
    const eventName2 = data.elements?.event_name_2?.value;
    const themeStr = data.elements?.theme__theme?.value?.[0].codename;
    const theme = themeStr ? themeStr : CtaCardTheme.None;
    return (<CtaCardLayout content={data.elements?.content?.value} cta1EventName={eventName1} cta1IsExternal={cta1IsExternal} cta1Text={cta1Text} cta1To={cta1To} cta2EventName={eventName2} cta2IsExternal={cta2IsExternal} cta2Text={cta2Text} cta2To={cta2To} image={data.elements?.image?.value?.[0]?.fluid} subtitle={data.elements?.subtitle?.value} theme={theme} title={data.elements?.title?.value || ''} footer={data.elements?.footer?.value} alignment={data.elements?.content_alignment?.value?.[0]?.codename}/>);
};
export default CtaCard;
export const KontentItemCtaCardFragment = graphql `
  fragment KontentItemCtaCardFragment on KontentItemCtaCard {
    elements {
      content {
        value
      }
      event_name_1 {
        value
      }
      event_name_2 {
        value
      }
      image {
        value {
          fluid(maxWidth: 1600) {
            ...KontentAssetFluid_withWebp
          }
        }
      }
      link_1__external {
        value
      }
      link_1__internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      link_2__external {
        value
      }
      link_2__internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      subtitle {
        value
      }
      text_1 {
        value
      }
      text_2 {
        value
      }
      theme__theme {
        value {
          codename
        }
      }
      title {
        value
      }
      content_alignment {
        value {
          codename
        }
      }
      footer {
        value
      }
    }
  }
`;
