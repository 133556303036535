import { graphql } from 'gatsby';
import React from 'react';
import TextWithImageLayout from '@presentation/TextWithImage';
const TextWithImage = ({ data }) => {
    return (<TextWithImageLayout headline={data.elements?.headline?.value} image={data.elements?.image?.value?.[0]?.fluid} text={data.elements?.text}/>);
};
export default TextWithImage;
export const KontentItemTextWithImageFragment = graphql `
  fragment KontentItemTextWithImageFragment on KontentItemTextWithImage {
    elements {
      headline {
        value
      }
      text {
        images {
          description
          imageId
          fluid(maxWidth: 1200) {
            ...KontentAssetFluid_withWebp
          }
        }
        links {
          codename
          linkId
          type
          urlSlug
        }
        resolvedData {
          html
        }
      }
      image {
        value {
          fluid(maxWidth: 600) {
            ...KontentAssetFluid_withWebp
          }
        }
      }
    }
  }
`;
