import classNames from 'classnames';
import React, { useRef } from 'react';
import Image from 'gatsby-image';
import useFadeUp from '@hooks/animation/useFadeUp';
import { motion } from 'framer-motion';
const Quote = ({ text = '', name, photo, position, company, codename, }) => {
    const className = classNames('quote', {
        ['quote--kenticospecial']: codename === 'WhatMakesKenticoSpecial',
    });
    const containerRef = useRef(null);
    const quoteProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    return (<section className={className}>
      <div className="quote__container" ref={containerRef}>
        <motion.figure className="quote__figure" {...quoteProps}>
          <div className="quote__border"></div>
          <div className="quote__wrapper">
            <Image className="quote__photo" fluid={photo}/>
            <div className="quote__text">
              <blockquote className="quote__blockquote" dangerouslySetInnerHTML={{ __html: text }}/>
              <figcaption className="quote__caption">
                {name}
                {position ? `, ${position}` : ''}
                {company ? ` at ${company}` : ''}
              </figcaption>
            </div>
          </div>
        </motion.figure>
      </div>
    </section>);
};
export default Quote;
