import { motion } from 'framer-motion';
import React, { useRef } from 'react';
import useFadeUp from '@hooks/animation/useFadeUp';
import { Grid, GridColumn } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import SectionWrapper from '@presentation/SectionWrapper';
const RichContent = ({ content }) => {
    const containerRef = useRef(null);
    const animProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    return (<SectionWrapper>
      <Grid layout={GridLayout.FULL_INDENT}>
        <GridColumn>
          <motion.div ref={containerRef} className="rich-content" dangerouslySetInnerHTML={{ __html: content || '' }} {...animProps}/>
        </GridColumn>
      </Grid>
    </SectionWrapper>);
};
export default RichContent;
