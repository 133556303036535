export var FormFieldType;
(function (FormFieldType) {
    FormFieldType["Checkbox"] = "Kentico.CheckBox";
    FormFieldType["ConsentAgreement"] = "CustomConsentAgreement";
    FormFieldType["Email"] = "Kentico.EmailInput";
    FormFieldType["FileUploader"] = "Kentico.FileUploader";
    FormFieldType["IntInput"] = "Kentico.IntInput";
    FormFieldType["PhoneNumber"] = "PhoneInput";
    FormFieldType["RadioButton"] = "Kentico.RadioButtons";
    FormFieldType["MultipleChoice"] = "MultipleChoice";
    FormFieldType["Select"] = "Kentico.DropDown";
    FormFieldType["TextArea"] = "Kentico.TextArea";
    FormFieldType["TextInput"] = "Kentico.TextInput";
    FormFieldType["CountrySelector"] = "CountrySelector";
    FormFieldType["Unknown"] = "Unknown";
})(FormFieldType || (FormFieldType = {}));
