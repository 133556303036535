export var GridLayout;
(function (GridLayout) {
    GridLayout[GridLayout["FULL"] = 0] = "FULL";
    GridLayout[GridLayout["HALF"] = 1] = "HALF";
    GridLayout[GridLayout["TWO_SIX"] = 2] = "TWO_SIX";
    GridLayout[GridLayout["FULL_INDENT"] = 3] = "FULL_INDENT";
    GridLayout[GridLayout["HALF_INDENT"] = 4] = "HALF_INDENT";
    GridLayout[GridLayout["THIRD"] = 5] = "THIRD";
    GridLayout[GridLayout["TWO_FOUR"] = 6] = "TWO_FOUR";
    GridLayout[GridLayout["FOOTER_ONE"] = 7] = "FOOTER_ONE";
    GridLayout[GridLayout["FOOTER_TWO"] = 8] = "FOOTER_TWO";
    GridLayout[GridLayout["FOUR"] = 9] = "FOUR";
})(GridLayout || (GridLayout = {}));
