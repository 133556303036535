import React, { forwardRef } from 'react';
import { GridLayout } from './Grid.types';
const GridBase = (props, ref) => {
    const { children, className = '', flush = false, layout = GridLayout.FULL, } = props;
    const containerClassName = getGridContainerClassname(layout, flush);
    return (<div ref={ref} className={`${className} ${containerClassName}`.trim()}>
      {children}
    </div>);
};
const GridColumn = ({ children, className = '', }) => {
    return <div className={`grid-column ${className}`.trim()}>{children}</div>;
};
const Grid = forwardRef(GridBase);
Grid.displayName = 'Grid';
/**
 * Get the CSS class values for the specified grid layout.
 * @param layout The grid layout
 */
function getGridContainerClassname(layout, flush) {
    let className = '';
    switch (layout) {
        case GridLayout.FULL:
            className = 'grid-container grid-container--full';
            break;
        case GridLayout.HALF:
            className = 'grid-container grid-container--half';
            break;
        case GridLayout.TWO_SIX:
            className = 'grid-container grid-container--two-six';
            break;
        case GridLayout.FULL_INDENT:
            className = 'grid-container grid-container--full grid-container--indent';
            break;
        case GridLayout.HALF_INDENT:
            className = 'grid-container grid-container--half grid-container--indent';
            break;
        case GridLayout.THIRD:
            className = 'grid-container grid-container--third';
            break;
        case GridLayout.TWO_FOUR:
            className = 'grid-container grid-container--two-four';
            break;
        case GridLayout.FOOTER_ONE:
            className = 'grid-container grid-container--footer-one';
            break;
        case GridLayout.FOOTER_TWO:
            className = 'grid-container grid-container--footer-two';
            break;
        case GridLayout.FOUR:
            className = 'grid-container grid-container--four';
            break;
        default:
            throw new Error('Invalid value provided for enum GridLayout.');
    }
    return `${className} ${flush ? 'grid-container--flush' : ''}`;
}
export { Grid, GridColumn, getGridContainerClassname };
