const countryAndStateList = [
    {
        countryName: 'Afghanistan',
        countryDisplayName: 'Afghanistan',
    },
    {
        countryName: 'Aland Islands',
        countryDisplayName: 'Aland Islands',
    },
    {
        countryName: 'Albania',
        countryDisplayName: 'Albania',
    },
    {
        countryName: 'Algeria',
        countryDisplayName: 'Algeria',
    },
    {
        countryName: 'American Samoa',
        countryDisplayName: 'American Samoa',
    },
    {
        countryName: 'Andorra',
        countryDisplayName: 'Andorra',
    },
    {
        countryName: 'Angola',
        countryDisplayName: 'Angola',
    },
    {
        countryName: 'Anguilla',
        countryDisplayName: 'Anguilla',
    },
    {
        countryName: 'Antarctica',
        countryDisplayName: 'Antarctica',
    },
    {
        countryName: 'Antigua and Barbuda',
        countryDisplayName: 'Antigua and Barbuda',
    },
    {
        countryName: 'Argentina',
        countryDisplayName: 'Argentina',
    },
    {
        countryName: 'Armenia',
        countryDisplayName: 'Armenia',
    },
    {
        countryName: 'Aruba',
        countryDisplayName: 'Aruba',
    },
    {
        countryName: 'Australia',
        countryDisplayName: 'Australia',
        countryStates: [
            {
                stateName: 'Australian Capital Territory',
                stateDisplayName: 'Australian Capital Territory',
            },
            {
                stateName: 'New South Wales',
                stateDisplayName: 'New South Wales',
            },
            {
                stateName: 'Northern Territory',
                stateDisplayName: 'Northern Territory',
            },
            {
                stateName: 'Queensland',
                stateDisplayName: 'Queensland',
            },
            {
                stateName: 'South Australia',
                stateDisplayName: 'South Australia',
            },
            {
                stateName: 'Tasmania',
                stateDisplayName: 'Tasmania',
            },
            {
                stateName: 'Victoria',
                stateDisplayName: 'Victoria',
            },
            {
                stateName: 'Western Australia',
                stateDisplayName: 'Western Australia',
            },
        ],
    },
    {
        countryName: 'Austria',
        countryDisplayName: 'Austria',
    },
    {
        countryName: 'Azerbaijan',
        countryDisplayName: 'Azerbaijan',
    },
    {
        countryName: 'Bahamas',
        countryDisplayName: 'Bahamas',
    },
    {
        countryName: 'Bahrain',
        countryDisplayName: 'Bahrain',
    },
    {
        countryName: 'Bangladesh',
        countryDisplayName: 'Bangladesh',
    },
    {
        countryName: 'Barbados',
        countryDisplayName: 'Barbados',
    },
    {
        countryName: 'Belarus',
        countryDisplayName: 'Belarus',
    },
    {
        countryName: 'Belgium',
        countryDisplayName: 'Belgium',
    },
    {
        countryName: 'Belize',
        countryDisplayName: 'Belize',
    },
    {
        countryName: 'Benin',
        countryDisplayName: 'Benin',
    },
    {
        countryName: 'Bermuda',
        countryDisplayName: 'Bermuda',
    },
    {
        countryName: 'Bhutan',
        countryDisplayName: 'Bhutan',
    },
    {
        countryName: 'Bolivia',
        countryDisplayName: 'Bolivia',
    },
    {
        countryName: 'Bonaire Saint Eustatius and Saba',
        countryDisplayName: 'Bonaire Saint Eustatius and Saba',
    },
    {
        countryName: 'Bosnia and Herzegovina',
        countryDisplayName: 'Bosnia and Herzegovina',
    },
    {
        countryName: 'Botswana',
        countryDisplayName: 'Botswana',
    },
    {
        countryName: 'Bouvet Island',
        countryDisplayName: 'Bouvet Island',
    },
    {
        countryName: 'Brazil',
        countryDisplayName: 'Brazil',
    },
    {
        countryName: 'British Indian Ocean Territory',
        countryDisplayName: 'British Indian Ocean Territory',
    },
    {
        countryName: 'Brunei',
        countryDisplayName: 'Brunei',
    },
    {
        countryName: 'Bulgaria',
        countryDisplayName: 'Bulgaria',
    },
    {
        countryName: 'Burkina Faso',
        countryDisplayName: 'Burkina Faso',
    },
    {
        countryName: 'Burundi',
        countryDisplayName: 'Burundi',
    },
    {
        countryName: 'Cambodia',
        countryDisplayName: 'Cambodia',
    },
    {
        countryName: 'Cameroon',
        countryDisplayName: 'Cameroon',
    },
    {
        countryName: 'Canada',
        countryDisplayName: 'Canada',
        countryStates: [
            {
                stateName: 'Alberta',
                stateDisplayName: 'Alberta',
            },
            {
                stateName: 'British Columbia',
                stateDisplayName: 'British Columbia',
            },
            {
                stateName: 'Manitoba',
                stateDisplayName: 'Manitoba',
            },
            {
                stateName: 'New Brunswick',
                stateDisplayName: 'New Brunswick',
            },
            {
                stateName: 'Newfoundland and Labrador',
                stateDisplayName: 'Newfoundland and Labrador',
            },
            {
                stateName: 'Northwest Territories',
                stateDisplayName: 'Northwest Territories',
            },
            {
                stateName: 'Nova Scotia',
                stateDisplayName: 'Nova Scotia',
            },
            {
                stateName: 'Nunavut',
                stateDisplayName: 'Nunavut',
            },
            {
                stateName: 'Ontario',
                stateDisplayName: 'Ontario',
            },
            {
                stateName: 'Prince Edward Island',
                stateDisplayName: 'Prince Edward Island',
            },
            {
                stateName: 'Quebec',
                stateDisplayName: 'Quebec',
            },
            {
                stateName: 'Saskatchewan',
                stateDisplayName: 'Saskatchewan',
            },
            {
                stateName: 'Yukon',
                stateDisplayName: 'Yukon',
            },
        ],
    },
    {
        countryName: 'Cape Verde',
        countryDisplayName: 'Cape Verde',
    },
    {
        countryName: 'Cayman Islands',
        countryDisplayName: 'Cayman Islands',
    },
    {
        countryName: 'Central African Republic',
        countryDisplayName: 'Central African Republic',
    },
    {
        countryName: 'Chad',
        countryDisplayName: 'Chad',
    },
    {
        countryName: 'Chile',
        countryDisplayName: 'Chile',
    },
    {
        countryName: 'China',
        countryDisplayName: 'China',
    },
    {
        countryName: 'Christmas Island',
        countryDisplayName: 'Christmas Island',
    },
    {
        countryName: 'Cocos (Keeling) Islands',
        countryDisplayName: 'Cocos (Keeling) Islands',
    },
    {
        countryName: 'Colombia',
        countryDisplayName: 'Colombia',
    },
    {
        countryName: 'Comoros',
        countryDisplayName: 'Comoros',
    },
    {
        countryName: 'Cook Islands',
        countryDisplayName: 'Cook Islands',
    },
    {
        countryName: 'Costa Rica',
        countryDisplayName: 'Costa Rica',
    },
    {
        countryName: 'Croatia',
        countryDisplayName: 'Croatia',
    },
    {
        countryName: 'Cuba',
        countryDisplayName: 'Cuba',
    },
    {
        countryName: 'Curaçao',
        countryDisplayName: 'Curaçao',
    },
    {
        countryName: 'Cyprus',
        countryDisplayName: 'Cyprus',
    },
    {
        countryName: 'Czech Republic',
        countryDisplayName: 'Czech Republic',
    },
    {
        countryName: 'Democratic Republic of the Congo',
        countryDisplayName: 'Democratic Republic of the Congo',
    },
    {
        countryName: 'Denmark',
        countryDisplayName: 'Denmark',
    },
    {
        countryName: 'Djibouti',
        countryDisplayName: 'Djibouti',
    },
    {
        countryName: 'Dominica',
        countryDisplayName: 'Dominica',
    },
    {
        countryName: 'Dominican Republic',
        countryDisplayName: 'Dominican Republic',
    },
    {
        countryName: 'East Timor',
        countryDisplayName: 'East Timor',
    },
    {
        countryName: 'Ecuador',
        countryDisplayName: 'Ecuador',
    },
    {
        countryName: 'Egypt',
        countryDisplayName: 'Egypt',
    },
    {
        countryName: 'El Salvador',
        countryDisplayName: 'El Salvador',
    },
    {
        countryName: 'Equatorial Guinea',
        countryDisplayName: 'Equatorial Guinea',
    },
    {
        countryName: 'Eritrea',
        countryDisplayName: 'Eritrea',
    },
    {
        countryName: 'Estonia',
        countryDisplayName: 'Estonia',
    },
    {
        countryName: 'Ethiopia',
        countryDisplayName: 'Ethiopia',
    },
    {
        countryName: 'Falkland Islands (Malvinas)',
        countryDisplayName: 'Falkland Islands (Malvinas)',
    },
    {
        countryName: 'Faroe Islands',
        countryDisplayName: 'Faroe Islands',
    },
    {
        countryName: 'Fiji',
        countryDisplayName: 'Fiji',
    },
    {
        countryName: 'Finland',
        countryDisplayName: 'Finland',
    },
    {
        countryName: 'France',
        countryDisplayName: 'France',
    },
    {
        countryName: 'French Guiana',
        countryDisplayName: 'French Guiana',
    },
    {
        countryName: 'French Polynesia',
        countryDisplayName: 'French Polynesia',
    },
    {
        countryName: 'French Southern Territories',
        countryDisplayName: 'French Southern Territories',
    },
    {
        countryName: 'Gabon',
        countryDisplayName: 'Gabon',
    },
    {
        countryName: 'Gambia',
        countryDisplayName: 'Gambia',
    },
    {
        countryName: 'Georgia',
        countryDisplayName: 'Georgia',
    },
    {
        countryName: 'Germany',
        countryDisplayName: 'Germany',
    },
    {
        countryName: 'Ghana',
        countryDisplayName: 'Ghana',
    },
    {
        countryName: 'Gibraltar',
        countryDisplayName: 'Gibraltar',
    },
    {
        countryName: 'Greece',
        countryDisplayName: 'Greece',
    },
    {
        countryName: 'Greenland',
        countryDisplayName: 'Greenland',
    },
    {
        countryName: 'Grenada',
        countryDisplayName: 'Grenada',
    },
    {
        countryName: 'Guadeloupe',
        countryDisplayName: 'Guadeloupe',
    },
    {
        countryName: 'Guam',
        countryDisplayName: 'Guam',
    },
    {
        countryName: 'Guatemala',
        countryDisplayName: 'Guatemala',
    },
    {
        countryName: 'Guernsey',
        countryDisplayName: 'Guernsey',
    },
    {
        countryName: 'Guinea',
        countryDisplayName: 'Guinea',
    },
    {
        countryName: 'Guinea-Bissau',
        countryDisplayName: 'Guinea-Bissau',
    },
    {
        countryName: 'Guyana',
        countryDisplayName: 'Guyana',
    },
    {
        countryName: 'Haiti',
        countryDisplayName: 'Haiti',
    },
    {
        countryName: 'Heard Island and McDonald Islands',
        countryDisplayName: 'Heard Island and McDonald Islands',
    },
    {
        countryName: 'Holy See',
        countryDisplayName: 'Holy See',
    },
    {
        countryName: 'Honduras',
        countryDisplayName: 'Honduras',
    },
    {
        countryName: 'Hong Kong',
        countryDisplayName: 'Hong Kong',
    },
    {
        countryName: 'Hungary',
        countryDisplayName: 'Hungary',
    },
    {
        countryName: 'Iceland',
        countryDisplayName: 'Iceland',
    },
    {
        countryName: 'India',
        countryDisplayName: 'India',
    },
    {
        countryName: 'Indonesia',
        countryDisplayName: 'Indonesia',
    },
    {
        countryName: 'Iran',
        countryDisplayName: 'Iran',
    },
    {
        countryName: 'Iraq',
        countryDisplayName: 'Iraq',
    },
    {
        countryName: 'Ireland',
        countryDisplayName: 'Ireland',
    },
    {
        countryName: 'Isle of Man',
        countryDisplayName: 'Isle of Man',
    },
    {
        countryName: 'Israel',
        countryDisplayName: 'Israel',
    },
    {
        countryName: 'Italy',
        countryDisplayName: 'Italy',
    },
    {
        countryName: 'Ivory Coast',
        countryDisplayName: 'Ivory Coast',
    },
    {
        countryName: 'Jamaica',
        countryDisplayName: 'Jamaica',
    },
    {
        countryName: 'Japan',
        countryDisplayName: 'Japan',
    },
    {
        countryName: 'Jersey',
        countryDisplayName: 'Jersey',
    },
    {
        countryName: 'Jordan',
        countryDisplayName: 'Jordan',
    },
    {
        countryName: 'Kazakhstan',
        countryDisplayName: 'Kazakhstan',
    },
    {
        countryName: 'Kenya',
        countryDisplayName: 'Kenya',
    },
    {
        countryName: 'Kiribati',
        countryDisplayName: 'Kiribati',
    },
    {
        countryName: 'Kosovo',
        countryDisplayName: 'Kosovo',
    },
    {
        countryName: 'Kuwait',
        countryDisplayName: 'Kuwait',
    },
    {
        countryName: 'Kyrgyzstan',
        countryDisplayName: 'Kyrgyzstan',
    },
    {
        countryName: 'Laos',
        countryDisplayName: 'Laos',
    },
    {
        countryName: 'Latvia',
        countryDisplayName: 'Latvia',
    },
    {
        countryName: 'Lebanon',
        countryDisplayName: 'Lebanon',
    },
    {
        countryName: 'Lesotho',
        countryDisplayName: 'Lesotho',
    },
    {
        countryName: 'Liberia',
        countryDisplayName: 'Liberia',
    },
    {
        countryName: 'Libya',
        countryDisplayName: 'Libya',
    },
    {
        countryName: 'Liechtenstein',
        countryDisplayName: 'Liechtenstein',
    },
    {
        countryName: 'Lithuania',
        countryDisplayName: 'Lithuania',
    },
    {
        countryName: 'Luxembourg',
        countryDisplayName: 'Luxembourg',
    },
    {
        countryName: 'Macao',
        countryDisplayName: 'Macao',
    },
    {
        countryName: 'Macedonia',
        countryDisplayName: 'Macedonia',
    },
    {
        countryName: 'Madagascar',
        countryDisplayName: 'Madagascar',
    },
    {
        countryName: 'Malawi',
        countryDisplayName: 'Malawi',
    },
    {
        countryName: 'Malaysia',
        countryDisplayName: 'Malaysia',
    },
    {
        countryName: 'Maldives',
        countryDisplayName: 'Maldives',
    },
    {
        countryName: 'Mali',
        countryDisplayName: 'Mali',
    },
    {
        countryName: 'Malta',
        countryDisplayName: 'Malta',
    },
    {
        countryName: 'Marshall Islands',
        countryDisplayName: 'Marshall Islands',
    },
    {
        countryName: 'Martinique',
        countryDisplayName: 'Martinique',
    },
    {
        countryName: 'Mauritania',
        countryDisplayName: 'Mauritania',
    },
    {
        countryName: 'Mauritius',
        countryDisplayName: 'Mauritius',
    },
    {
        countryName: 'Mayotte',
        countryDisplayName: 'Mayotte',
    },
    {
        countryName: 'Mexico',
        countryDisplayName: 'Mexico',
    },
    {
        countryName: 'Micronesia',
        countryDisplayName: 'Micronesia',
    },
    {
        countryName: 'Moldova',
        countryDisplayName: 'Moldova',
    },
    {
        countryName: 'Monaco',
        countryDisplayName: 'Monaco',
    },
    {
        countryName: 'Mongolia',
        countryDisplayName: 'Mongolia',
    },
    {
        countryName: 'Montenegro',
        countryDisplayName: 'Montenegro',
    },
    {
        countryName: 'Montserrat',
        countryDisplayName: 'Montserrat',
    },
    {
        countryName: 'Morocco',
        countryDisplayName: 'Morocco',
    },
    {
        countryName: 'Mozambique',
        countryDisplayName: 'Mozambique',
    },
    {
        countryName: 'Myanmar',
        countryDisplayName: 'Myanmar',
    },
    {
        countryName: 'Namibia',
        countryDisplayName: 'Namibia',
    },
    {
        countryName: 'Nauru',
        countryDisplayName: 'Nauru',
    },
    {
        countryName: 'Nepal',
        countryDisplayName: 'Nepal',
    },
    {
        countryName: 'Netherlands',
        countryDisplayName: 'Netherlands',
    },
    {
        countryName: 'New Caledonia',
        countryDisplayName: 'New Caledonia',
    },
    {
        countryName: 'New Zealand',
        countryDisplayName: 'New Zealand',
    },
    {
        countryName: 'Nicaragua',
        countryDisplayName: 'Nicaragua',
    },
    {
        countryName: 'Niger',
        countryDisplayName: 'Niger',
    },
    {
        countryName: 'Nigeria',
        countryDisplayName: 'Nigeria',
    },
    {
        countryName: 'Niue',
        countryDisplayName: 'Niue',
    },
    {
        countryName: 'Norfolk Island',
        countryDisplayName: 'Norfolk Island',
    },
    {
        countryName: 'North Korea',
        countryDisplayName: 'North Korea',
    },
    {
        countryName: 'Northern Mariana Islands',
        countryDisplayName: 'Northern Mariana Islands',
    },
    {
        countryName: 'Norway',
        countryDisplayName: 'Norway',
    },
    {
        countryName: 'Oman',
        countryDisplayName: 'Oman',
    },
    {
        countryName: 'Pakistan',
        countryDisplayName: 'Pakistan',
    },
    {
        countryName: 'Palau',
        countryDisplayName: 'Palau',
    },
    {
        countryName: 'Palestinian Territory',
        countryDisplayName: 'Palestinian Territory',
    },
    {
        countryName: 'Panama',
        countryDisplayName: 'Panama',
    },
    {
        countryName: 'Papua New Guinea',
        countryDisplayName: 'Papua New Guinea',
    },
    {
        countryName: 'Paraguay',
        countryDisplayName: 'Paraguay',
    },
    {
        countryName: 'Peru',
        countryDisplayName: 'Peru',
    },
    {
        countryName: 'Philippines',
        countryDisplayName: 'Philippines',
    },
    {
        countryName: 'Pitcairn',
        countryDisplayName: 'Pitcairn',
    },
    {
        countryName: 'Poland',
        countryDisplayName: 'Poland',
    },
    {
        countryName: 'Portugal',
        countryDisplayName: 'Portugal',
    },
    {
        countryName: 'Puerto Rico',
        countryDisplayName: 'Puerto Rico',
    },
    {
        countryName: 'Qatar',
        countryDisplayName: 'Qatar',
    },
    {
        countryName: 'Republic of the Congo',
        countryDisplayName: 'Republic of the Congo',
    },
    {
        countryName: 'Reunion',
        countryDisplayName: 'Reunion',
    },
    {
        countryName: 'Romania',
        countryDisplayName: 'Romania',
    },
    {
        countryName: 'Russia',
        countryDisplayName: 'Russia',
    },
    {
        countryName: 'Rwanda',
        countryDisplayName: 'Rwanda',
    },
    {
        countryName: 'Saint Barthélemy',
        countryDisplayName: 'Saint Barthélemy',
    },
    {
        countryName: 'Saint Helena',
        countryDisplayName: 'Saint Helena',
    },
    {
        countryName: 'Saint Kitts and Nevis',
        countryDisplayName: 'Saint Kitts and Nevis',
    },
    {
        countryName: 'Saint Lucia',
        countryDisplayName: 'Saint Lucia',
    },
    {
        countryName: 'Saint Martin (French part)',
        countryDisplayName: 'Saint Martin (French part)',
    },
    {
        countryName: 'Saint Pierre and Miquelon',
        countryDisplayName: 'Saint Pierre and Miquelon',
    },
    {
        countryName: 'Saint Vincent and the Grenadines',
        countryDisplayName: 'Saint Vincent and the Grenadines',
    },
    {
        countryName: 'Samoa',
        countryDisplayName: 'Samoa',
    },
    {
        countryName: 'San Marino',
        countryDisplayName: 'San Marino',
    },
    {
        countryName: 'Sao Tome and Principe',
        countryDisplayName: 'Sao Tome and Principe',
    },
    {
        countryName: 'Saudi Arabia',
        countryDisplayName: 'Saudi Arabia',
    },
    {
        countryName: 'Senegal',
        countryDisplayName: 'Senegal',
    },
    {
        countryName: 'Serbia',
        countryDisplayName: 'Serbia',
    },
    {
        countryName: 'Seychelles',
        countryDisplayName: 'Seychelles',
    },
    {
        countryName: 'Sierra Leone',
        countryDisplayName: 'Sierra Leone',
    },
    {
        countryName: 'Singapore',
        countryDisplayName: 'Singapore',
    },
    {
        countryName: 'Sint Maarten (Dutch part)',
        countryDisplayName: 'Sint Maarten (Dutch part)',
    },
    {
        countryName: 'Slovakia',
        countryDisplayName: 'Slovakia',
    },
    {
        countryName: 'Slovenia',
        countryDisplayName: 'Slovenia',
    },
    {
        countryName: 'Solomon Islands',
        countryDisplayName: 'Solomon Islands',
    },
    {
        countryName: 'Somalia',
        countryDisplayName: 'Somalia',
    },
    {
        countryName: 'South Africa',
        countryDisplayName: 'South Africa',
    },
    {
        countryName: 'South Georgia and the South Sandwich Islands',
        countryDisplayName: 'South Georgia and the South Sandwich Islands',
    },
    {
        countryName: 'South Korea',
        countryDisplayName: 'South Korea',
    },
    {
        countryName: 'South Sudan',
        countryDisplayName: 'South Sudan',
    },
    {
        countryName: 'Spain',
        countryDisplayName: 'Spain',
    },
    {
        countryName: 'Sri Lanka',
        countryDisplayName: 'Sri Lanka',
    },
    {
        countryName: 'Sudan',
        countryDisplayName: 'Sudan',
    },
    {
        countryName: 'Suriname',
        countryDisplayName: 'Suriname',
    },
    {
        countryName: 'Svalbard and Jan Mayen',
        countryDisplayName: 'Svalbard and Jan Mayen',
    },
    {
        countryName: 'Swaziland',
        countryDisplayName: 'Swaziland',
    },
    {
        countryName: 'Sweden',
        countryDisplayName: 'Sweden',
    },
    {
        countryName: 'Switzerland',
        countryDisplayName: 'Switzerland',
    },
    {
        countryName: 'Syria',
        countryDisplayName: 'Syria',
    },
    {
        countryName: 'Taiwan',
        countryDisplayName: 'Taiwan',
    },
    {
        countryName: 'Tajikistan',
        countryDisplayName: 'Tajikistan',
    },
    {
        countryName: 'Tanzania',
        countryDisplayName: 'Tanzania',
    },
    {
        countryName: 'Thailand',
        countryDisplayName: 'Thailand',
    },
    {
        countryName: 'Togo',
        countryDisplayName: 'Togo',
    },
    {
        countryName: 'Tokelau',
        countryDisplayName: 'Tokelau',
    },
    {
        countryName: 'Tonga',
        countryDisplayName: 'Tonga',
    },
    {
        countryName: 'Trinidad and Tobago',
        countryDisplayName: 'Trinidad and Tobago',
    },
    {
        countryName: 'Tunisia',
        countryDisplayName: 'Tunisia',
    },
    {
        countryName: 'Turkey',
        countryDisplayName: 'Turkey',
    },
    {
        countryName: 'Turkmenistan',
        countryDisplayName: 'Turkmenistan',
    },
    {
        countryName: 'Turks and Caicos Islands',
        countryDisplayName: 'Turks and Caicos Islands',
    },
    {
        countryName: 'Tuvalu',
        countryDisplayName: 'Tuvalu',
    },
    {
        countryName: 'U.S. Virgin Islands',
        countryDisplayName: 'U.S. Virgin Islands',
    },
    {
        countryName: 'Uganda',
        countryDisplayName: 'Uganda',
    },
    {
        countryName: 'Ukraine',
        countryDisplayName: 'Ukraine',
    },
    {
        countryName: 'United Arab Emirates',
        countryDisplayName: 'United Arab Emirates',
    },
    {
        countryName: 'United Kingdom',
        countryDisplayName: 'United Kingdom',
    },
    {
        countryName: 'United States',
        countryDisplayName: 'United States',
        countryStates: [
            {
                stateName: 'Alabama',
                stateDisplayName: 'Alabama',
            },
            {
                stateName: 'Alaska',
                stateDisplayName: 'Alaska',
            },
            {
                stateName: 'Arizona',
                stateDisplayName: 'Arizona',
            },
            {
                stateName: 'Arkansas',
                stateDisplayName: 'Arkansas',
            },
            {
                stateName: 'California',
                stateDisplayName: 'California',
            },
            {
                stateName: 'Colorado',
                stateDisplayName: 'Colorado',
            },
            {
                stateName: 'Connecticut',
                stateDisplayName: 'Connecticut',
            },
            {
                stateName: 'Delaware',
                stateDisplayName: 'Delaware',
            },
            {
                stateName: 'District of Columbia',
                stateDisplayName: 'District of Columbia',
            },
            {
                stateName: 'Florida',
                stateDisplayName: 'Florida',
            },
            {
                stateName: 'Georgia',
                stateDisplayName: 'Georgia',
            },
            {
                stateName: 'Hawaii',
                stateDisplayName: 'Hawaii',
            },
            {
                stateName: 'Idaho',
                stateDisplayName: 'Idaho',
            },
            {
                stateName: 'Illinois',
                stateDisplayName: 'Illinois',
            },
            {
                stateName: 'Indiana',
                stateDisplayName: 'Indiana',
            },
            {
                stateName: 'Iowa',
                stateDisplayName: 'Iowa',
            },
            {
                stateName: 'Kansas',
                stateDisplayName: 'Kansas',
            },
            {
                stateName: 'Kentucky',
                stateDisplayName: 'Kentucky',
            },
            {
                stateName: 'Louisiana',
                stateDisplayName: 'Louisiana',
            },
            {
                stateName: 'Maine',
                stateDisplayName: 'Maine',
            },
            {
                stateName: 'Maryland',
                stateDisplayName: 'Maryland',
            },
            {
                stateName: 'Massachusetts',
                stateDisplayName: 'Massachusetts',
            },
            {
                stateName: 'Michigan',
                stateDisplayName: 'Michigan',
            },
            {
                stateName: 'Minnesota',
                stateDisplayName: 'Minnesota',
            },
            {
                stateName: 'Mississippi',
                stateDisplayName: 'Mississippi',
            },
            {
                stateName: 'Missouri',
                stateDisplayName: 'Missouri',
            },
            {
                stateName: 'Montana',
                stateDisplayName: 'Montana',
            },
            {
                stateName: 'Nebraska',
                stateDisplayName: 'Nebraska',
            },
            {
                stateName: 'Nevada',
                stateDisplayName: 'Nevada',
            },
            {
                stateName: 'New Hampshire',
                stateDisplayName: 'New Hampshire',
            },
            {
                stateName: 'New Jersey',
                stateDisplayName: 'New Jersey',
            },
            {
                stateName: 'New Mexico',
                stateDisplayName: 'New Mexico',
            },
            {
                stateName: 'New York',
                stateDisplayName: 'New York',
            },
            {
                stateName: 'North Carolina',
                stateDisplayName: 'North Carolina',
            },
            {
                stateName: 'North Dakota',
                stateDisplayName: 'North Dakota',
            },
            {
                stateName: 'Ohio',
                stateDisplayName: 'Ohio',
            },
            {
                stateName: 'Oklahoma',
                stateDisplayName: 'Oklahoma',
            },
            {
                stateName: 'Oregon',
                stateDisplayName: 'Oregon',
            },
            {
                stateName: 'Pennsylvania',
                stateDisplayName: 'Pennsylvania',
            },
            {
                stateName: 'Rhode Island',
                stateDisplayName: 'Rhode Island',
            },
            {
                stateName: 'South Carolina',
                stateDisplayName: 'South Carolina',
            },
            {
                stateName: 'South Dakota',
                stateDisplayName: 'South Dakota',
            },
            {
                stateName: 'Tennessee',
                stateDisplayName: 'Tennessee',
            },
            {
                stateName: 'Texas',
                stateDisplayName: 'Texas',
            },
            {
                stateName: 'Utah',
                stateDisplayName: 'Utah',
            },
            {
                stateName: 'Vermont',
                stateDisplayName: 'Vermont',
            },
            {
                stateName: 'Virginia',
                stateDisplayName: 'Virginia',
            },
            {
                stateName: 'Washington',
                stateDisplayName: 'Washington',
            },
            {
                stateName: 'West Virginia',
                stateDisplayName: 'West Virginia',
            },
            {
                stateName: 'Wisconsin',
                stateDisplayName: 'Wisconsin',
            },
            {
                stateName: 'Wyoming',
                stateDisplayName: 'Wyoming',
            },
        ],
    },
    {
        countryName: 'United States Minor Outlying Islands',
        countryDisplayName: 'United States Minor Outlying Islands',
    },
    {
        countryName: 'Uruguay',
        countryDisplayName: 'Uruguay',
    },
    {
        countryName: 'Uzbekistan',
        countryDisplayName: 'Uzbekistan',
    },
    {
        countryName: 'Vanuatu',
        countryDisplayName: 'Vanuatu',
    },
    {
        countryName: 'Venezuela',
        countryDisplayName: 'Venezuela',
    },
    {
        countryName: 'Vietnam',
        countryDisplayName: 'Vietnam',
    },
    {
        countryName: 'Virgin Islands (British)',
        countryDisplayName: 'Virgin Islands (British)',
    },
    {
        countryName: 'Wallis and Futuna',
        countryDisplayName: 'Wallis and Futuna',
    },
    {
        countryName: 'Western Sahara',
        countryDisplayName: 'Western Sahara',
    },
    {
        countryName: 'Yemen',
        countryDisplayName: 'Yemen',
    },
    {
        countryName: 'Zambia',
        countryDisplayName: 'Zambia',
    },
    {
        countryName: 'Zimbabwe',
        countryDisplayName: 'Zimbabwe',
    },
];
export default countryAndStateList;
