import { graphql } from 'gatsby';
import parseHTML, { domToReact } from 'html-react-parser';
import get from 'lodash/get';
import React from 'react';
import LinkedItem from '@connected/LinkedItem';
import InlineAsset from '@presentation/InlineAsset';
import InlineContent from '@presentation/InlineContent';
import InlineLink from '@presentation/InlineLink';
import RichContentLayout from '@presentation/RichContentWithComponents';
const RichContentWithComponents = ({ content, images, linkedItems, links, gridLayout, preventAnyLayout, }) => {
    if (!content || !content.length) {
        return null;
    }
    // Remove any line breaks from HTML.
    const cleaned = content.replace(/(\n|\r)+/, '');
    // Parse HTML as React components, replacing any content items.
    const children = parseHTML(cleaned, {
        replace: domNode => replaceNode(domNode, images, links, linkedItems, gridLayout, preventAnyLayout),
    });
    // Return all components inside a fragment.
    return (<RichContentLayout preventAnyLayout={preventAnyLayout}>
      {children}
    </RichContentLayout>);
};
export default RichContentWithComponents;
// <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: content }} />
/** Get data for Kentico Cloud inline asset. */
function getAsset(id, assets) {
    return assets.find(asset => asset.imageId === id);
}
/** Get ID for Kentico Cloud inline asset from DOM node. */
function getAssetId(domNode) {
    return get(domNode, 'attribs["data-asset-id"]') || null;
}
/** Get code name for Kentico Cloud inline content item from DOM node. */
function getCodeName(domNode) {
    return get(domNode, 'attribs["data-codename"]') || null;
}
/** Get data for Kentico Cloud inline link. */
function getLink(id, links) {
    return links.find(link => link.linkId === id);
}
/** Get content for Kentico Cloud inline link from DOM node. */
function getLinkContent(domNode) {
    return get(domNode, 'children[0].data') || null;
}
/** Get data for Kentico Cloud inline content item. */
function getLinkedItem(codename, linkedItems) {
    return linkedItems.find(item => item.system?.codename === codename);
}
/** Get ID for Kentico Cloud inline link from DOM node. */
function getLinkId(domNode) {
    return get(domNode, 'attribs["data-item-id"]') || null;
}
/** Check if DOM node is a Kentico Cloud inline asset. */
function isAsset(domNode) {
    return (domNode.name === 'figure' &&
        !!domNode.attribs &&
        !!domNode.attribs['data-asset-id']);
}
/** Check if DOM node is a Kentico Cloud inline link. */
function isLink(domNode) {
    return (domNode.name === 'a' &&
        !!domNode.attribs &&
        !!domNode.attribs['data-item-id']);
}
/** Check if DOM node is a Kentico Cloud inline content item. */
function isLinkedItem(domNode) {
    return (domNode.name === 'p' &&
        !!domNode.attribs &&
        domNode.attribs.type === 'application/kenticocloud');
}
/** Replace HTML DOM node with React component. */
function replaceNode(domNode, images, links, linkedItems, gridLayout, preventAnyLayout) {
    // Replace inline assets.
    if (isAsset(domNode)) {
        const id = getAssetId(domNode);
        if (!id) {
            return false;
        }
        const image = getAsset(id, images);
        if (!image?.fluid) {
            return false;
        }
        return <InlineAsset alt={image.description || ''} image={image.fluid}/>;
    }
    // Replace inline links.
    if (isLink(domNode)) {
        const id = getLinkId(domNode);
        if (!id) {
            return false;
        }
        const link = getLink(id, links);
        if (!link) {
            return false;
        }
        const content = getLinkContent(domNode) || '';
        return (<InlineLink content={content} linkId={link.linkId || 0} type={link.type || ''} urlSlug={link.urlSlug || ''}/>);
    }
    // Replace inline linked items.
    if (isLinkedItem(domNode)) {
        const codename = getCodeName(domNode);
        if (!codename) {
            return false;
        }
        const linkedItem = getLinkedItem(codename, linkedItems);
        if (!linkedItem) {
            return false;
        }
        return <LinkedItem inline item={linkedItem}/>;
    }
    // Ignore empty text nodes.
    if (domNode.type === 'text' && domNode.data.trim().length === 0) {
        return false;
    }
    return (<InlineContent gridLayout={gridLayout} preventAnyLayout={preventAnyLayout}>
      {domToReact([domNode], {
        replace: childNode => replaceLinks(childNode, links),
    })}
    </InlineContent>);
}
function replaceLinks(domNode, links) {
    // Replace inline links.
    if (isLink(domNode)) {
        const id = getLinkId(domNode);
        if (!id) {
            return false;
        }
        const link = getLink(id, links);
        if (!link) {
            return false;
        }
        const content = getLinkContent(domNode) || '';
        return (<InlineLink content={content} linkId={link.linkId || 0} type={link.type || ''} urlSlug={link.urlSlug || ''}/>);
    }
}
export const KontentRichTextElementFragment = graphql `
  fragment KontentRichTextElementFragment on KontentRichTextElement {
    images {
      description
      imageId
      fluid(maxWidth: 1200) {
        ...KontentAssetFluid_withWebp
      }
    }
    links {
      codename
      linkId
      type
      urlSlug
    }
    linked_items {
      ...LinkedItemsFragment
    }
    resolvedData {
      html
    }
  }
`;
