export var LinkedItemType;
(function (LinkedItemType) {
    LinkedItemType["Article"] = "article";
    LinkedItemType["BasicGrid"] = "basic_grid";
    LinkedItemType["CardGrid"] = "card_grid";
    LinkedItemType["CardList"] = "card_list";
    LinkedItemType["ContentCard"] = "content_card";
    LinkedItemType["CtaLink"] = "cta_link";
    LinkedItemType["CtaCard"] = "cta_card";
    LinkedItemType["Custom"] = "custom";
    LinkedItemType["ProfileCard"] = "profile";
    LinkedItemType["FacetedCardList"] = "faceted_card_list";
    LinkedItemType["FeaturedCardList"] = "featured_card_list";
    LinkedItemType["Form"] = "form";
    LinkedItemType["ImageCarousel"] = "image_carousel";
    LinkedItemType["LogoCarousel"] = "logo_carousel";
    LinkedItemType["PageBannerThick"] = "page_banner__thick";
    LinkedItemType["PageBannerThin"] = "page_banner__thin";
    LinkedItemType["ProductOverview"] = "product_overview";
    LinkedItemType["Quote"] = "quote";
    LinkedItemType["QuoteList"] = "quote_list";
    LinkedItemType["RichContent"] = "rich_content";
    LinkedItemType["RichContentStandard"] = "rich_content_standard";
    LinkedItemType["Section"] = "section";
    LinkedItemType["SplitContent"] = "split_content__50_50";
    LinkedItemType["StaggeredCardList"] = "staggered_card_list";
    LinkedItemType["StatCard"] = "stat_card";
    LinkedItemType["StaticText"] = "static_text";
    LinkedItemType["Stories"] = "stories";
    LinkedItemType["TabbedContentArea"] = "tabbed_content_area";
    LinkedItemType["TestimonialCarousel"] = "testimonial_carousel";
    LinkedItemType["TextWithImage"] = "text_with_image";
    LinkedItemType["Timeline"] = "timeline";
    LinkedItemType["Video"] = "video";
})(LinkedItemType || (LinkedItemType = {}));
