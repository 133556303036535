import React, { useRef } from 'react';
import Image from 'gatsby-image';
import SectionWrapper from '@presentation/SectionWrapper';
import { motion } from 'framer-motion';
import useFadeUp from '@hooks/animation/useFadeUp';
import formatTitle from '@utils/formatTitle';
const CardGrid = ({ title, codename, image, children, }) => {
    const containerRef = useRef(null);
    const titleProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    const contentProps = useFadeUp({ ref: containerRef, delay: 0.2 });
    if (codename === 'ContactPress') {
        return (<SectionWrapper>
        {title && <h2 className="card-grid-heading">{title}</h2>}
        <div className="card-grid-two-columns">{children}</div>
      </SectionWrapper>);
    }
    if (codename === 'KenticoHQ') {
        return (<section className="custom-section custom-section--hq" ref={containerRef}>
        <div className="custom-section__container">
          <div className="custom-section__image">
            <Image className="custom-section__media" alt={title} loading="lazy" fluid={image} imgStyle={{ objectFit: 'cover' }}/>
          </div>
          <div className="custom-section__text">
            <motion.h2 className="custom-section__heading" {...titleProps}>
              {formatTitle(title)}
            </motion.h2>
            <motion.div className="custom-section__content" {...contentProps}>
              {children}
            </motion.div>
          </div>
        </div>
      </section>);
    }
    return (<SectionWrapper>
      {title && <h2 className="card-grid-heading">{title}</h2>}
      <div className="card-grid">{children}</div>
    </SectionWrapper>);
};
export default CardGrid;
