import React from 'react';
import { graphql } from 'gatsby';
import CustomHero from '@presentation/CustomHero';
import CustomSection from '@presentation/CustomSection';
import getCtaModel from '@utils/getCtaModel';
const Custom = ({ data }) => {
    const [ctaText, ctaTo, ctaIsExternal] = getCtaModel(data.elements?.cta__cta_text?.value, data.elements?.cta__cta_link__external?.value, data.elements?.cta__cta_link__internal?.linked_items?.[0]);
    const props = {
        title: data.elements?.title?.value,
        content: data.elements?.content?.value,
        codename: data.elements?.codename?.value,
        ctaText: ctaText,
        ctaTo: ctaTo,
        ctaIsExternal: ctaIsExternal,
        ctaEventName: data.elements?.cta__cta_event_name?.value,
        image1: data.elements?.image?.value?.[0]?.fluid,
        image2: data.elements?.image?.value?.[1]?.fluid,
    };
    switch (props.codename) {
        case 'ChooseRightProductHero':
            return <CustomHero {...props}/>;
        case 'ChooseRightProductCMSDXP':
        case 'ChooseRightProductBestOf':
        case 'ChooseRightProductHeadlessDXP':
        case 'ChooseRightProductDiscuss':
        case 'PeopleStrongTeam':
        case 'PeopleThisIsUs':
        case 'ContactHero':
        case 'HomeStrongerTogether':
        case 'SpecialHero':
        case 'SpecialHelpYouSucceed':
        case 'SpecialTransparency':
        case 'SpecialCommunity':
        case 'SpecialBalance':
        case 'SpecialTrees':
        case 'OurStroryHero':
        case 'DXPHero':
        case 'HeadlessHero':
            return <CustomSection {...props}/>;
    }
    return <>Unknown codename</>;
};
export default Custom;
export const KontentItemCustomFragment = graphql `
  fragment KontentItemCustomFragment on KontentItemCustom {
    elements {
      content {
        value
      }
      cta__cta_event_name {
        value
      }
      cta__cta_link__external {
        value
      }
      cta__cta_link__internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      cta__cta_text {
        value
      }
      image {
        value {
          fluid(maxWidth: 1920) {
            ...KontentAssetFluid_withWebp_noBase64
          }
        }
      }
      title {
        value
      }
      codename {
        value
      }
    }
  }
`;
