import React from 'react';
import { Grid, GridColumn } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import { ButtonVariant } from '@presentation/Button/Button.types';
import Link from '@presentation/Link';
import SectionWrapper from '@presentation/SectionWrapper';
import formatTitle from '@utils/formatTitle';
const FeaturedCardList = ({ children, ctaEventName, ctaIsExternal, ctaText, ctaTo, featuredCard, title, }) => {
    return (<SectionWrapper>
      <div className="featured-news">
        <Grid layout={GridLayout.FULL_INDENT}>
          <GridColumn>
            <div className="centered-content">
              <h2 className="featured-news__title">{formatTitle(title)}</h2>
            </div>
          </GridColumn>
        </Grid>

        <div className="featured-news__grid-container">
          {featuredCard && (<GridColumn>
              {React.cloneElement(featuredCard, {
        featured: true,
        landscape: true,
    })}
            </GridColumn>)}

          <div className="grid-column">
            <div className="featured-news__inner-grid">{renderChildren()}</div>
          </div>
        </div>

        {ctaText && ctaTo && (<div className="site-wide grid-container grid-container--full grid-container--indent vert-padding-50">
            <div className="grid-column centered-content">
              <Link eventName={ctaEventName} isExternal={ctaIsExternal} text={ctaText} to={ctaTo} variant={ButtonVariant.BlackBorder}/>
            </div>
          </div>)}
      </div>
    </SectionWrapper>);
    function renderChildren() {
        if (!children || !children.length) {
            return null;
        }
        return children.map(child => (<GridColumn key={child.key || ''}>
        {React.cloneElement(child, {
            landscape: false,
        })}
      </GridColumn>));
    }
};
export default FeaturedCardList;
