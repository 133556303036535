import React, { useRef } from 'react';
import Image from 'gatsby-image';
import useFadeUp from '@hooks/animation/useFadeUp';
import { motion } from 'framer-motion';
import formatTitle from '@utils/formatTitle';
const QuoteList = ({ title, quotes, codename }) => {
    return (<section className="quote-list" id={codename}>
      <div className="quote-list__container">
        <h2 className="quote-list__heading">{formatTitle(title)}</h2>
        <div className="quote-list__list">
          {quotes &&
        quotes.map((quote, index) => {
            const containerRef = useRef(null);
            const quoteProps = useFadeUp({ ref: containerRef, delay: 0.1 });
            return (<div key={index} ref={containerRef} className="quote-list__wrapper">
                  <motion.div className="quote-list__item" {...quoteProps}>
                    <div className="quote-list__border"/>
                    <Image className="quote-list__photo" fluid={quote.photo}/>
                    <div className="quote-list__text" dangerouslySetInnerHTML={{ __html: quote.text || '' }}/>
                    <div className="quote-list__author">
                      {quote.name}
                      <br />
                      {quote.position}
                      <br />
                      {quote.company}
                    </div>
                  </motion.div>
                </div>);
        })}
        </div>
      </div>
    </section>);
};
export default QuoteList;
