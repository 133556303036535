import React from 'react';
import { Grid, GridColumn } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import SectionWrapper from '@presentation/SectionWrapper';
const InlineContent = ({ children, gridLayout, preventAnyLayout, }) => {
    let layout = null;
    switch (gridLayout) {
        case 'full':
            layout = GridLayout.FULL;
            break;
        default:
            layout = GridLayout.FOUR;
    }
    if (preventAnyLayout) {
        return <>{children}</>;
    }
    return (<SectionWrapper margin={false} padding={false}>
      <Grid layout={layout}>
        <GridColumn>
          <div className="content-area">{children}</div>
        </GridColumn>
      </Grid>
    </SectionWrapper>);
};
export default InlineContent;
