/**
 * Fix invalid w descriptors in fluid images
 * @param {FluidObject | undefined} image
 */
export default function fixImageFluidSrcset(image) {
    if (image && image.srcSetWebp) {
        const regexp = /\.[0-9]+w/g;
        if (image.srcSet) {
            image.srcSet = image.srcSet.replace(regexp, 'w');
        }
        if (image.srcSetWebp) {
            image.srcSetWebp = image.srcSetWebp.replace(regexp, 'w');
        }
    }
    return image;
}
