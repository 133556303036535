import classNames from 'classnames';
import { motion } from 'framer-motion';
import Image from 'gatsby-image';
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import useFadeUp from '@hooks/animation/useFadeUp';
import useTinySlider from '@hooks/useTinySlider';
import IconChevronDown from '@icons/IconChevronDown';
import IconGeneric from '@icons/IconGeneric';
import { GridColumn, Grid } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import SectionWrapper from '@presentation/SectionWrapper';
import { SectionBackground } from '@presentation/SectionWrapper/SectionWrapper.types';
import TabbedNavigation from '@presentation/TabbedNavigation';
import formatTitle from '@utils/formatTitle';
import Link from '@presentation/Link';
const TabbedContentItem = ({ activeId, content, ctaEventName, ctaIsExternal, ctaText, ctaTo, eventName, icon, id, image, name, title, onChange, }) => {
    const titleClassName = classNames({
        'tabbed-content-features__item-title': true,
        'orange-dot': true,
        'is-active': activeId === id,
    });
    return (<>
      <div className={titleClassName} data-tracking-code={eventName} onClick={event => {
        event.preventDefault();
        onChange(id);
    }}>
        {icon && <IconGeneric iconName={icon}/>}
        {name}
        <IconChevronDown />
      </div>

      <div className="tabbed-content-features__item-contents">
        <div className="tabbed-content-features__item-text">
          <h2 className="h3-styling">{formatTitle(title)}</h2>
          <p>{content}</p>

          {ctaText && ctaTo && (<Link className="chev-link" eventName={ctaEventName} isExternal={ctaIsExternal} to={ctaTo}>
              {ctaText}
              <IconChevronDown />
            </Link>)}
        </div>

        <div className="tabbed-content-features__item-image">
          <Image className="tabbed-content-features__image" fluid={image}/>
        </div>
      </div>
    </>);
};
const TabbedContentGroup = ({ items }) => {
    const [activeId, setActiveId] = useState(null);
    useEffect(() => {
        if (window && window.matchMedia('(min-width: 1024px)').matches) {
            setActiveId(items[0].id);
        }
    }, []);
    return (<div className="tabbed-section__content-item">
      <section className="tabbed-content-features site-wide">
        <div className="grid-container-special ">
          {items.map(item => (<TabbedContentItem key={item.id} {...item} activeId={activeId} onChange={id => {
        if (window.matchMedia('(min-width: 1024px)').matches) {
            setActiveId(id);
        }
        else {
            if (activeId === id) {
                setActiveId(null);
            }
            else {
                setActiveId(id);
            }
        }
    }}/>))}
        </div>
      </section>
    </div>);
};
const TabbedContentArea = ({ content, groups, title, }) => {
    const [activeId, setActiveId] = useState(groups[0].id);
    const { containerRef: carouselRef, instanceRef } = useTinySlider({
        arrowKeys: false,
        autoplay: false,
        center: true,
        items: 1,
        controls: false,
        gutter: 160,
        loop: false,
        mouseDrag: false,
        nav: false,
        touch: false,
    });
    const containerRef = useRef(null);
    const titleProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    const contentProps = useFadeUp({ ref: containerRef, delay: 0.2 });
    const navProps = useFadeUp({ ref: containerRef, delay: 0.3 });
    const carouselProps = useFadeUp({ ref: containerRef, delay: 0.4 });
    useLayoutEffect(() => {
        if (instanceRef.current) {
            const index = groups.findIndex(group => group.id === activeId);
            instanceRef.current.goTo(index);
        }
    }, [activeId]);
    return (<SectionWrapper className="tabbed-section" bg={SectionBackground.LightGrey}>
      <Grid layout={GridLayout.FULL}>
        <GridColumn>
          <div ref={containerRef}>
            <header className="tabbed-section__header header-section">
              <motion.h2 {...titleProps}>{formatTitle(title)}</motion.h2>
              <motion.p {...contentProps}>{content}</motion.p>
            </header>

            <motion.div {...navProps}>
              <TabbedNavigation groups={groups} activeId={activeId} onChange={id => {
        setActiveId(id);
    }}/>
            </motion.div>

            <motion.div ref={carouselRef} className="tabbed-section__content" {...carouselProps}>
              {groups.map(group => (<TabbedContentGroup key={group.id} {...group}/>))}
            </motion.div>
          </div>
        </GridColumn>
      </Grid>
    </SectionWrapper>);
};
export default TabbedContentArea;
