import { graphql } from 'gatsby';
import React from 'react';
import CtaLinkLayout from '@presentation/CtaLink';
import getCtaLinkModel from '@utils/getCtaModel';
const CtaLink = ({ data }) => {
    const [text, link, isExternal] = getCtaLinkModel(data.elements?.text?.value, data.elements?.link_external?.value, data.elements?.link_internal?.linked_items?.[0]);
    return (<CtaLinkLayout isExternal={isExternal} link={link} text={text} eventName={data.elements?.text?.value} style={data.elements?.style?.value?.[0]?.codename} alignment={data.elements?.alignment?.value?.[0]?.codename} spacing={data.elements?.spacing?.value?.[0]?.codename} containerWidth={data.elements?.container_width?.value?.[0]?.codename}/>);
};
export default CtaLink;
export const KontentItemCtaLinkFragment = graphql `
  fragment KontentItemCtaLinkFragment on KontentItemCtaLink {
    elements {
      link_external {
        value
      }
      link_internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      text {
        value
      }
      event_name {
        value
      }
      style {
        value {
          codename
        }
      }
      alignment {
        value {
          codename
        }
      }
      spacing {
        value {
          codename
        }
      }
      container_width {
        value {
          codename
        }
      }
    }
  }
`;
