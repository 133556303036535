import { graphql } from 'gatsby';
import React from 'react';
import QuoteListLayout from '@presentation/QuoteList';
const QuoteList = ({ data }) => {
    const items = (data.elements?.quotes?.linked_items ||
        []);
    const quotes = items.map(createQuote);
    function createQuote(item) {
        return {
            name: item.elements?.name?.value || '',
            text: item.elements?.text?.value || '',
            photo: item.elements?.photo?.value?.[0]?.fluid,
            position: item.elements?.position?.value || '',
            company: item.elements?.company?.value || '',
        };
    }
    return (<QuoteListLayout title={data.elements?.title?.value} quotes={quotes} codename={data.system?.codename}/>);
};
export default QuoteList;
export const KontentItemQuoteListFragment = graphql `
  fragment KontentItemQuoteListFragment on KontentItemQuoteList {
    system {
      codename
    }
    elements {
      quotes {
        linked_items {
          ... on KontentItemQuote {
            elements {
              text {
                value
              }
              name {
                value
              }
              photo {
                value {
                  fluid(maxWidth: 400) {
                    ...KontentAssetFluid_withWebp_noBase64
                  }
                }
              }
              position {
                value
              }
              company {
                value
              }
              codename {
                value
              }
            }
          }
        }
      }
      title {
        value
      }
    }
  }
`;
