import { graphql } from 'gatsby';
import React from 'react';
import LinkedItem from '@connected/LinkedItem';
import BasicGridLayout from '@layouts/BasicGrid';
import { GridColumn } from '@layouts/Grid';
const BasicGrid = ({ data }) => {
    const content = data.elements?.content?.linked_items || [];
    const gridType = data.elements?.type?.value[0]?.codename || '';
    return (<BasicGridLayout type={gridType}>
      {content.map(item => {
        return (<GridColumn key={item.system?.id || ''}>
            <LinkedItem item={item}/>
          </GridColumn>);
    })}
    </BasicGridLayout>);
};
export default BasicGrid;
export const KontentItemBasicGridFragment = graphql `
  fragment KontentItemBasicGridFragment on KontentItemBasicGrid {
    elements {
      type {
        value {
          codename
        }
      }
      content {
        linked_items {
          system {
            id
            type
          }
          ... on KontentItem {
            ...KontentItemCtaCardFragment
          }
        }
      }
    }
  }
`;
