import { graphql } from 'gatsby';
import React from 'react';
import QuoteLayout from '@presentation/Quote';
const Quote = ({ data }) => {
    return (<QuoteLayout text={data.elements?.text?.value} name={data.elements?.name?.value} photo={data.elements?.photo?.value?.[0]?.fluid} position={data.elements?.position?.value} company={data.elements?.company?.value} codename={data.elements?.codename?.value}/>);
};
export default Quote;
export const KontentItemQuoteFragment = graphql `
  fragment KontentItemQuoteFragment on KontentItemQuote {
    elements {
      text {
        value
      }
      name {
        value
      }
      photo {
        value {
          fluid(maxWidth: 400) {
            ...KontentAssetFluid_withWebp_noBase64
          }
        }
      }
      position {
        value
      }
      company {
        value
      }
      codename {
        value
      }
    }
  }
`;
