import React, { useState } from 'react';
import { GridColumn, Grid } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import Button from '@presentation/Button';
import { ButtonVariant } from '@presentation/Button/Button.types';
import SectionWrapper from '@presentation/SectionWrapper';
import formatTitle from '@utils/formatTitle';
const FacetedCardList = ({ children, content, topics, selectedTopics, title, onTopicFilterChange, }) => {
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize] = useState(10);
    const totalCount = children.length;
    const numberToDisplay = pageSize * pageIndex;
    const canLoadMore = totalCount > numberToDisplay;
    return (<SectionWrapper className="blog-listing">
      <div className="blog-listing__intro">
        {title && <h2 className="orange-dot">{formatTitle(title)}</h2>}
        {content && <p>{content}</p>}
      </div>

      <Grid layout={GridLayout.TWO_FOUR}>
        <GridColumn>
          {topics?.options && (<div key={topics.title} className="form-orange">
              <h4>Filter by {topics.title}</h4>

              <div className="checkboxes">
                {topics.options.map(option => (<div key={`${option.name}-${option.value}`} className="form-input-container form-input-container--clickable">
                    <input id={`${option.name}-${option.value}`} className="form-clickable form-clickable--checkbox" name={option.name} checked={selectedTopics.includes(option.value)} type="checkbox" value={option.value} onChange={onTopicFilterChange}/>
                    <label className="form-label" htmlFor={`${option.name}-${option.value}`} data-tracking-code={`Filter_${option.name}_${option.value}`}>
                      {option.label}
                    </label>
                  </div>))}
              </div>
            </div>)}
        </GridColumn>
        <div className="grid-column">
          <Grid layout={GridLayout.HALF}>{renderChildren()}</Grid>
        </div>
      </Grid>

      {canLoadMore && (<Grid layout={GridLayout.TWO_FOUR}>
          <GridColumn />
          <GridColumn>
            <div className="blog-listing__load-more">
              <Button text="LOAD MORE" variant={ButtonVariant.BlackBorder} onClick={event => {
        event.preventDefault();
        setPageIndex(pageIndex + 1);
    }}/>
            </div>
          </GridColumn>
        </Grid>)}
    </SectionWrapper>);
    function renderChildren() {
        if (!children || !children.length) {
            return null;
        }
        const slice = children.slice(0, numberToDisplay);
        return slice.map(child => (<GridColumn key={child.key || ''}>{React.cloneElement(child)}</GridColumn>));
    }
};
export default FacetedCardList;
