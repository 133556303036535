import { graphql, useStaticQuery } from 'gatsby';
import flatMap from 'lodash/flatMap';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import React, { useState, useEffect } from 'react';
import LinkedItem from '@connected/LinkedItem';
import FacetedCardListLayout from '@layouts/FacetedCardList';
const FacetedCardList = ({ data }) => {
    const articleData = useArticleData();
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [items, setItems] = useState([]);
    const types = getArticleTypes();
    // Retrieve options for topics.
    const topics = getTopics(articleData, types);
    // Filter article data based on selected types and filter options.
    useEffect(() => {
        let filtered = articleData;
        // Apply types filter.
        if (types.length) {
            filtered = articleData.filter(item => item.elements?.article_types?.value.some(type => types.includes(type.codename)));
        }
        // Apply topics filter.
        if (selectedTopics.length) {
            filtered = filtered.filter(item => item.elements?.topics?.value.some(topic => selectedTopics.includes(topic.codename)));
        }
        setItems(filtered);
    }, [articleData, selectedTopics]);
    return (<FacetedCardListLayout content={data.elements?.content?.value} selectedTopics={selectedTopics} title={data.elements?.title?.value || ''} topics={topics} onTopicFilterChange={event => {
        const set = new Set(selectedTopics);
        if (event.target.checked) {
            set.add(event.target.value);
        }
        else {
            set.delete(event.target.value);
        }
        setSelectedTopics([...set]);
    }}>
      {items.map(item => (<LinkedItem key={item.system?.id || ''} item={item}/>))}
    </FacetedCardListLayout>);
    /**
     * Get selected article types.
     */
    function getArticleTypes() {
        return (data.elements?.article_types?.value?.map(item => item.codename) || []);
    }
};
export default FacetedCardList;
function getTopics(items, types) {
    // Extract Articles of the specified Article Type
    items = items.filter(item => {
        return item?.elements?.article_types?.value.some(itemValue => {
            return types.some(type => itemValue.codename === type);
        });
    });
    // Extract "topics" taxonomy.
    const taxonomyItems = flatMap(items, item => item.elements?.topics?.value).filter(item => !!item);
    // Create filter structure.
    return {
        options: getFilterOptions('Topic', taxonomyItems),
        title: 'Topic',
    };
}
function getFilterOptions(name, items) {
    return orderBy(uniqBy(items, item => item.codename).map((item) => ({
        label: item.name,
        name,
        value: item.codename,
    })), ['label'], ['asc']);
}
/**
 * Get the filtered article data - cannot be done at schema level due to dynamic parameters.
 * @param types The selected article types.
 */
function useArticleData() {
    const data = useStaticQuery(graphql `
    {
      allKontentItemArticle(
        sort: {
          fields: [
            elements___start_date___value
            elements___standard_content__title___value
          ]
          order: [DESC, ASC]
        }
      ) {
        nodes {
          ...KontentItemArticleFragment
          elements {
            article_types {
              value {
                codename
                name
              }
            }
            topics {
              value {
                codename
                name
              }
            }
          }
          system {
            id
            type
          }
        }
      }
    }
  `);
    return data.allKontentItemArticle.nodes || [];
}
export const KontentItemFacetedCardListFragment = graphql `
  fragment KontentItemFacetedCardListFragment on KontentItemFacetedCardList {
    elements {
      article_types {
        value {
          codename
        }
      }
      content {
        value
      }
      title {
        value
      }
    }
  }
`;
