import { graphql } from 'gatsby';
import React from 'react';
import fixImageFluidSrcset from '@utils/fixImageFluidSrcset';
import Card from '@presentation/Card';
import { CardLayout, } from '@presentation/Card/Card.types';
const ArticleCard = ({ data, featured = false, landscape = false, ...otherProps }) => {
    const author = data.elements?.authors?.linked_items &&
        data.elements?.authors?.linked_items[0];
    // Check if article is featured or we're forcing it to be due to a layout requirement.
    const isFeatured = getFeatured();
    const type = getType();
    const department = getDepartment();
    const readTime = data.elements?.read_time?.value || 0;
    const model = {
        author: author?.elements?.standard_content__title?.value,
        authorAvatar: author?.elements?.avatar?.value[0]?.fixed,
        content: data.elements?.standard_content__summary?.value,
        date: data.elements?.start_date?.value
            ? new Date(data.elements?.start_date?.value)
            : undefined,
        endDate: data.elements?.end_date?.value
            ? new Date(data.elements?.end_date?.value)
            : undefined,
        image: data.elements?.thumbnail?.value[0]?.fluid,
        layout: getCardLayout(type, isFeatured, landscape),
        location: data.elements?.location?.value,
        logo: fixImageFluidSrcset(data.elements?.logo?.value[0]?.fluid),
        readTime: readTime > 0 ? `${readTime} minute read` : undefined,
        tag: data.elements?.department?.value[0]?.name,
        title: data.elements?.standard_content__title?.value || '',
        type,
        department,
        url: data.url || '',
    };
    return <Card {...model} {...otherProps}/>;
    function getFeatured() {
        if (featured) {
            return true;
        }
        return data.elements?.featured?.value?.[0]?.codename == 'yes';
    }
    function getType() {
        return data.elements?.article_types?.value[0]?.codename;
    }
    function getDepartment() {
        return data.elements?.department?.value[0]?.codename;
    }
};
export default ArticleCard;
function getCardLayout(type, featured, landscape) {
    if (featured && landscape) {
        return CardLayout.FeaturedLandscape;
    }
    if (featured) {
        return CardLayout.Featured;
    }
    if (landscape) {
        return CardLayout.Landscape;
    }
    return CardLayout.Default;
}
export const KontentItemArticleFragment = graphql `
  fragment KontentItemArticleFragment on KontentItemArticle {
    elements {
      authors {
        linked_items {
          ... on KontentItemProfile {
            elements {
              avatar {
                value {
                  fixed(height: 32, width: 32) {
                    ...KontentAssetFixed_withWebp
                  }
                }
              }
              standard_content__title {
                value
              }
            }
          }
        }
      }
      article_types {
        value {
          codename
          name
        }
      }
      department {
        value {
          codename
          name
        }
      }
      start_date {
        value
      }
      end_date {
        value
      }
      featured {
        value {
          codename
        }
      }
      location {
        value
      }
      logo {
        value {
          fluid(maxWidth: 150) {
            ...KontentAssetFluid_withWebp
          }
        }
      }
      standard_content__title {
        value
      }
      standard_content__summary {
        value
      }
      thumbnail {
        value {
          fluid(maxWidth: 1600) {
            ...KontentAssetFluid_withWebp
          }
        }
      }
      read_time {
        value
      }
    }
    url
  }
`;
