import { useEffect, useRef } from 'react';
export default function useAoS(elementRef, className = 'animated') {
    const instanceRef = useRef();
    useEffect(() => {
        if (!elementRef.current) {
            return;
        }
        instanceRef.current = new IntersectionObserver(entries => {
            if (entries?.[0]?.isIntersecting) {
                const target = entries[0].target;
                target.classList.add(className);
            }
        });
        instanceRef.current.observe(elementRef.current);
        return () => {
            elementRef.current && instanceRef.current?.unobserve(elementRef.current);
        };
    }, [className]);
    return instanceRef;
}
