import classNames from 'classnames';
import { motion } from 'framer-motion';
import Image from 'gatsby-image';
import React, { useRef } from 'react';
import useFadeLeft from '@hooks/animation/useFadeLeft';
import useFadeRight from '@hooks/animation/useFadeRight';
import { Grid, GridColumn } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import Link from '@presentation/Link';
import SectionWrapper from '@presentation/SectionWrapper';
import formatTitle from '@utils/formatTitle';
const SplitContent = ({ title, content, tag, image, ctaEventName, ctaIsExternal, ctaText, ctaTo, isFlipped, }) => {
    const containerRef = useRef(null);
    const toRightProps = useFadeRight({ ref: containerRef, delay: 0.1 });
    const toLeftProps = useFadeLeft({ ref: containerRef, delay: 0.1 });
    const imageProps = isFlipped ? toLeftProps : toRightProps;
    const contentProps = isFlipped ? toRightProps : toLeftProps;
    const containerClassName = classNames({
        'card-50-50': true,
        'vert-padding-50': true,
        'card-50-50--flipped': isFlipped,
    });
    return (<SectionWrapper>
      <Grid layout={GridLayout.FULL_INDENT}>
        <GridColumn>
          <div ref={containerRef} className={containerClassName}>
            <div className="card-50-50__inner">
              <motion.div className="card-50-50__media-holder" {...imageProps}>
                <div className="card-50-50__media-holder-inner">
                  <Image alt={title} className="card-50-50__media-element" fluid={image}/>
                </div>
              </motion.div>
              <motion.div className="card-50-50__content-holder" {...contentProps}>
                <div className="card-50-50__content-holder-inner">
                  {tag && <div className="tag tag--blue">{tag}</div>}
                  <h2 className="card-50-50__title">{formatTitle(title)}</h2>
                  <p className="card-50-50__intro">{content}</p>
                  {ctaText && ctaTo && (<Link className="chev-link" eventName={ctaEventName} isExternal={ctaIsExternal} text={ctaText} to={ctaTo}/>)}
                </div>
              </motion.div>
            </div>
          </div>
        </GridColumn>
      </Grid>
    </SectionWrapper>);
};
export default SplitContent;
