import React from 'react';
import LogoCarouselLayout from '@presentation/LogoCarousel';
import { graphql } from 'gatsby';
import getCtaModel from '@utils/getCtaModel';
const LogoCarousel = ({ data }) => {
    const assets = data.elements?.logos?.value || [];
    const assetsStatic = data.elements?.static_logos?.value || [];
    const items = assets.map((asset) => ({
        alt: asset.description,
        id: asset.url || '',
        image: asset.fixed,
    }));
    const itemsStatic = assetsStatic.map((asset) => ({
        alt: asset.description,
        id: asset.url || '',
        image: asset.fluid,
    }));
    const [ctaText, ctaTo, ctaIsExternal] = getCtaModel(data.elements?.cta__cta_text?.value, data.elements?.cta__cta_link__external?.value, data.elements?.cta__cta_link__internal?.linked_items?.[0]);
    return (<LogoCarouselLayout title={data.elements?.title?.value} content={data.elements?.content?.value} items={items} itemsStatic={itemsStatic} ctaEventName={data.elements?.cta__cta_event_name?.value} ctaText={ctaText} ctaTo={ctaTo} ctaIsExternal={ctaIsExternal}/>);
};
export default LogoCarousel;
export const KontentItemLogoCarouselFragment = graphql `
  fragment KontentItemLogoCarouselFragment on KontentItemLogoCarousel {
    elements {
      content {
        value
      }
      logos {
        value {
          fixed(width: 240) {
            ...KontentAssetFixed_withWebp_noBase64
          }
          url
        }
      }
      static_logos {
        value {
          fluid(maxWidth: 240) {
            ...KontentAssetFluid_withWebp_noBase64
          }
          url
        }
      }
      title {
        value
      }
      cta__cta_event_name {
        value
      }
      cta__cta_link__external {
        value
      }
      cta__cta_link__internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      cta__cta_text {
        value
      }
    }
  }
`;
