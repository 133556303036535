import { graphql } from 'gatsby';
import React from 'react';
import RichContentLayout from '@presentation/RichContent';
const RichContent = ({ data }) => {
    return <RichContentLayout content={data.elements?.content?.value || ''}/>;
};
export default RichContent;
export const KontentItemRichContentFragment = graphql `
  fragment KontentItemRichContentFragment on KontentItemRichContent {
    elements {
      content {
        value
      }
    }
  }
`;
