import React from 'react';
import { graphql } from 'gatsby';
import fixImageFluidSrcset from '@utils/fixImageFluidSrcset';
import StatCardLayout from '@presentation/StatCard';
const StatCard = ({ data }) => {
    return (<StatCardLayout content={data.elements?.content?.value} line1={data.elements?.line_1?.value} line2={data.elements?.line_2?.value} logo={fixImageFluidSrcset(data.elements?.logo?.value?.[0]?.fluid)} richText={data.elements?.rich_text?.value} addBorder={data.elements?.add_border?.value?.[0]?.codename !== 'no'} codename={data.elements?.codename?.value}/>);
};
export default StatCard;
export const KontentItemStatCardFragment = graphql `
  fragment KontentItemStatCardFragment on KontentItemStatCard {
    elements {
      line_1 {
        value
      }
      line_2 {
        value
      }
      logo {
        value {
          fluid(maxWidth: 250) {
            ...KontentAssetFluid_withWebp
          }
        }
      }
      content {
        value
      }
      rich_text {
        value
      }
      add_border {
        value {
          codename
        }
      }
      codename {
        value
      }
    }
  }
`;
