import { motion } from 'framer-motion';
import Image from 'gatsby-image';
import React, { useRef, useState, useEffect } from 'react';
import useFadeUp from '@hooks/animation/useFadeUp';
import usePlyr from '@hooks/usePlyr';
import IconPlay from '@icons/IconPlay';
import { Grid, GridColumn } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import SectionWrapper from '@presentation/SectionWrapper';
const Video = ({ image, subtitle, title, video }) => {
    const containerRef = useRef(null);
    const containerProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    const [videoContainerRef, instanceRef] = usePlyr();
    const [isActive, setIsActive] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const placeholderRef = useRef(null);
    useEffect(() => {
        placeholderRef.current?.addEventListener('transitionend', () => {
            const opacity = parseFloat(placeholderRef.current?.style?.opacity || '');
            if (opacity === 0) {
                setIsDone(true);
                instanceRef.current?.play();
            }
        });
    }, []);
    return (<SectionWrapper>
      <Grid layout={GridLayout.FULL_INDENT}>
        <GridColumn>
          <motion.div ref={containerRef} className="video-card" {...containerProps}>
            <div ref={placeholderRef} className="video-card__placeholder" onClick={() => setIsActive(true)} style={{
        opacity: isActive ? 0 : 1,
        display: isDone ? 'none' : 'block',
    }}>
              <Image alt={title} className="video-card__media-element" fluid={image}/>
              <div className="video-card__placeholder-overlay"></div>
              <div className="video-card__play-holder">
                <IconPlay />
              </div>
              {(title || subtitle) && (<div className="video-card__placeholder-titles">
                  {title && (<p className="video-card__placeholder-title">{title}</p>)}
                  {subtitle && (<p className="video-card__placeholder-subtitle">
                      {subtitle}
                    </p>)}
                </div>)}
            </div>
            <div ref={videoContainerRef} style={{ opacity: isActive ? 1 : 0 }} className="plyr__video-embed">
              <iframe src={`${video}?iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`} allowFullScreen allow="autoplay"></iframe>
            </div>
          </motion.div>
        </GridColumn>
      </Grid>
    </SectionWrapper>);
};
export default Video;
