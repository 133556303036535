import React, { useContext, useState } from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';
import { boolean, date, mixed, number, object, string, ValidationError, } from 'yup';
import { useXperienceForm } from '@hooks/useXperienceFormData';
/**
 * Store the form definition and state.
 */
const FormContext = React.createContext(null);
/**
 * Provide access to the form definition.
 */
const FormProvider = ({ additionalFormState = {}, children, formName, }) => {
    const form = useXperienceForm(formName) ?? null;
    const formFields = form?.formDefinition.fields ?? [];
    const initialFormState = getInitialFormState(formFields, additionalFormState);
    const validationSchema = getValidationSchema(formFields);
    // @todo: Could refactor this with useReducer.
    const [errorMessage, setErrorMessage] = useState(null);
    const [confirmation, setConfirmation] = useState(null);
    const [confirmationCta, setConfirmationCta] = useState(null);
    const [formError, setFormError] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [formState, setFormState] = useState(initialFormState);
    const [pardotIframe, setPardotIframe] = useState(null);
    const value = {
        confirmation,
        confirmationCta,
        errorMessage,
        form,
        formError,
        formLoading,
        formState,
        validationSchema,
        pardotIframe,
        createFormData,
        resetFormState,
        setConfirmation,
        setConfirmationCta,
        setErrorMessage,
        setFormError,
        setFormLoading,
        setFormState,
        validateForm,
        setPardotIframe,
    };
    return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
    /**
     * Create the form data to submit.
     */
    function createFormData() {
        const formData = new FormData();
        formData.append('formguid', form?.formGUID ?? '');
        formData.append('formid', form?.formId?.toString() ?? '');
        formData.append('formname', form?.formName ?? '');
        Object.entries(formState).forEach(([key, value]) => {
            formData.append(key, value);
        });
        return formData;
    }
    /**
     * Reset the form state to initial values.
     */
    function resetFormState() {
        setFormState(initialFormState);
    }
    /**
     * Validate the form state and populate the error state.
     */
    async function validateForm() {
        try {
            await validationSchema.validate(formState, { abortEarly: false });
            setFormError(null);
            return true;
        }
        catch (error) {
            if (error instanceof ValidationError) {
                setFormError(error);
            }
            return false;
        }
    }
};
/**
 * Access the form context.
 */
function useFormContext() {
    const context = useContext(FormContext);
    return context;
}
/**
 * Get the initial form state.
 * @param {Object} form
 */
function getInitialFormState(formFields, additionalFormState) {
    const initialState = {};
    formFields.forEach(field => {
        if (field.isSystem || field.isKey) {
            return;
        }
        switch (field.type) {
            case 'boolean':
                if (field.defaultValue?.toLowerCase() === 'false') {
                    initialState[field.name] = false;
                }
                if (field.defaultValue?.toLowerCase() === 'true') {
                    initialState[field.name] = true;
                }
                initialState[field.name] = false;
                break;
            case 'datetime':
                initialState[field.name] = new Date(field.defaultValue ?? '');
                break;
            case 'integer':
                const value = parseFloat(field.defaultValue ?? '');
                initialState[field.name] = Number.isNaN(value) ? '' : value;
                break;
            default:
                initialState[field.name] = field.defaultValue ?? '';
                break;
        }
    });
    return { ...initialState, ...additionalFormState };
}
/**
 * Get the validation schema.
 * @param {Object} form
 */
function getValidationSchema(formFields) {
    const fieldSchema = formFields
        .filter(field => field.isPublic && field.isVisible)
        .map(getFieldSchema)
        .reduce((aggr, value) => ({ ...aggr, [value.name]: value.schema }), {});
    return object().shape(fieldSchema);
}
/**
 * Get the validation schema for the specified field.
 * @param field
 */
function getFieldSchema(field) {
    let schema;
    switch (field.type) {
        case 'boolean':
            schema = boolean().transform(function transform(value, originalValue) {
                if (typeof originalValue === 'string' && originalValue.length === 0) {
                    return false;
                }
                if (this.isType(value)) {
                    return value;
                }
            });
            break;
        case 'datetime':
            schema = date().transform(function transform(value, originalValue) {
                if (typeof originalValue === 'string' && originalValue.length === 0) {
                    return undefined;
                }
                if (this.isType(value)) {
                    return value;
                }
            });
            break;
        case 'integer':
            schema = number().transform(function transform(value, originalValue) {
                if (typeof originalValue === 'string' && originalValue.length === 0) {
                    return undefined;
                }
                if (this.isType(value)) {
                    return value;
                }
            });
            break;
        case 'longtext':
        case 'text':
            schema = string().transform(function transform(value, originalValue) {
                if (typeof originalValue === 'string' && originalValue.length === 0) {
                    return undefined;
                }
                if (this.isType(value)) {
                    return value;
                }
            });
            if (field.componentName === 'Kentico.EmailInput') {
                schema = schema.test({
                    name: 'Kentico.EmailInput',
                    exclusive: true,
                    message: 'Email address must be in a correct format.',
                    test: value => {
                        return validateEmail(value);
                    },
                });
            }
            if (field.componentName === 'PhoneInput') {
                schema = schema.test({
                    name: 'PhoneInput',
                    exclusive: true,
                    message: 'Phone number must be in a correct format.',
                    test: value => {
                        return validatePhone(value);
                    },
                });
            }
            break;
        default:
            schema = mixed();
            break;
    }
    schema = schema.label(field.label ?? '');
    if (field.isRequired) {
        schema = schema.required();
    }
    return { name: field.name ?? '', schema };
}
function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !email || re.test(String(email).toLowerCase());
}
function validatePhone(phone) {
    if (!phone)
        return true;
    const parsedPhone = parsePhoneNumber(phone);
    return parsedPhone.isValid();
}
export { FormProvider, useFormContext };
