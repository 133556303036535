import React, { Fragment } from 'react';
import LinkedItem from '@components/connected/LinkedItem';
const Sections = ({ linked_items: items }) => {
    if (!items || !items.length) {
        return null;
    }
    return (<Fragment>
      {items.map((item, index) => (<LinkedItem key={item.system?.id} index={index} item={item}/>))}
    </Fragment>);
};
export default Sections;
