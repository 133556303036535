import { motion } from 'framer-motion';
import Image from 'gatsby-image';
import React, { Fragment, useRef } from 'react';
import useFadeUp from '@hooks/animation/useFadeUp';
import StatValue from './StatValue';
import classNames from 'classnames';
const StatCard = ({ logo, line1, line2, richText, addBorder, codename, }) => {
    const elementRef = useRef(null);
    const animProps = useFadeUp({ ref: elementRef, delay: 0.1 });
    const containerClassName = classNames({
        card: true,
        'card--stat': true,
        'card--no-border': !addBorder,
    });
    const cardInnerClassname = classNames('card__inner', {
        ['card__inner--contact']: codename === 'ContactPerson',
    });
    if (codename === 'ResolveItalicPhoneNumbers' && richText) {
        const regexp = new RegExp('<em>(.+)</em>', 'g');
        let match;
        while (null != (match = regexp.exec(richText))) {
            if (match && match.length) {
                richText = richText.replace(match[0], `<a href="tel:${match[1].replace(/[ -]/g, '')}">${match[1]}</a>`);
            }
        }
    }
    return (<div ref={elementRef} className={containerClassName}>
      <motion.div className={cardInnerClassname} {...animProps}>
        <div className="card__content-external">
          <div className="card__content-internal">
            {codename === 'ContactPerson' && line2 && logo && (<div className="card__line2 orange-dot">{countUp(line2)}</div>)}
            {logo ? (<Image className="card__image" fluid={logo}/>) : (<Fragment>
                {line1 && <div className="card__line1">{countUp(line1)}</div>}
                {line2 && (<div className="card__line2 orange-dot">{countUp(line2)}</div>)}
              </Fragment>)}
            {richText && (<div className="card__content" dangerouslySetInnerHTML={{ __html: richText }}></div>)}
          </div>
        </div>
      </motion.div>
    </div>);
    function countUp(input) {
        const regex = /(.*?)(\d+)(.*)/;
        const array = regex.exec(input);
        if (!array || array.length < 4) {
            return input;
        }
        const value = parseFloat(array[2]);
        return [array[1], <StatValue key={value} value={value}/>, array[3]];
    }
};
export default StatCard;
