import classNames from 'classnames';
import React from 'react';
import CheckBox from './controls/CheckBox';
import ConsentAgreement from './controls/ConsentAgreement';
import Email from './controls/Email';
import FileUploader from './controls/FileUploader';
import HiddenInput from './controls/HiddenInput';
import IntInput from './controls/IntInput';
import PhoneNumber from './controls/PhoneNumber';
import RadioButton from './controls/RadioButton';
import MultipleChoice from './controls/MultipleChoice';
import Select from './controls/Select';
import TextArea from './controls/TextArea';
import TextInput from './controls/TextInput';
import CountrySelector from './controls/CountrySelector';
import { FormFieldType } from './Form.types';
import { useFormContext } from './FormProvider';
import { graphql, useStaticQuery } from 'gatsby';
/**
 * Basic replacement of tilde prefixed URLs.
 * @param input
 */
function resolveUrls(input) {
    return input.replace('~/', '/');
}
const IGNORED_FIELD_NAMES = [
    'pageurl',
    'resourceurl',
    'automaticfollowupconsent',
    'formguid',
];
const FormField = ({ datasource, id, isRequired, label, name = '', optionLabel, placeholder, settingsRaw, type, value, targetSite, onChange, }) => {
    const formData = useFormContext();
    if (IGNORED_FIELD_NAMES.includes(name.toLowerCase())) {
        return (<HiddenInput inputProps={{
            id,
            name,
            placeholder,
            required: isRequired,
        }} type={type} value={value} onChange={onChange}/>);
    }
    const formError = formData?.formError ?? null;
    const fieldError = formError?.inner.find(error => error.path === name);
    const typeClassName = type?.split('.')?.[1]?.toLowerCase() ?? 'default';
    const formInputClassnames = classNames({
        'form-input-container': true,
        [`form-input-container--${typeClassName}`]: true,
        'form-input-container--clickable': type === FormFieldType.Checkbox ||
            type === FormFieldType.ConsentAgreement,
        'form-input-container--error': !!fieldError,
        'form-input-container--consentagreement': type === FormFieldType.ConsentAgreement,
    });
    const options = datasource?.split(/[\r\n]+/).map(option => {
        const optionParts = option.split(';');
        if (optionParts.length == 1) {
            return {
                value: optionParts[0],
                label: optionParts[0],
            };
        }
        return {
            value: optionParts[0],
            label: optionParts[1],
        };
    });
    if (type === FormFieldType.ConsentAgreement) {
        const settings = JSON.parse(settingsRaw ?? '{}');
        const consentName = settings.ConsentCodeName[0];
        const consents = useStaticQuery(graphql `
      {
        allXperienceConsent {
          nodes {
            consentName
            consentContent {
              shortText
            }
          }
        }
      }
    `);
        const consent = consents.allXperienceConsent.nodes?.find(c => c.consentName === consentName);
        // Get consent content, also resolve any URLs with a ~/ prefix.
        label = resolveUrls(consent?.consentContent?.[0]?.shortText ?? '');
    }
    return (<div className={formInputClassnames}>
      {renderField()}
      <label className="form-label" htmlFor={id} dangerouslySetInnerHTML={{ __html: label }}/>

      {fieldError && (<span className={`form-label form-label--error${type === FormFieldType.RadioButton ||
        type === FormFieldType.MultipleChoice
        ? ' form-label--no-icon'
        : ''}`}>
          {fieldError?.message}
        </span>)}
    </div>);
    function renderField() {
        const inputProps = {
            id,
            name,
            placeholder,
            required: isRequired,
        };
        switch (type) {
            case FormFieldType.Checkbox:
                return (<CheckBox inputProps={inputProps} type={type} value={value} onChange={onChange}/>);
            case FormFieldType.ConsentAgreement:
                return (<ConsentAgreement inputProps={inputProps} type={type} value={value} onChange={onChange}/>);
            case FormFieldType.Email:
                return (<Email inputProps={inputProps} type={type} value={value} onChange={onChange}/>);
            case FormFieldType.FileUploader:
                return (<FileUploader inputProps={inputProps} type={type} onChange={onChange}/>);
            case FormFieldType.IntInput:
                return (<IntInput inputProps={inputProps} type={type} value={value} onChange={onChange}/>);
            case FormFieldType.PhoneNumber:
                return (<PhoneNumber value={value} onChange={onChange} inputProps={inputProps} type={type}/>);
            case FormFieldType.RadioButton:
                return (<RadioButton inputProps={inputProps} options={options || []} type={type} value={value} onChange={onChange}/>);
            case FormFieldType.MultipleChoice:
                return (<MultipleChoice inputProps={inputProps} options={options || []} type={type} value={value} onChange={onChange}/>);
            case FormFieldType.Select:
                return (<Select inputProps={inputProps} optionLabel={optionLabel || ''} options={options || []} type={type} value={value} onChange={onChange}/>);
            case FormFieldType.TextArea:
                return (<TextArea inputProps={inputProps} type={type} value={value} onChange={onChange}/>);
            case FormFieldType.TextInput:
                return (<TextInput inputProps={inputProps} type={type} value={value} onChange={onChange}/>);
            case FormFieldType.CountrySelector:
                return (<CountrySelector inputProps={inputProps} type={type} value={value} onChange={onChange} targetSite={targetSite}/>);
            default:
                return null;
        }
    }
};
export default FormField;
