import React from 'react';
import { graphql } from 'gatsby';
import SplitContentLayout from '@presentation/SplitContent';
import getCtaModel from '@utils/getCtaModel';
const SplitContent = ({ data }) => {
    const [ctaText, ctaTo, ctaIsExternal] = getCtaModel(data.elements?.cta__cta_text?.value, data.elements?.cta__cta_link__external?.value, data.elements?.cta__cta_link__internal?.linked_items?.[0]);
    const flipped = data.elements?.is_flipped?.value?.[0]?.codename == 'flipped';
    return (<SplitContentLayout content={data.elements?.content?.value} ctaEventName={data.elements?.cta__cta_event_name?.value} ctaText={ctaText} ctaTo={ctaTo} ctaIsExternal={ctaIsExternal} image={data.elements?.image?.value?.[0].fluid} tag={data.elements?.tag?.value} title={data.elements?.title?.value} isFlipped={flipped}/>);
};
export default SplitContent;
export const KontentItemSplitContentFragment = graphql `
  fragment KontentItemSplitContentFragment on KontentItemSplitContent_50_50 {
    elements {
      content {
        value
      }
      cta__cta_event_name {
        value
      }
      cta__cta_link__external {
        value
      }
      cta__cta_link__internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      cta__cta_text {
        value
      }
      image {
        value {
          fluid(maxWidth: 600) {
            ...KontentAssetFluid_withWebp
          }
        }
      }
      tag {
        value
      }
      title {
        value
      }
      is_flipped {
        value {
          codename
        }
      }
    }
  }
`;
