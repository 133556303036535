import React from 'react';
import classNames from 'classnames';
import { CardDepartment } from './Tag.types';
const Tag = ({ type = CardDepartment.Unknown, tag }) => {
    return <div className={getTagClassName(type)}>{tag}</div>;
};
/**
 * Get the card class names for the specified article type.
 * @param type The article type
 */
export function getTagClassName(type) {
    let tagClass = '';
    switch (type) {
        case CardDepartment.Kontent:
            tagClass = 'tag--turquoise';
            break;
        case CardDepartment.Xperience:
            tagClass = 'tag--blue';
            break;
        default:
            tagClass = 'tag--orange';
            break;
    }
    const tagClassName = classNames({
        tag: true,
        [tagClass]: true,
    });
    return tagClassName;
}
export default Tag;
