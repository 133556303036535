import classNames from 'classnames';
import React, { useRef } from 'react';
import useFadeLeft from '@hooks/animation/useFadeLeft';
import useFadeRight from '@hooks/animation/useFadeRight';
import KontentLines from '@icons/KontentLines';
import XperienceRings from '@icons/XperienceRings';
import { Grid, GridColumn } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import { ButtonVariant } from '@presentation/Button/Button.types';
import Link from '@presentation/Link';
import SectionWrapper from '@presentation/SectionWrapper';
import formatTitle from '@utils/formatTitle';
import { ProductType, } from './ProductOverview.types';
import { motion } from 'framer-motion';
import useFadeUp from '@hooks/animation/useFadeUp';
const Product = ({ logo, content, title, note, subtitle, features, type = ProductType.Unknown, ctaEventName, ctaIsExternal, ctaText, ctaTo, ...otherProps }) => {
    const productClassname = getProductClassName(type);
    return (<GridColumn>
      <div className="product-overview">
        <div className={productClassname} {...otherProps}>
          <div className="product-overview__product-background">
            {type == ProductType.Xperience ? (<XperienceRings />) : (<KontentLines />)}
          </div>
          <div className="product-overview__product-container">
            {title && (<h3 className="product-overview__product-title">
                {formatTitle(title)}
              </h3>)}
            <div className="product-overview__product-logo">
              <img alt="" role="presentation" src={logo}/>
            </div>
            {subtitle && (<p className="product-overview__product-subtitle">{subtitle}</p>)}
            {content && (<p className="product-overview__product-content">{content}</p>)}
            {features && features !== '<p><br></p>' && (<div className="product-overview__product-features feature-list" dangerouslySetInnerHTML={{ __html: features }}></div>)}
            {ctaText && ctaTo && (<div className="product-overview__product-button">
                <Link eventName={ctaEventName} isExternal={ctaIsExternal} text={ctaText} to={ctaTo} variant={ButtonVariant.OrangeBorder}/>
              </div>)}
            {note && note !== '<p><br></p>' && (<div className="product-overview__note" dangerouslySetInnerHTML={{ __html: note }}></div>)}
          </div>
        </div>
      </div>
    </GridColumn>);
};
/**
 * Get the product class names for the specified product type.
 * @param type The product type
 */
export function getProductClassName(type) {
    let productClass = '';
    switch (type) {
        case ProductType.Kontent:
            productClass = 'product-overview__product--kontent';
            break;
        case ProductType.Xperience:
            productClass = 'product-overview__product--xperience';
            break;
        default:
            productClass = '';
            break;
    }
    const productClassName = classNames({
        'product-overview__product': true,
        [productClass]: true,
    });
    return productClassName;
}
const ProductOverview = ({ title, content, ctaTo, ctaText, ctaIsExternal, ctaEventName, fullWidth, products, }) => {
    const containerRefHeader = useRef(null);
    const containerRefContent = useRef(null);
    const containerRefFooter = useRef(null);
    const titleProps = useFadeUp({ ref: containerRefHeader, delay: 0.1 });
    const contentProps = useFadeUp({ ref: containerRefHeader, delay: 0.2 });
    const leftProps = useFadeRight({ ref: containerRefContent, delay: 0.1 });
    const rightProps = useFadeLeft({ ref: containerRefContent, delay: 0.1 });
    const ctaProps = useFadeUp({ ref: containerRefFooter, delay: 0.1 });
    // KC-169: Amended to have "full width" be non-indented grid layout
    if (fullWidth) {
        return (<SectionWrapper className="section section--center" id="product-overview">
        {(title || content) && (<Grid ref={containerRefHeader} layout={GridLayout.FULL}>
            {renderHeader()}
          </Grid>)}
        <Grid ref={containerRefContent} layout={GridLayout.HALF}>
          {renderContent()}
        </Grid>
        {ctaText && ctaTo && (<Grid ref={containerRefFooter} layout={GridLayout.FULL}>
            {renderFooter()}
          </Grid>)}
      </SectionWrapper>);
    }
    return (<SectionWrapper margin={false} className="section section--center" id="product-overview">
      {(title || content) && (<Grid ref={containerRefHeader} layout={GridLayout.FULL_INDENT}>
          {renderHeader()}
        </Grid>)}
      <Grid ref={containerRefContent} layout={GridLayout.HALF_INDENT}>
        {renderContent()}
      </Grid>
      {ctaText && ctaTo && (<Grid ref={containerRefFooter} layout={GridLayout.FULL_INDENT}>
          {renderFooter()}
        </Grid>)}
    </SectionWrapper>);
    function renderHeader() {
        return (<>
        <GridColumn>
          {title && (<motion.h2 className="section__title remove-dot" {...titleProps}>
              {formatTitle(title)}
            </motion.h2>)}
          {content && (<motion.p className="section__content" {...contentProps}>
              {content}
            </motion.p>)}
        </GridColumn>
      </>);
    }
    function renderContent() {
        if (!products?.length) {
            return null;
        }
        return products.map((product, index) => {
            const isLeft = index % 2 === 0;
            return (<Product key={product.id} logo={product.logo} title={product.title} subtitle={product.subtitle} content={product.content} features={product.features} type={product.type} ctaEventName={product.ctaEventName} ctaIsExternal={product.ctaIsExternal} ctaText={product.ctaText} ctaTo={product.ctaTo} note={product.note} {...(isLeft ? leftProps : rightProps)}/>);
        });
    }
    function renderFooter() {
        return (ctaText &&
            ctaTo && (<GridColumn>
          <motion.div className="section__cta vert-margin-top-50" {...ctaProps}>
            <Link eventName={ctaEventName} isExternal={ctaIsExternal} text={ctaText} to={ctaTo} variant={ButtonVariant.OrangeSolid}/>
          </motion.div>
        </GridColumn>));
    }
};
export default ProductOverview;
