import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useRef } from 'react';
import useFadeUp from '@hooks/animation/useFadeUp';
import formatTitle from '@utils/formatTitle';
const ContentCard = ({ align, content, title, }) => {
    const elementRef = useRef(null);
    const animProps = useFadeUp({ ref: elementRef, delay: 0.1 });
    const cardClassName = classNames({
        card: true,
        'card--content': true,
        [`card--align-${align}`]: true,
    });
    return (<div ref={elementRef} className={cardClassName}>
      <motion.div className="card__inner" {...animProps}>
        <div className="card__content-external">
          <div className="card__content-internal">
            <h3 className="card__title">{formatTitle(title)}</h3>
            <p className="card__content">{content}</p>
          </div>
        </div>
      </motion.div>
    </div>);
};
export default ContentCard;
