import { graphql } from 'gatsby';
import React from 'react';
const StaticText = ({ data }) => {
    return (<div dangerouslySetInnerHTML={{ __html: data.elements?.text?.value || '' }}/>);
};
export default StaticText;
export const KontentItemStaticTextFragment = graphql `
  fragment KontentItemStaticTextFragment on KontentItemStaticText {
    elements {
      text {
        value
      }
    }
  }
`;
