import React from 'react';
import Image from 'gatsby-image';
const Author = ({ name, avatar }) => {
    return (<div className="card__author">
      {avatar && (<div className="card__author-img">
          <Image className="card__author-element" alt={name} fixed={avatar}/>
        </div>)}
      <span className="card__author-name">{name}</span>
    </div>);
};
export default Author;
