import React, { Fragment, useState } from 'react';
import { FormFieldType } from '../Form.types';
const MultipleChoice = ({ inputProps, options, value, onChange, }) => {
    const [allSelectedOptions, setAllSelectedOptions] = useState([]);
    const fieldName = inputProps.name;
    return (<Fragment>
      {options &&
        options.map(option => (<div key={`${inputProps.id}-${option.value}`} className={`form-input-container form-input-container--clickable${allSelectedOptions.indexOf(option.value) > -1
            ? ' form-input-container--checked'
            : ''}`}>
            <input id={`${inputProps.id}-${option.value}`} checked={value === option.value} className="form-clickable form-clickable--checkbox form-clickable--checkbox-group" name={inputProps.id} type="checkbox" value={option.value} onChange={e => {
            const allOptions = allSelectedOptions;
            const index = allSelectedOptions.indexOf(e.target.value);
            if (index !== -1) {
                allOptions.splice(index, 1);
            }
            else if (e.target.value) {
                allOptions.push(e.target.value);
            }
            setAllSelectedOptions(allOptions);
            const value = allOptions.join('|');
            const fakeEvent = {};
            fakeEvent.target = {};
            fakeEvent.target.name = fieldName || '';
            fakeEvent.target.value = value;
            onChange(fakeEvent, FormFieldType.MultipleChoice);
        }}/>

            <label className="form-label" htmlFor={`${inputProps.id}-${option.value}`}>
              {option.label}
            </label>
          </div>))}
    </Fragment>);
};
export default MultipleChoice;
