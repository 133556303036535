import React from 'react';
import Image from 'gatsby-image';
import RichContentWithComponents from '@connected/RichContentWithComponents';
import SectionWrapper from '@presentation/SectionWrapper';
const TextWithImage = ({ headline, text, image, }) => {
    return (<SectionWrapper fullWidth>
      <div className="site-wide grid-container grid-container--full">
        <div className="grid-column">
          <div className="text">
            <div className="text__wrapper">
              {image && (<div className="text__image-wrapper">
                  <Image className="text__image" alt="" fluid={image}/>
                </div>)}
              <div className="text__text">
                {headline && <h2 className="text__headline">{headline}</h2>}
                <RichContentWithComponents content={text?.resolvedData?.html || ''} images={text?.images || []} linkedItems={text?.linked_items || []} links={text?.links || []} gridLayout="full"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>);
};
export default TextWithImage;
