import Cookie from 'js-cookie';
import React, { Fragment, useLayoutEffect, useRef } from 'react';
import { Grid, GridColumn } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import SectionWrapper from '@presentation/SectionWrapper';
import formatTitle from '@utils/formatTitle';
import isTrustedHost from '@utils/isTrustedHost';
import FormLayout from './FormLayout';
import { useFormContext } from './FormProvider';
import { FormFieldType } from './Form.types';
const Form = ({ title, submitText, downloadButtonText, formColour, targetSite, onSubmit, styleCodename, }) => {
    const formContext = useFormContext();
    const formRef = useRef(null);
    if (!formContext) {
        return null;
    }
    const { confirmation, confirmationCta, errorMessage, form, formLoading, formState, pardotIframe, createFormData, resetFormState, setConfirmation, setErrorMessage, setFormLoading, setFormState, validateForm, } = formContext;
    if (!form) {
        return null;
    }
    useLayoutEffect(() => {
        if (formRef.current != null) {
            window.scrollTo(0, formRef.current.offsetTop - 200);
        }
    }, [confirmation]);
    const formFields = form.formDefinition.fields;
    const formLayout = form.formLayout;
    const confirmationCtaRel = isTrustedHost(confirmationCta || '')
        ? undefined
        : 'noopener noreferrer';
    let formClassName = '';
    switch (styleCodename) {
        case 'DiscoverHeadless':
            formClassName = ' form--discoverheadless';
            break;
        case 'DiscoverDXP':
            formClassName = ' form--discoverdxp';
            break;
    }
    return (<SectionWrapper>
      <Grid layout={GridLayout.FOUR}>
        <GridColumn>
          {title && (<h2 className="align-center orange-dot">{formatTitle(title)}</h2>)}
        </GridColumn>
        <GridColumn className={`form${formColour === 'orange' ? ' bg-orange' : ' bg-black'}${formClassName}`}>
          {errorMessage && (<span className="alert alert--error">{errorMessage}</span>)}
          {confirmation && (<Fragment>
              <p ref={formRef}>{confirmation}</p>
              {confirmationCta && (<a className={`btn ${formColour === 'orange' ? 'btn--black-solid' : 'btn--blue'}`} href={confirmationCta} rel={confirmationCtaRel} 
    // eslint-disable-next-line react/jsx-no-target-blank
    target="_blank">
                  {downloadButtonText ? downloadButtonText : 'Download'}
                </a>)}
            </Fragment>)}
          {!confirmation && (<form noValidate onSubmit={handleSubmit}>
              <fieldset disabled={formLoading}>
                <FormLayout editableAreas={formLayout.editableAreas} formFields={formFields} handleChange={handleChange} formState={formState} targetSite={targetSite}/>
                <button className={`btn ${formColour === 'orange'
        ? 'btn--black-solid'
        : 'btn--orange-solid'} ${formLoading ? 'btn--loading' : ''}`} disabled={formLoading} type="submit">
                  {form.formSubmitButtonText ??
        (submitText ? submitText : 'Submit')}
                </button>
              </fieldset>
            </form>)}
          {pardotIframe && (<iframe src={pardotIframe} width="0" height="0" frameBorder="0" style={{ width: 0, height: 0, border: 0, visibility: 'hidden' }} title="Pardot API iframe"/>)}
        </GridColumn>
      </Grid>
    </SectionWrapper>);
    /**
     * Handle Value Changes.
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    function handleChange(event, type) {
        const target = event.target;
        if (!target) {
            return setFormState({
                ...formState,
            });
        }
        switch (type) {
            case FormFieldType.Checkbox:
            case FormFieldType.ConsentAgreement:
                return setFormState({
                    ...formState,
                    [target.name]: target.checked || '',
                });
            case FormFieldType.FileUploader:
                return setFormState({
                    ...formState,
                    [target.name]: target.files,
                });
            case FormFieldType.MultipleChoice:
                return setFormState({
                    ...formState,
                    [target.name]: target.value,
                });
            default:
                return setFormState({
                    ...formState,
                    [target.name]: target.value,
                });
        }
    }
    async function handleSubmit(event) {
        // Pass to custom submit handler if provided.
        if (onSubmit) {
            return onSubmit(event);
        }
        setFormLoading(true);
        // Prevent default form submission.
        event.preventDefault();
        // Validate current form state.
        if (!(await validateForm())) {
            setFormLoading(false);
            return;
        }
        // Construct new FormData from the form element.
        const formData = createFormData();
        try {
            // Post form data to API.
            const url = `${process.env.GATSBY_XPERIENCE_API}/forms`;
            const res = await fetch(url, {
                body: formData,
                cache: 'no-cache',
                credentials: 'include',
                method: 'POST',
                mode: 'cors',
            });
            // If the request failed then show error message.
            if (!res.ok) {
                setFormLoading(false);
                setErrorMessage('Could not submit the form data.');
                return;
            }
            // Parse response data.
            const resData = (await res.json());
            // The response should include the contact id used for consents.
            const contactId = resData.formContactId ?? 0;
            if (contactId !== 0) {
                Cookie.set('contact-id', contactId.toString());
            }
            // The response should contain three properties indicating what to do:
            //  - formClearAfterSave
            //  - formDisplayText
            //  - formRedirectToUrl
            // Handle clearing form.
            if (resData.formClearAfterSave) {
                setFormLoading(false);
                resetFormState();
                return;
            }
            // Handle displaying content.
            if (resData.formDisplayText) {
                setFormLoading(false);
                setConfirmation(resData.formDisplayText);
                return;
            }
            // Handle redirecting.
            if (resData.formRedirectToUrl) {
                setFormLoading(false);
                window.location.href = resData.formRedirectToUrl;
                return;
            }
        }
        catch (error) {
            setFormLoading(false);
            setErrorMessage('Could not submit the form data.');
        }
    }
};
export default Form;
