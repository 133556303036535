import Image from 'gatsby-image';
import React from 'react';
import SectionWrapper from '@presentation/SectionWrapper';
import getParameterByName from '@utils/getParameterByName';
const InlineAsset = ({ alt, image }) => {
    const width = parseInt(getParameterByName('w', image.src));
    return (<SectionWrapper>
      <div style={{ maxWidth: width + 'px', margin: '0 auto' }}>
        <Image alt={alt} fluid={image}/>
      </div>
    </SectionWrapper>);
};
export default InlineAsset;
