import freeEmailList from '@utils/freeEmailList';
import Cookie from 'js-cookie';
function getCountryState(value) {
    const countryState = value.split(';');
    let qs = '';
    if (countryState[0])
        qs += `country=${encodeURIComponent(countryState[0])}&`;
    if (countryState[1])
        qs += `state=${encodeURIComponent(countryState[1])}&`;
    return qs;
}
async function getGeoIp() {
    try {
        const res = await fetch(`https://pro.ip-api.com/json/?key=XMfm2lAgNcbxcSV&fields=status,country,countryCode,state,isp,org,mobile,proxy,regionName&lang=en`);
        const resData = await res.json();
        if (resData.status !== 'success')
            return '';
        let qs = `country=${encodeURIComponent(resData.country)}&`;
        if (resData.countryCode === 'US' ||
            resData.countryCode === 'CA' ||
            resData.countryCode === 'AU') {
            qs += `state=${encodeURIComponent(resData.regionName)}&`;
        }
        if (!resData.mobile && !resData.proxy && resData.isp !== resData.org) {
            qs += `organization=${encodeURIComponent(resData.org)}&`;
        }
        return qs;
    }
    catch (err) {
        return '';
    }
}
export default async function getKontentPardotUrl(kontentPardotUrl, formState) {
    if (!kontentPardotUrl)
        return null;
    kontentPardotUrl += '?';
    Object.entries(formState).forEach(([key, value]) => {
        if (key === 'ResourceUrl')
            return;
        if (key === 'PageUrl')
            return;
        if (key === 'consentMarketingSalesEU' && value === false)
            return;
        if (key === 'country') {
            kontentPardotUrl += getCountryState(value);
            return;
        }
        if (key === 'email') {
            const email = typeof value === 'string' ? value.trim().toLowerCase() : '';
            const emailDomain = email.split('@')?.[1];
            const isFreeEmail = freeEmailList.includes(emailDomain);
            kontentPardotUrl += `free_email=${isFreeEmail.toString().toUpperCase()}&`;
        }
        kontentPardotUrl += `${key}=${encodeURIComponent(value)}&`;
    });
    if (!kontentPardotUrl.includes('country=')) {
        kontentPardotUrl += await getGeoIp();
    }
    const keys = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_content',
        'utm_type',
        'utm_audience',
        'gclid',
    ];
    for (let i = 0; i < keys.length; i++) {
        const cookieValue = Cookie.get(keys[i]);
        if (cookieValue) {
            kontentPardotUrl += `${keys[i]}=${encodeURIComponent(cookieValue)}&`;
        }
    }
    return kontentPardotUrl.slice(0, -1);
}
