import { graphql } from 'gatsby';
import React from 'react';
import TabbedContentAreaLayout from '@presentation/TabbedContentArea';
import getCtaModel from '@utils/getCtaModel';
const TabbedContentArea = ({ data }) => {
    const groupData = (data.elements?.tab_groups?.linked_items ||
        []);
    const groups = groupData.map(createGroupModel);
    return (<TabbedContentAreaLayout content={data.elements?.content?.value} groups={groups} title={data.elements?.title?.value}/>);
};
function createGroupModel(data) {
    const itemData = (data.elements?.items?.linked_items ||
        []);
    return {
        eventName: data.elements?.event_name?.value,
        icon: data.elements?.icon?.value,
        id: data.system?.id || '',
        items: itemData.map(createItemModel),
        name: data.elements?.name?.value || '',
    };
}
function createItemModel(data) {
    const [ctaText, ctaTo, ctaIsExternal] = getCtaModel(data.elements?.cta__cta_text?.value, data.elements?.cta__cta_link__external?.value, data.elements?.cta__cta_link__internal?.linked_items?.[0]);
    return {
        content: data.elements?.content?.value || '',
        ctaEventName: data.elements?.cta__cta_event_name?.value,
        ctaIsExternal,
        ctaText,
        ctaTo,
        eventName: data.elements?.event_name?.value,
        icon: data.elements?.icon?.value,
        id: data.system?.id || '',
        image: data.elements?.image?.value?.[0]?.fluid,
        name: data.elements?.name?.value || '',
        title: data.elements?.title?.value || '',
    };
}
export default TabbedContentArea;
export const KontentItemTabbedContentAreaFragment = graphql `
  fragment KontentItemTabbedContentAreaFragment on KontentItemTabbedContentArea {
    elements {
      content {
        value
      }
      title {
        value
      }
      tab_groups {
        linked_items {
          system {
            id
          }
          ... on KontentItemTabGroup {
            ...KontentItemTabGroupFragment
          }
        }
      }
    }
  }
`;
export const KontentItemTabGroupFragment = graphql `
  fragment KontentItemTabGroupFragment on KontentItemTabGroup {
    elements {
      event_name {
        value
      }
      icon {
        value
      }
      name {
        value
      }
      items {
        linked_items {
          system {
            id
          }
          ... on KontentItemTabItem {
            ...KontentItemTabItemFragment
          }
        }
      }
    }
  }
`;
export const KontentItemTabItemFragment = graphql `
  fragment KontentItemTabItemFragment on KontentItemTabItem {
    elements {
      content {
        value
      }
      event_name {
        value
      }
      cta__cta_event_name {
        value
      }
      cta__cta_link__external {
        value
      }
      cta__cta_link__internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      cta__cta_text {
        value
      }
      icon {
        value
      }
      image {
        value {
          fluid(maxWidth: 600) {
            ...KontentAssetFluid_withWebp
          }
        }
      }
      name {
        value
      }
      title {
        value
      }
    }
  }
`;
