import { graphql } from 'gatsby';
import React from 'react';
import RichContentWithComponents from '@connected/RichContentWithComponents';
const RichContentStandard = ({ data }) => {
    const content = data.elements?.content?.resolvedData?.html ?? '';
    const images = data.elements?.content?.images ?? [];
    const links = data.elements?.content?.links ?? [];
    return (<RichContentWithComponents content={content} images={images} linkedItems={[]} links={links}/>);
};
export default RichContentStandard;
export const KontentItemRichContentStandardFragment = graphql `
  fragment KontentItemRichContentStandardFragment on KontentItemRichContentStandard {
    elements {
      content {
        images {
          description
          imageId
          fluid(maxWidth: 1200) {
            ...KontentAssetFluid_withWebp
          }
        }
        links {
          codename
          linkId
          type
          urlSlug
        }
        resolvedData {
          html
        }
      }
    }
  }
`;
