import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
const SEO = ({ description = '', image = '', keywords = '', canonicalUrl = '', lang = 'en', title = '', url = '', }) => {
    const { site } = useStaticQuery(graphql `
      query {
        site {
          siteMetadata {
            author
            description
            origin
            title
          }
        }
      }
    `);
    const fullUrl = canonicalUrl || `${site.siteMetadata?.origin}${url}`;
    const metaDescription = description || site?.siteMetadata?.description;
    return (<Helmet htmlAttributes={{
        lang,
    }} title={title} titleTemplate={`%s | ${site?.siteMetadata?.title}`} meta={[
        {
            name: `description`,
            content: metaDescription,
        },
        {
            name: `keywords`,
            content: keywords,
        },
        {
            property: `og:title`,
            content: title,
        },
        {
            property: `og:description`,
            content: metaDescription,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            property: `og:image`,
            content: image,
        },
        {
            name: `twitter:card`,
            content: `summary_large_image`,
        },
        {
            name: `twitter:creator`,
            content: site?.siteMetadata?.author,
        },
        {
            name: `twitter:title`,
            content: title,
        },
        {
            name: `twitter:description`,
            content: metaDescription,
        },
        {
            property: `twitter:image`,
            content: image,
        },
    ]}>
      <link rel="canonical" href={fullUrl}/>
      
      <link rel="preload" href="/fonts/GT-Walsheim.css" as="style"/>
      <link rel="preload" href="/fonts/GT-Walsheim/GT-Walsheim-Medium.woff" as="font" type="font/woff" crossOrigin="anonymous"/>
      <link rel="preload" href="/fonts/GT-Walsheim/GT-Walsheim-Bold.woff" as="font" type="font/woff" crossOrigin="anonymous"/>
      <link rel="preload" href="/fonts/GT-Walsheim/GT-Walsheim-Regular.woff" as="font" type="font/woff" crossOrigin="anonymous"/>
      <link rel="preload" href="/fonts/GT-Walsheim/GT-Walsheim-Light.woff" as="font" type="font/woff" crossOrigin="anonymous"/>
    </Helmet>);
};
export default SEO;
export const SEOFragment = graphql `
  fragment SEOFragment on NodeWithMetadata {
    metadata {
      title
      description
      keywords
      canonicalUrl
    }
  }
`;
