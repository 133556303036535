import { useEffect, useState } from 'react';
import useInView from '@hooks/useInView';
export default function useInViewAnimation({ ref, variants, once = true, }) {
    const [done, setDone] = useState(false);
    const inView = useInView(ref);
    useEffect(() => {
        if (inView) {
            setDone(true);
        }
    }, [inView]);
    return {
        animate: (once && done) || inView ? 'visible' : 'hidden',
        initial: 'hidden',
        variants: {
            hidden: {
                ...variants.hidden,
                transition: {
                    duration: 0.6,
                    ease: [0.215, 0.61, 0.355, 1],
                    staggerChildren: 0.1,
                    ...variants.hidden.transition,
                },
            },
            visible: {
                ...variants.visible,
                transition: {
                    duration: 0.6,
                    ease: [0.215, 0.61, 0.355, 1],
                    staggerChildren: 0.1,
                    ...variants.visible.transition,
                },
            },
        },
    };
}
