import { useStaticQuery, graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import countryAndStateList from '../../../../utils/countryAndStateList';
import Select from './Select';
import { FormFieldType } from '../Form.types';
const CountrySelector = ({ inputProps, type, targetSite, onChange, }) => {
    const optionLabel = 'Select from the list';
    let options;
    let countryAndState;
    let fieldName;
    if (targetSite === 'kontent') {
        fieldName = 'country';
        countryAndState = countryAndStateList;
        options = countryAndStateList.map(country => {
            return {
                label: country.countryDisplayName,
                value: country.countryName,
            };
        });
    }
    else {
        const xperienceCountryState = useStaticQuery(graphql `
      {
        allXperienceCountry {
          nodes {
            countryDisplayName
            countryName
            countryStates {
              stateName
              stateDisplayName
            }
          }
        }
      }
    `);
        fieldName = inputProps.name;
        countryAndState = xperienceCountryState.allXperienceCountry.nodes;
        options = xperienceCountryState.allXperienceCountry.nodes?.map(country => {
            return {
                label: country.countryDisplayName,
                value: country.countryName,
            };
        });
    }
    const [countryStates, setCountryStates] = useState([]);
    const [countrySelected, setCountrySelected] = useState();
    const [countryStateSelected, setCountryStateSelected] = useState();
    useEffect(() => {
        const countrySelectedList = countryAndState?.find(country => {
            return (country.countryName === countrySelected && country.countryStates?.length);
        });
        const stateOptions = countrySelectedList?.countryStates?.map(state => {
            return {
                value: state.stateName,
                label: state.stateDisplayName,
            };
        });
        setCountryStates(stateOptions || []);
        const fakeEvent = {};
        fakeEvent.target = {};
        fakeEvent.target.name = fieldName || '';
        fakeEvent.target.value =
            countrySelected || countryStateSelected
                ? `${countrySelected || ''}${countryStateSelected ? `;${countryStateSelected}` : ''}`
                : '';
        onChange(fakeEvent, FormFieldType.CountrySelector);
    }, [countrySelected, countryStateSelected]);
    return (<>
      <Select inputProps={inputProps} optionLabel={optionLabel || ''} options={options || []} type={type} value={countrySelected} onChange={e => {
        setCountryStateSelected('');
        setCountrySelected(e.target.value);
    }}/>
      {!!countryStates.length && (<Select inputProps={inputProps} optionLabel={optionLabel || ''} options={countryStates || []} type={type} value={countryStateSelected} onChange={e => setCountryStateSelected(e.target.value)}/>)}
    </>);
};
export default CountrySelector;
