import { graphql } from 'gatsby';
import React from 'react';
import LinkedItem from '@connected/LinkedItem';
import StaggeredCardListLayout from '@layouts/StaggeredCardList';
import getCtaModel from '@utils/getCtaModel';
const StaggeredCardList = ({ data }) => {
    const cards = data.elements?.cards?.linked_items || [];
    const items = cards.map((card, index) => (<LinkedItem key={card.system?.id} index={index} item={card}/>));
    const [ctaText, ctaTo, ctaIsExternal] = getCtaModel(data.elements?.cta__cta_text?.value, data.elements?.cta__cta_link__external?.value, data.elements?.cta__cta_link__internal?.linked_items?.[0]);
    return (<StaggeredCardListLayout content={data.elements?.content?.value} ctaEventName={data.elements?.cta__cta_event_name?.value} ctaIsExternal={ctaIsExternal} ctaText={ctaText} ctaTo={ctaTo} title={data.elements?.title?.value || ''}>
      {items}
    </StaggeredCardListLayout>);
};
export default StaggeredCardList;
export const KontentItemStaggeredCardListFragment = graphql `
  fragment KontentItemStaggeredCardListFragment on KontentItemStaggeredCardList {
    elements {
      cards {
        linked_items {
          system {
            id
            type
          }
          ... on KontentItem {
            ...CardFragment
          }
        }
      }
      content {
        value
      }
      cta__cta_event_name {
        value
      }
      cta__cta_link__external {
        value
      }
      cta__cta_link__internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      cta__cta_text {
        value
      }
      title {
        value
      }
    }
  }
`;
