import React, { Fragment } from 'react';
import FormField from './FormField';
const FormZone = ({ formComponents, formFields, formState, targetSite, handleChange, }) => {
    return (<Fragment>
      {formComponents &&
        formComponents.map(component => {
            const item = formFields?.find(f => f.id === component.identifier);
            return (item && (<FormField {...item} key={item.id} type={item.componentName} value={formState[item.name]} onChange={handleChange} targetSite={targetSite}/>));
        })}
    </Fragment>);
};
export default FormZone;
