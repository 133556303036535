import { graphql } from 'gatsby';
import React from 'react';
import VideoLayout from '@presentation/Video';
const Video = ({ data }) => {
    return (<VideoLayout title={data.elements?.title?.value} subtitle={data.elements?.subtitle?.value} image={data.elements?.image?.value?.[0]?.fluid} video={data.elements?.video?.value}/>);
};
export default Video;
export const KontentItemVideoFragment = graphql `
  fragment KontentItemVideoFragment on KontentItemVideo {
    elements {
      title {
        value
      }
      subtitle {
        value
      }
      image {
        value {
          fluid(maxWidth: 1200) {
            ...KontentAssetFluid_withWebp
          }
        }
      }
      video {
        value
      }
    }
  }
`;
