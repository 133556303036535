import { graphql } from 'gatsby';
import React from 'react';
import TimelineLayout from '@presentation/Timeline';
const Timeline = ({ data }) => {
    const groups = getGroups(data);
    if (!groups.length) {
        return null;
    }
    return <TimelineLayout groups={groups}/>;
};
function getGroups(data) {
    const groupData = data.elements?.timeline_groups?.linked_items;
    return groupData?.map(createGroup) ?? [];
}
function createGroup(data) {
    return {
        id: data.system?.id ?? '',
        items: getItems(data),
    };
}
function getItems(data) {
    const itemData = data.elements?.timeline_items?.linked_items;
    return itemData?.map(createItem) ?? [];
}
function createItem(data) {
    return {
        id: data.system?.id ?? '',
        content: data.elements?.content?.value,
        text: data.elements?.text?.value,
        image: data.elements?.image?.value?.[0]?.fluid,
        logo: data.elements?.logo?.value?.[0]?.fixed,
        subtitle: data.elements?.subtitle?.value,
        title: data.elements?.title?.value,
        year: data.elements?.year?.value,
    };
}
export default Timeline;
export const KontentItemTimelineFragment = graphql `
  fragment KontentItemTimelineFragment on KontentItemTimeline {
    elements {
      timeline_groups {
        linked_items {
          system {
            id
          }
          ...KontentItemTimelineGroupFragment
        }
      }
    }
  }
`;
export const KontentItemTimelineGroupFragment = graphql `
  fragment KontentItemTimelineGroupFragment on KontentItemTimelineGroup {
    elements {
      timeline_items {
        linked_items {
          system {
            id
          }
          ...KontentItemTimelineItemFragment
        }
      }
    }
  }
`;
export const KontentItemTimelineItemFragment = graphql `
  fragment KontentItemTimelineItemFragment on KontentItemTimelineItem {
    elements {
      year {
        value
      }
      title {
        value
      }
      subtitle {
        value
      }
      content {
        value
      }
      text {
        value
      }
      image {
        value {
          fluid(maxWidth: 540) {
            ...KontentAssetFluid_withWebp
          }
        }
      }
      logo {
        value {
          fixed(width: 180) {
            ...KontentAssetFixed_withWebp
          }
        }
      }
    }
  }
`;
