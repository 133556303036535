import classNames from 'classnames';
import React from 'react';
import { SectionBackground, } from './SectionWrapper.types';
const SectionWrapper = ({ bg = SectionBackground.None, children, className = '', fullWidth = false, margin = true, padding = false, id, }) => {
    const sectionClassName = classNames({
        [className]: true,
        'bg-light-grey': bg === SectionBackground.LightGrey,
        'vert-margin-100': margin && bg === SectionBackground.None,
        'vert-padding-100': padding || bg !== SectionBackground.None,
    }).trim();
    const inputProps = {
        id: id,
    };
    if (fullWidth) {
        return <section className={sectionClassName}>{children}</section>;
    }
    return (<section className={sectionClassName} {...inputProps}>
      <div className="site-wide">{children}</div>
    </section>);
};
export default SectionWrapper;
