import { graphql, useStaticQuery } from 'gatsby';
function useXperienceForm(formName) {
    const forms = useXperienceFormData();
    return forms?.allXperienceForm?.nodes?.find(f => f.formName.toLowerCase() === formName?.toLowerCase());
}
function useXperienceFormData() {
    const data = useStaticQuery(graphql `
      {
        allXperienceForm {
          nodes {
            id
            formClearAfterSave
            formDisplayName
            formGUID
            formId
            formName
            formDefinition {
              version
              fields {
                caption
                componentName
                controlName
                datasource
                defaultValue
                description
                id
                isKey
                isPublic
                isRequired
                isSystem
                isVisible
                label
                name
                optionLabel
                placeholder
                size
                type
                propertiesRaw
                settingsRaw
              }
            }
            formLayout {
              editableAreas {
                identifier
                sections {
                  identifier
                  type
                  zones {
                    identifier
                    formComponents {
                      identifier
                    }
                  }
                }
              }
            }
          }
        }
      }
    `);
    return data;
}
export { useXperienceFormData as default, useXperienceForm };
