import React from 'react';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import fixImageFluidSrcset from '@utils/fixImageFluidSrcset';
import resolveUrl from '@utils/resolveUrl';
import Tag from '@presentation/Tag';
import { CardDepartment } from '@presentation/Tag/Tag.types';
const ProfileCard = ({ data }) => {
    const slug = data?.elements?.slug?.value;
    const image = fixImageFluidSrcset(data?.elements?.avatar?.value?.[0]?.fluid);
    const name = data?.elements?.standard_content__title?.value;
    const position = data?.elements?.title?.value;
    const departments = data?.elements?.department?.value;
    const department = departments && departments[0]?.codename ? departments[0].codename : '';
    const departmentName = departments && departments[0]?.codename ? departments[0].name : '';
    const type = (department => {
        switch (department) {
            case 'kontent':
                return CardDepartment.Kontent;
            case 'xperience':
                return CardDepartment.Xperience;
            default:
                return CardDepartment.Kentico;
        }
    })(department);
    return (<Link className="card" to={resolveUrl(data?.system?.type || 'profile', slug || '')}>
      <div className="card__inner">
        {image && (<div className="card__media">
            <div className="card__media-holder">
              <Image className="card__media-element card__media-element--high" alt={name} fluid={image}/>
            </div>
          </div>)}
        <div className="card__content-external">
          {departmentName && departmentName !== 'None' && (<Tag tag={departmentName} type={type}/>)}
          <div className="card__content-internal">
            <h2 className="card__title">{name}</h2>
            <p className="card__blog-detail">{position}</p>
          </div>
        </div>
      </div>
    </Link>);
};
export default ProfileCard;
export const KontentItemStatProfileFragment = graphql `
  fragment KontentItemStatProfileFragment on KontentItemProfile {
    system {
      type
    }
    elements {
      title {
        value
      }
      standard_content__title {
        value
      }
      avatar {
        value {
          fluid(maxWidth: 325) {
            ...KontentAssetFluid_withWebp
          }
        }
      }
      department {
        value {
          codename
          name
        }
      }
      slug {
        value
      }
    }
  }
`;
