import { motion } from 'framer-motion';
import Image from 'gatsby-image';
import React, { useRef } from 'react';
import classNames from 'classnames';
import useFadeUp from '@hooks/animation/useFadeUp';
import { Grid, GridColumn } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import { ButtonVariant } from '@presentation/Button/Button.types';
import Link from '@presentation/Link';
import formatTitle from '@utils/formatTitle';
const Section = ({ title, content, ctaEventName, ctaIsExternal, ctaText, ctaTo, image1, image2, backgroundStyle, backgroundShape, imageStyle, ctaStyle, customCodenameStyle, type, center, contentPosition, }) => {
    const containerRef = useRef(null);
    const titleProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    const contentProps = useFadeUp({ ref: containerRef, delay: 0.2 });
    const ctaProps = useFadeUp({ ref: containerRef, delay: 0.3 });
    const className = classNames('section', 'site-wide', {
        [`section--${customCodenameStyle}`]: !!customCodenameStyle,
        [`section--center`]: center,
        [`section--bg-${backgroundStyle}`]: !!backgroundStyle,
        [`section--img-${imageStyle}`]: !!imageStyle,
        [`section--cta-${ctaStyle}`]: !!ctaStyle,
        [`section--2-images`]: image1 && image2,
        [`section--${backgroundShape}`]: !!backgroundShape,
        [`section--${contentPosition}`]: !!contentPosition,
    });
    let linkVariant;
    if (!ctaStyle || ctaStyle === 'button') {
        linkVariant = ButtonVariant.OrangeSolid;
    }
    else if (ctaStyle === 'button_orange_border') {
        linkVariant = ButtonVariant.OrangeBorder;
    }
    else {
        linkVariant = ButtonVariant.LinkUnderlineArrowOrange;
    }
    const CustomTag = type === 'hero' ? motion.h1 : motion.h2;
    return (<div ref={containerRef} className={className} id={customCodenameStyle}>
      {backgroundShape === 'circle' && <div className="section__circle"></div>}
      <Grid layout={GridLayout.FULL_INDENT}>
        <GridColumn>
          <div className="section__inner">
            {!customCodenameStyle && image2 && (<div className="section__image section__image--2">
                <Image className="section__media" alt={title} loading="lazy" fluid={image2} imgStyle={{ objectFit: 'contain' }}/>
              </div>)}
            <div className="section__text">
              <CustomTag className="section__title" {...titleProps}>
                {formatTitle(title)}
              </CustomTag>
              <motion.p className="section__content" {...contentProps} dangerouslySetInnerHTML={{ __html: content || '' }}/>
              {ctaText && ctaTo && (<motion.div className="section__cta" {...ctaProps}>
                  <Link eventName={ctaEventName} isExternal={ctaIsExternal} text={ctaText} to={ctaTo} variant={linkVariant}/>
                </motion.div>)}
            </div>
            {!customCodenameStyle && image1 && (<div className="section__image section__image--1">
                <Image className="section__media" alt={title} loading="lazy" fluid={image1} imgStyle={{ objectFit: 'contain' }}/>
              </div>)}
          </div>
        </GridColumn>
      </Grid>
      {customCodenameStyle === 'nextcms' && image1 && (<div className="section__image-nextcms">
          <Image className="section__media-nextcms" alt={title} loading="lazy" fluid={image1} imgStyle={{ objectFit: 'contain' }}/>
        </div>)}
    </div>);
};
export default Section;
