import React from 'react';
import { graphql } from 'gatsby';
import SectionElem from '@presentation/Section';
import getCtaModel from '@utils/getCtaModel';
const Section = ({ data }) => {
    const [ctaText, ctaTo, ctaIsExternal] = getCtaModel(data.elements?.cta__cta_text?.value, data.elements?.cta__cta_link__external?.value, data.elements?.cta__cta_link__internal?.linked_items?.[0]);
    return (<SectionElem content={data.elements?.content?.value} ctaEventName={data.elements?.cta__cta_event_name?.value} ctaIsExternal={ctaIsExternal} ctaText={ctaText} ctaTo={ctaTo} image1={data.elements?.image?.value?.[0]?.fluid} image2={data.elements?.image?.value?.[1]?.fluid} title={data.elements?.title?.value} backgroundStyle={data.elements?.background?.value?.[0]?.codename} backgroundShape={data.elements?.background_shape?.value?.[0]?.codename} contentPosition={data.elements?.content_position?.value?.[0]?.codename} imageStyle={data.elements?.image_style?.value?.[0]?.codename} ctaStyle={data.elements?.call_to_action?.value?.[0]?.codename} customCodenameStyle={data.elements?.custom_styling_codename?.value} type={data.elements?.section_type?.value?.[0]?.codename} center={data.elements?.center?.value?.[0]?.codename === 'yes'}/>);
};
export default Section;
export const KontentItemSectionFragment = graphql `
  fragment KontentItemSectionFragment on KontentItemSection {
    elements {
      content {
        value
      }
      cta__cta_event_name {
        value
      }
      cta__cta_link__external {
        value
      }
      cta__cta_link__internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      cta__cta_text {
        value
      }
      image {
        value {
          fluid(maxWidth: 800) {
            ...KontentAssetFluid_withWebp_noBase64
          }
        }
      }
      title {
        value
      }
      center {
        value {
          codename
        }
      }
      background_shape {
        value {
          codename
        }
      }
      background {
        value {
          codename
        }
      }
      content_position {
        value {
          codename
        }
      }
      image_style {
        value {
          codename
        }
      }
      section_type {
        value {
          codename
        }
      }
      call_to_action {
        value {
          codename
        }
      }
      custom_styling_codename {
        value
      }
    }
  }
`;
