import { motion } from 'framer-motion';
import Image from 'gatsby-image';
import React, { useRef } from 'react';
import useFadeUp from '@hooks/animation/useFadeUp';
import IconRose from '@icons/IconRose';
import { Grid, GridColumn } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import SectionWrapper from '@presentation/SectionWrapper';
import formatTitle from '@utils/formatTitle';
const TimelineItem = ({ item }) => {
    const elementRef = useRef(null);
    const animProps = useFadeUp({ ref: elementRef, delay: 0.1 });
    return (<motion.div ref={elementRef} className="timeline__item" {...animProps}>
      <div className="timeline__item-outer">
        {item.image && (<Image className="timeline__item-image" fluid={item.image}/>)}
        <div className="timeline__item-inner">
          <h2 className="timeline__item-title remove-dot">
            {formatTitle(item.year || item.title)}
          </h2>
          {item.logo && (<Image className="timeline__item-logo" fixed={item.logo} imgStyle={{ objectFit: 'contain' }}/>)}
          <h3 className="timeline__item-subtitle">
            {formatTitle(item.subtitle)}
          </h3>
          <div className="timeline__item-content" dangerouslySetInnerHTML={{ __html: item.text || '' }}/>
        </div>
      </div>
    </motion.div>);
};
const TimelineGroup = ({ group }) => {
    return (<div className="timeline__group">
      <div className="timeline__group-inner">
        {group.items.map(item => (<TimelineItem key={item.id} item={item}/>))}
      </div>
    </div>);
};
const Timeline = ({ groups }) => {
    return (<SectionWrapper padding={false} margin={false}>
      <Grid layout={GridLayout.FULL}>
        <GridColumn>
          <div className="timeline">
            <div className="timeline__icon">
              <IconRose />
            </div>
            <div className="timeline__items">
              {groups.map(group => (<TimelineGroup key={group.id} group={group}/>))}
            </div>
          </div>
        </GridColumn>
      </Grid>
    </SectionWrapper>);
};
export default Timeline;
