import { graphql } from 'gatsby';
import React from 'react';
import PageBanner from '@presentation/PageBanner';
import { PageBannerType } from '@presentation/PageBanner/PageBanner.types';
import getCtaModel from '@utils/getCtaModel';
const PageBannerThin = ({ data }) => {
    const [ctaText, ctaTo, ctaIsExternal] = getCtaModel(data.elements?.cta__cta_text?.value, data.elements?.cta__cta_link__external?.value, data.elements?.cta__cta_link__internal?.linked_items?.[0]);
    return (<PageBanner backgroundImage={data.elements?.image?.value?.[0].fluid} content={data.elements?.content?.value} ctaEventName={data.elements?.cta__cta_event_name?.value} ctaIsExternal={ctaIsExternal} ctaText={ctaText} ctaTo={ctaTo} title={data.elements?.title?.value} type={PageBannerType.Thin}/>);
};
export default PageBannerThin;
export const KontentItemPageBannerThinFragment = graphql `
  fragment KontentItemPageBannerThinFragment on KontentItemPageBannerThin {
    elements {
      image {
        value {
          fluid(maxWidth: 1920) {
            ...KontentAssetFluid_withWebp
          }
        }
      }
      content {
        value
      }
      cta__cta_event_name {
        value
      }
      cta__cta_link__external {
        value
      }
      cta__cta_link__internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      cta__cta_text {
        value
      }
      title {
        value
      }
    }
  }
`;
