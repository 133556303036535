import { graphql } from 'gatsby';
import React from 'react';
import LinkedItem from '@connected/LinkedItem';
import FeaturedCardListLayout from '@layouts/FeaturedCardList';
import getCtaModel from '@utils/getCtaModel';
const FeaturedCardList = ({ data }) => {
    const featuredCard = data.elements?.featured_card?.linked_items?.[0];
    const cards = data.elements?.cards?.linked_items || [];
    const [ctaText, ctaTo, ctaIsExternal] = getCtaModel(data.elements?.cta__cta_text?.value, data.elements?.cta__cta_link__external?.value, data.elements?.cta__cta_link__internal?.linked_items?.[0]);
    const ctaEventName = data.elements?.cta__cta_event_name?.value;
    return (<FeaturedCardListLayout content={data.elements?.content?.value} ctaEventName={ctaEventName} ctaIsExternal={ctaIsExternal} ctaText={ctaText} ctaTo={ctaTo} featuredCard={featuredCard && <LinkedItem item={featuredCard}/>} title={data.elements?.title?.value || ''}>
      {cards.map(item => (<LinkedItem key={item.system?.id || ''} item={item}/>))}
    </FeaturedCardListLayout>);
};
export default FeaturedCardList;
export const KontentItemFeaturedCardListFragment = graphql `
  fragment KontentItemFeaturedCardListFragment on KontentItemFeaturedCardList {
    elements {
      cards {
        linked_items {
          system {
            id
            type
          }
          ... on KontentItem {
            ...CardFragment
          }
        }
      }
      content {
        value
      }
      cta__cta_event_name {
        value
      }
      cta__cta_link__external {
        value
      }
      cta__cta_link__internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      cta__cta_text {
        value
      }
      featured_card {
        linked_items {
          system {
            id
            type
          }
          ... on KontentItem {
            ...CardFragment
          }
        }
      }
      title {
        value
      }
    }
  }
`;
