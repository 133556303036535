import { graphql } from 'gatsby';
import React from 'react';
import StoriesLayout from '@presentation/Stories';
import getCtaModel from '@utils/getCtaModel';
const Stories = ({ data }) => {
    return <StoriesLayout items={getItems(data)}/>;
};
function getItems(data) {
    const itemData = data.elements?.items?.linked_items;
    return itemData?.map(createItem) ?? [];
}
function createQuote(item) {
    return {
        name: item.elements?.name?.value || '',
        text: item.elements?.text?.value || '',
        photo: item.elements?.photo?.value?.[0]?.fluid,
        position: item.elements?.position?.value || '',
        company: item.elements?.company?.value || '',
    };
}
function createItem(data) {
    const [ctaText, ctaTo, ctaIsExternal] = getCtaModel(data.elements?.cta__cta_text?.value, data.elements?.cta__cta_link__external?.value, data.elements?.cta__cta_link__internal?.linked_items?.[0]);
    const quoteItems = (data.elements?.quotes?.linked_items ||
        []);
    const quotes = quoteItems.map(createQuote);
    return {
        id: data.system?.id ?? '',
        codename: data.system?.codename ?? '',
        text: data.elements?.text,
        title: data.elements?.title?.value,
        styleCodename: data.elements?.codename?.value,
        ctaText: ctaText,
        ctaTo: ctaTo,
        ctaIsExternal: ctaIsExternal,
        ctaEventName: data.elements?.cta__cta_event_name?.value,
        quotes: quotes,
    };
}
export default Stories;
export const KontentItemStoriesFragment = graphql `
  fragment KontentItemStoriesFragment on KontentItemStories {
    elements {
      items {
        linked_items {
          ...KontentItemStoryItemFragment
        }
      }
    }
  }
`;
export const KontentItemStoryItemFragment = graphql `
  fragment KontentItemStoryItemFragment on KontentItemStoryItem {
    system {
      codename
      id
    }
    elements {
      title {
        value
      }
      text {
        images {
          description
          imageId
          fluid(maxWidth: 1200) {
            ...KontentAssetFluid_withWebp
          }
        }
        links {
          codename
          linkId
          type
          urlSlug
        }
        resolvedData {
          html
        }
      }
      cta__cta_event_name {
        value
      }
      cta__cta_link__external {
        value
      }
      cta__cta_link__internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      cta__cta_text {
        value
      }
      codename {
        value
      }
      quotes {
        linked_items {
          ... on KontentItemQuote {
            elements {
              text {
                value
              }
              name {
                value
              }
              photo {
                value {
                  fluid(maxWidth: 400) {
                    ...KontentAssetFluid_withWebp_noBase64
                  }
                }
              }
              position {
                value
              }
              company {
                value
              }
              codename {
                value
              }
            }
          }
        }
      }
    }
  }
`;
