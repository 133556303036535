import React from 'react';
/**
 * Format the title strings to wrap full stops.
 * @param title The source title string value
 */
function formatTitle(title) {
    if (!title) {
        return null;
    }
    title = title.trim();
    const chars = [...title];
    return chars.reduce((output, char, index) => {
        if (char === '.' || char === '?' || char === '!') {
            return [
                ...output,
                <span key={index} className="text-orange">
          {char}
        </span>,
            ];
        }
        return [...output, char];
    }, []);
}
export default formatTitle;
