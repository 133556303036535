import { graphql } from 'gatsby';
import React from 'react';
import LinkedItem from '@connected/LinkedItem';
import CardGridLayout from '@layouts/CardGrid';
const CardGrid = ({ data }) => {
    const cards = data.elements?.cards?.linked_items || [];
    return (<CardGridLayout title={data.elements?.title?.value} image={data.elements?.image?.value?.[0]?.fluid} codename={data.elements?.codename?.value}>
      {cards.map(item => (<LinkedItem key={item.system?.id || ''} item={item}/>))}
    </CardGridLayout>);
};
export default CardGrid;
export const KontentItemCardGridFragment = graphql `
  fragment KontentItemCardGridFragment on KontentItemCardGrid {
    elements {
      title {
        value
      }
      image {
        value {
          fluid(maxWidth: 800) {
            ...KontentAssetFluid_withWebp_noBase64
          }
        }
      }
      cards {
        linked_items {
          system {
            id
            type
          }
          ... on KontentItem {
            ...CardFragment
          }
        }
      }
      codename {
        value
      }
    }
  }
`;
