import { graphql } from 'gatsby';
import React from 'react';
import LinkedItem from '@connected/LinkedItem';
import CardListLayout from '@layouts/CardList';
import getCtaModel from '@utils/getCtaModel';
import { LinkedItemType } from '../LinkedItem/LinkedItem.types';
const CardList = ({ data }) => {
    const cards = data.elements?.cards?.linked_items || [];
    const [ctaText, ctaTo, ctaIsExternal] = getCtaModel(data.elements?.cta__cta_text?.value, data.elements?.cta__cta_link__external?.value, data.elements?.cta__cta_link__internal?.linked_items?.[0]);
    const ctaEventName = data.elements?.cta__cta_event_name?.value;
    const ctaStyle = data.elements?.cta_style?.value?.[0]?.codename;
    // Sort article cards by start_date
    cards.sort((a, b) => {
        const aType = a.system?.type;
        const bType = b.system?.type;
        if (aType === LinkedItemType.Article && bType === LinkedItemType.Article) {
            const aItem = a;
            const bItem = b;
            return (new Date(bItem?.elements?.start_date?.value || '').getTime() -
                new Date(aItem?.elements?.start_date?.value || '').getTime());
        }
        return 0;
    });
    return (<CardListLayout ctaEventName={ctaEventName} ctaIsExternal={ctaIsExternal} ctaText={ctaText} ctaTo={ctaTo} ctaStyle={ctaStyle} ctaPosition={data.elements?.cta_position?.value?.[0]?.codename} title={data.elements?.title?.value || ''} content={data.elements?.content?.value || ''}>
      {cards.map(item => (<LinkedItem key={item.system?.id || ''} item={item}/>))}
    </CardListLayout>);
};
export default CardList;
export const KontentItemCardListFragment = graphql `
  fragment KontentItemCardListFragment on KontentItemCardList {
    elements {
      title {
        value
      }
      content {
        value
      }
      cta_position {
        value {
          codename
        }
      }
      cta__cta_event_name {
        value
      }
      cta__cta_link__external {
        value
      }
      cta__cta_link__internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      cta__cta_text {
        value
      }
      cta_style {
        value {
          codename
        }
      }
      cards {
        linked_items {
          system {
            id
            type
          }
          ... on KontentItem {
            ...CardFragment
          }
        }
      }
    }
  }
`;
