export var CardType;
(function (CardType) {
    CardType["Unknown"] = "";
    CardType["BlogPost"] = "blog_post";
    CardType["Event"] = "event";
    CardType["Report"] = "report";
    CardType["Resource"] = "resource";
    CardType["News"] = "news";
})(CardType || (CardType = {}));
export var CardDepartment;
(function (CardDepartment) {
    CardDepartment["Unknown"] = "";
    CardDepartment["Kontent"] = "kontent";
    CardDepartment["Xperience"] = "xperience";
    CardDepartment["Kentico"] = "kentico";
})(CardDepartment || (CardDepartment = {}));
export var CardLayout;
(function (CardLayout) {
    CardLayout["Default"] = "Default";
    CardLayout["Featured"] = "Featured";
    CardLayout["Landscape"] = "Landscape";
    CardLayout["FeaturedLandscape"] = "FeaturedLandscape";
})(CardLayout || (CardLayout = {}));
