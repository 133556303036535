import React from 'react';
import { Grid } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import SectionWrapper from '@presentation/SectionWrapper';
const BasicGrid = ({ type, children }) => {
    let gridType = GridLayout.FULL;
    switch (type) {
        case 'full':
            gridType = GridLayout.FULL;
            break;
        case 'half':
            gridType = GridLayout.HALF;
            break;
        case 'full_indent':
            gridType = GridLayout.FULL_INDENT;
            break;
        case 'half_indent':
            gridType = GridLayout.HALF_INDENT;
            break;
    }
    return (<SectionWrapper>
      <Grid layout={gridType}>{children}</Grid>
    </SectionWrapper>);
};
export default BasicGrid;
