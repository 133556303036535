import { graphql } from 'gatsby';
import React from 'react';
import ArticleCard from '@components/connected/ArticleCard';
import BasicGrid from '@connected/BasicGrid';
import CardGrid from '@connected/CardGrid';
import CardList from '@connected/CardList';
import ContentCard from '@connected/ContentCard';
import CtaLink from '@connected/CtaLink';
import CtaCard from '@connected/CtaCard';
import Custom from '@connected/Custom';
import ProfileCard from '@components/connected/ProfileCard';
import FacetedCardList from '@connected/FacetedCardList';
import FeaturedCardList from '@connected/FeaturedCardList';
import Form from '@connected/Form';
import ImageCarousel from '@connected/ImageCarousel';
import LogoCarousel from '@connected/LogoCarousel';
import PageBannerThick from '@connected/PageBannerThick';
import PageBannerThin from '@connected/PageBannerThin';
import ProductOverview from '@connected/ProductOverview';
import Quote from '@connected/Quote';
import QuoteList from '@connected/QuoteList';
import RichContent from '@connected/RichContent';
import RichContentStandard from '@connected/RichContentStandard';
import Section from '@connected/Section';
import SplitContent from '@connected/SplitContent';
import StaggeredCardList from '@connected/StaggeredCardList';
import StatCard from '@connected/StatCard';
import StaticText from '@connected/StaticText';
import Stories from '@connected/Stories';
import TabbedContentArea from '@connected/TabbedContentArea';
import TestimonialCarousel from '@connected/TestimonialCarousel';
import TextWithImage from '@connected/TextWithImage';
import Timeline from '@connected/Timeline';
import Video from '@connected/Video';
import { LinkedItemType } from './LinkedItem.types';
const LinkedItem = ({ item, ...otherProps }) => {
    const type = item.system?.type;
    switch (type) {
        case LinkedItemType.Article:
            return <ArticleCard data={item} {...otherProps}/>;
        case LinkedItemType.BasicGrid:
            return <BasicGrid data={item} {...otherProps}/>;
        case LinkedItemType.CardGrid:
            return <CardGrid data={item} {...otherProps}/>;
        case LinkedItemType.CardList:
            return <CardList data={item} {...otherProps}/>;
        case LinkedItemType.ContentCard:
            return <ContentCard data={item} {...otherProps}/>;
        case LinkedItemType.ProfileCard:
            return <ProfileCard data={item} {...otherProps}/>;
        case LinkedItemType.CtaLink:
            return <CtaLink data={item} {...otherProps}/>;
        case LinkedItemType.CtaCard:
            return <CtaCard data={item} {...otherProps}/>;
        case LinkedItemType.FacetedCardList:
            return <FacetedCardList data={item} {...otherProps}/>;
        case LinkedItemType.FeaturedCardList:
            return <FeaturedCardList data={item} {...otherProps}/>;
        case LinkedItemType.Form:
            return <Form data={item} {...otherProps}/>;
        case LinkedItemType.ImageCarousel:
            return <ImageCarousel data={item} {...otherProps}/>;
        case LinkedItemType.LogoCarousel:
            return <LogoCarousel data={item} {...otherProps}/>;
        case LinkedItemType.PageBannerThick:
            return <PageBannerThick data={item} {...otherProps}/>;
        case LinkedItemType.PageBannerThin:
            return <PageBannerThin data={item} {...otherProps}/>;
        case LinkedItemType.ProductOverview:
            return <ProductOverview data={item} {...otherProps}/>;
        case LinkedItemType.Quote:
            return <Quote data={item} {...otherProps}/>;
        case LinkedItemType.QuoteList:
            return <QuoteList data={item} {...otherProps}/>;
        case LinkedItemType.RichContent:
            return <RichContent data={item} {...otherProps}/>;
        case LinkedItemType.RichContentStandard:
            return <RichContentStandard data={item} {...otherProps}/>;
        case LinkedItemType.Section:
            return <Section data={item} {...otherProps}/>;
        case LinkedItemType.Custom:
            return <Custom data={item} {...otherProps}/>;
        case LinkedItemType.SplitContent:
            return <SplitContent data={item} {...otherProps}/>;
        case LinkedItemType.StaggeredCardList:
            return <StaggeredCardList data={item} {...otherProps}/>;
        case LinkedItemType.StatCard:
            return <StatCard data={item} {...otherProps}/>;
        case LinkedItemType.StaticText:
            return <StaticText data={item} {...otherProps}/>;
        case LinkedItemType.Stories:
            return <Stories data={item} {...otherProps}/>;
        case LinkedItemType.TabbedContentArea:
            return <TabbedContentArea data={item} {...otherProps}/>;
        case LinkedItemType.TestimonialCarousel:
            return <TestimonialCarousel data={item} {...otherProps}/>;
        case LinkedItemType.Timeline:
            return <Timeline data={item} {...otherProps}/>;
        case LinkedItemType.TextWithImage:
            return <TextWithImage data={item} {...otherProps}/>;
        case LinkedItemType.Video:
            return <Video data={item} {...otherProps}/>;
        default:
            return null;
    }
};
export default LinkedItem;
export const LinkedItemsFragment = graphql `
  fragment LinkedItemsFragment on KontentItem {
    system {
      codename
      id
      type
    }
    ... on KontentItemArticle {
      ...KontentItemArticleFragment
    }
    ... on KontentItemBasicGrid {
      ...KontentItemBasicGridFragment
    }
    ... on KontentItemCardGrid {
      ...KontentItemCardGridFragment
    }
    ... on KontentItemCardList {
      ...KontentItemCardListFragment
    }
    ... on KontentItemContentCard {
      ...KontentItemContentCardFragment
    }
    ... on KontentItemCtaLink {
      ...KontentItemCtaLinkFragment
    }
    ... on KontentItemCtaCard {
      ...KontentItemCtaCardFragment
    }
    ... on KontentItemCustom {
      ...KontentItemCustomFragment
    }
    ... on KontentItemProfile {
      ...KontentItemStatProfileFragment
    }
    ... on KontentItemFacetedCardList {
      ...KontentItemFacetedCardListFragment
    }
    ... on KontentItemFeaturedCardList {
      ...KontentItemFeaturedCardListFragment
    }
    ... on KontentItemForm {
      ...KontentItemFormFragment
    }
    ... on KontentItemImageCarousel {
      ...KontentItemImageCarouselFragment
    }
    ... on KontentItemLogoCarousel {
      ...KontentItemLogoCarouselFragment
    }
    ... on KontentItemPageBannerThick {
      ...KontentItemPageBannerThickFragment
    }
    ... on KontentItemPageBannerThin {
      ...KontentItemPageBannerThinFragment
    }
    ... on KontentItemProductOverview {
      ...KontentItemProductOverviewFragment
    }
    ... on KontentItemQuote {
      ...KontentItemQuoteFragment
    }
    ... on KontentItemQuoteList {
      ...KontentItemQuoteListFragment
    }
    ... on KontentItemRichContent {
      ...KontentItemRichContentFragment
    }
    ... on KontentItemRichContentStandard {
      ...KontentItemRichContentStandardFragment
    }
    ... on KontentItemSection {
      ...KontentItemSectionFragment
    }
    ... on KontentItemSplitContent_50_50 {
      ...KontentItemSplitContentFragment
    }
    ... on KontentItemStaggeredCardList {
      ...KontentItemStaggeredCardListFragment
    }
    ... on KontentItemStatCard {
      ...KontentItemStatCardFragment
    }
    ... on KontentItemStaticText {
      ...KontentItemStaticTextFragment
    }
    ... on KontentItemStories {
      ...KontentItemStoriesFragment
    }
    ... on KontentItemTabbedContentArea {
      ...KontentItemTabbedContentAreaFragment
    }
    ... on KontentItemTestimonialCarousel {
      ...KontentItemTestimonialCarouselFragment
    }
    ... on KontentItemTextWithImage {
      ...KontentItemTextWithImageFragment
    }
    ... on KontentItemTimeline {
      ...KontentItemTimelineFragment
    }
    ... on KontentItemVideo {
      ...KontentItemVideoFragment
    }
  }
`;
export const CardFragment = graphql `
  fragment CardFragment on KontentItem {
    system {
      codename
      id
      type
    }
    ... on KontentItemArticle {
      ...KontentItemArticleFragment
    }
    ... on KontentItemContentCard {
      ...KontentItemContentCardFragment
    }
    ... on KontentItemStatCard {
      ...KontentItemStatCardFragment
    }
    ... on KontentItemProfile {
      ...KontentItemStatProfileFragment
    }
  }
`;
