import React from 'react';
import SectionWrapper from '@presentation/SectionWrapper';
const RichContentWithComponents = ({ preventAnyLayout, children, }) => {
    if (preventAnyLayout) {
        return <>{children}</>;
    }
    return (<SectionWrapper fullWidth margin={false}>
      {children}
    </SectionWrapper>);
};
export default RichContentWithComponents;
