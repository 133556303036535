import { useLayoutEffect, useRef } from 'react';
function useTinySlider(options) {
    const containerRef = useRef(null);
    const instanceRef = useRef();
    const navRef = useRef(null);
    useLayoutEffect(() => {
        if (!containerRef.current) {
            return;
        }
        const locals = {
            ...options,
            container: containerRef.current,
        };
        if (navRef.current) {
            locals.navContainer = navRef.current;
        }
        // Async load tns to avoid server-render errors.
        import('tiny-slider/src/tiny-slider').then(({ tns }) => {
            instanceRef.current = tns(locals);
        });
        return () => {
            instanceRef.current?.destroy();
        };
    }, []);
    return { containerRef, instanceRef, navRef };
}
export default useTinySlider;
