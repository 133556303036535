import { motion } from 'framer-motion';
import Image from 'gatsby-image';
import React, { useRef } from 'react';
import Slider from 'react-slick';
import useFadeUp from '@hooks/animation/useFadeUp';
import SectionWrapper from '@presentation/SectionWrapper';
const ImageCarousel = ({ items }) => {
    const containerRef = useRef(null);
    const carouselProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (<SectionWrapper fullWidth>
      <div className="site-wide grid-container grid-container--full">
        <div className="grid-column">
          <div ref={containerRef} className="carousel">
            <motion.div className="carousel__wrapper" {...carouselProps}>
              <Slider {...sliderSettings}>
                {items.map((item, index) => (<Image key={index} className="" alt={item.alt} draggable={false} fluid={item.image}/>))}
              </Slider>
            </motion.div>
          </div>
        </div>
      </div>
    </SectionWrapper>);
};
export default ImageCarousel;
