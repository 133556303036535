import React from 'react';
import classNames from 'classnames';
import SectionWrapper from '@presentation/SectionWrapper';
import Link from '@presentation/Link';
import { ButtonVariant } from '@presentation/Button/Button.types';
const CtaLink = ({ eventName, isExternal, text, link, style, alignment, spacing, containerWidth, }) => {
    let variant = null;
    switch (style) {
        case 'white':
            variant = ButtonVariant.WhiteSolid;
            break;
        case 'link_underline_arrow':
            variant = ButtonVariant.LinkUnderlineArrowOrange;
            break;
        default:
            variant = ButtonVariant.OrangeSolid;
    }
    return (<SectionWrapper fullWidth margin={spacing !== 'none'}>
      <div className="site-wide">
        <div className={classNames({
        'grid-container': true,
        'grid-container--indent': true,
        'grid-container--full': !containerWidth || containerWidth === 'full',
        'grid-container--four': containerWidth === 'n4_columns',
    })}>
          <div className={classNames({
        'grid-column': true,
        'centered-content': alignment === 'center',
    })}>
            <Link eventName={eventName} isExternal={isExternal} text={text} to={link} variant={variant}/>
          </div>
        </div>
      </div>
    </SectionWrapper>);
};
export default CtaLink;
