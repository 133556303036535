import classNames from 'classnames';
import { motion } from 'framer-motion';
import Image from 'gatsby-image';
import React, { useRef } from 'react';
import useFade from '@hooks/animation/useFade';
import useFadeUp from '@hooks/animation/useFadeUp';
import formatTitle from '@utils/formatTitle';
import { PageBannerType } from './PageBanner.types';
const PageBanner = ({ backgroundImage, content, foregroundImage, title, type = PageBannerType.Thin, }) => {
    const containerRef = useRef(null);
    const bgImageProps = useFade({ ref: containerRef, delay: 0.1 });
    const titleProps = useFadeUp({ ref: containerRef, delay: 0.2 });
    const contentProps = useFadeUp({ ref: containerRef, delay: 0.3 });
    const fgImageProps = useFadeUp({ ref: containerRef, delay: 0.4 });
    const bannerClassName = classNames({
        'template-banner': true,
        'template-banner--standard': true,
        [`template-banner--${type}`]: true,
        'template-banner--no-inset': !foregroundImage,
        greyscale: true,
    });
    return (<section ref={containerRef} className={bannerClassName}>
      {backgroundImage && (<motion.div className="template-banner__bg" {...bgImageProps}>
          <Image fluid={backgroundImage}/>
        </motion.div>)}

      {(title || content) && (<div className="site-wide grid-container grid-container--full">
          <div className="grid-column">
            <div className="template-banner__content-wrapper">
              {title && (<motion.h1 className="template-banner__title" {...titleProps}>
                  {formatTitle(title)}
                </motion.h1>)}
              {content && (<motion.p className="template-banner__content" {...contentProps}>
                  {content}
                </motion.p>)}
            </div>
          </div>
        </div>)}

      {foregroundImage && (<motion.div className="site-wide grid-container grid-container--full grid-container--indent" {...fgImageProps}>
          <div className="grid-column">
            <div className="template-banner__image">
              <Image fluid={foregroundImage}/>
            </div>
          </div>
        </motion.div>)}
    </section>);
};
export default PageBanner;
