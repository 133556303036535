import React from 'react';
import { Grid, GridColumn } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import { ButtonVariant } from '@presentation/Button/Button.types';
import Link from '@presentation/Link';
import SectionWrapper from '@presentation/SectionWrapper';
import formatTitle from '@utils/formatTitle';
import { motion } from 'framer-motion';
const CardList = ({ children, ctaEventName, ctaText, ctaTo, ctaStyle, ctaIsExternal, ctaPosition, title, content, }) => {
    let ctaVariant;
    switch (ctaStyle) {
        case 'white_with_black_border':
            ctaVariant = ButtonVariant.BlackBorder;
            break;
        default:
            ctaVariant = ButtonVariant.OrangeSolid;
    }
    return (<SectionWrapper>
      {(!ctaPosition || ctaPosition === 'top') && (<div className="indent-title">
          <h2 className="indent-title__title">{formatTitle(title)}</h2>
          {ctaText && ctaTo && (<div className="indent-title__btn-holder">
              <Link eventName={ctaEventName} isExternal={ctaIsExternal} small text={ctaText} to={ctaTo} variant={ctaVariant}/>
            </div>)}
        </div>)}
      {ctaPosition === 'bottom' && (<div className="centered-content">
          <h2>{formatTitle(title)}</h2>
        </div>)}
      {content && (<Grid layout={GridLayout.FULL_INDENT}>
          <GridColumn>
            <motion.p className="section__content centered-content" dangerouslySetInnerHTML={{ __html: content }}/>
          </GridColumn>
        </Grid>)}
      <Grid layout={GridLayout.THIRD}>{renderChildren()}</Grid>
      {ctaText && ctaTo && ctaPosition === 'bottom' && (<div className="site-wide grid-container grid-container--full grid-container--indent vert-padding-50">
          <div className="grid-column centered-content">
            <Link eventName={ctaEventName} isExternal={ctaIsExternal} text={ctaText} to={ctaTo} variant={ctaVariant}/>
          </div>
        </div>)}
    </SectionWrapper>);
    function renderChildren() {
        if (!children || !children.length) {
            return null;
        }
        return children.map(child => (<GridColumn key={child.key || ''}>
        {React.cloneElement(child, { featured: false })}
      </GridColumn>));
    }
};
export default CardList;
