import { motion } from 'framer-motion';
import Image from 'gatsby-image';
import React, { useLayoutEffect, useRef } from 'react';
import useFadeUp from '@hooks/animation/useFadeUp';
import useTinySlider from '@hooks/useTinySlider';
import SectionWrapper from '@presentation/SectionWrapper';
import formatTitle from '@utils/formatTitle';
import IconQuote from '@icons/IconQuote';
const TestimonialCarousel = ({ items, }) => {
    const { containerRef, instanceRef, navRef } = useTinySlider({
        autoplayTimeout: 5000,
        nav: true,
        controls: false,
        mode: 'gallery',
        autoplay: true,
        autoplayButtonOutput: false,
        speed: 1500,
    });
    const observer = useRef();
    useLayoutEffect(() => {
        if (!containerRef.current || !navRef.current) {
            return;
        }
        const config = {
            rootMargin: '-30px 0px',
            threshold: [0, 0.5],
        };
        observer.current = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio >= 0.5) {
                    instanceRef.current?.play();
                    navRef.current?.classList.add('is-playing');
                }
                else {
                    instanceRef.current?.pause();
                    navRef.current?.classList.remove('is-playing');
                }
            });
        }, config);
        observer.current.observe(containerRef.current);
        return () => observer.current?.disconnect();
    });
    const containerProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    return (<SectionWrapper className="testimonial-carousel">
      <motion.div ref={containerRef} className="grid-container grid-container--full grid-container--indent" {...containerProps}>
        {items.map(item => (<div key={item.id} className="grid-column">
            <div className="card-testimonial">
              <div className="card-testimonial__inner">
                {item.image && (<div className="card-testimonial__media-holder">
                    <div className="card-testimonial__media-inner">
                      <img className="card-testimonial__media-element" alt="" 
    // loading="lazy"
    src={item.image?.src} srcSet={item.image?.srcSet}/>
                    </div>
                  </div>)}
                <div className="card-testimonial__content-holder">
                  <IconQuote />
                  <h2 className="card-testimonial__testimonial-lead">
                    {formatTitle(item.title)}
                  </h2>
                  <p className="card-testimonial__testimonial-intro">
                    {item.content}
                  </p>
                  <p className="card-testimonial__testimonial-author">
                    {item.citation}
                  </p>
                  {item.logo && (<div className="card-testimonial__logo">
                      <Image className="card-testimonial__logo-element" fixed={item.logo}/>
                    </div>)}
                </div>
              </div>
            </div>
          </div>))}
      </motion.div>

      <div className="nav-testimonial">
        <div className="nav-testimonial__inner">
          <div className="nav-testimonial__media-holder"></div>

          <div className="nav-testimonial__content-holder">
            <div ref={navRef} className="testimonial-carousel-nav">
              {items.map(item => (<div key={item.id} className="testimonial-carousel-nav__item">
                  <div className="testimonial-carousel-nav__item-image">
                    <img className="card-testimonial__media-element" alt="" 
    // loading="lazy"
    src={item.thumbnail?.src} srcSet={item.thumbnail?.srcSet}/>
                  </div>

                  <div className="testimonial-carousel-nav__item-circles">
                    <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92">
                      <g id="Ellipse_189" data-name="Ellipse 189" fill="none" stroke="#3d5dc0" strokeWidth="5">
                        <circle cx="46" cy="46" r="46" stroke="none"></circle>
                        <circle cx="46" cy="46" r="43.5" fill="none"></circle>
                      </g>
                    </svg>
                  </div>
                </div>))}
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>);
};
export default TestimonialCarousel;
