import { graphql } from 'gatsby';
import React from 'react';
import ContentCardLayout from '@presentation/ContentCard';
import { ContentCardAlignment } from '@presentation/ContentCard/ContentCard.types';
const ContentCard = ({ index = 0, data }) => {
    return (<ContentCardLayout align={index % 2 === 0 ? ContentCardAlignment.Left : ContentCardAlignment.Right} content={data.elements?.content?.value || ''} title={data.elements?.title?.value || ''}/>);
};
export default ContentCard;
export const KontentItemContentCardFragment = graphql `
  fragment KontentItemContentCardFragment on KontentItemContentCard {
    elements {
      content {
        value
      }
      title {
        value
      }
    }
  }
`;
