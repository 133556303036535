import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Grid, GridColumn } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import SectionWrapper from '@presentation/SectionWrapper';
import formatTitle from '@utils/formatTitle';
import Link from '@presentation/Link';
import { ButtonVariant } from '@presentation/Button/Button.types';
import Quote from '@presentation/Quote';
import RichContentWithComponents from '@connected/RichContentWithComponents';
const StoryItem = ({ item }) => {
    const className = classNames('stories__item', {
        ['stories__item--money']: item.styleCodename === 'OurStoryMoney',
        ['stories__item--saas']: item.styleCodename === 'OurStorySaaS',
        ['stories__item--gone']: item.styleCodename === 'OurStoryGone',
    });
    const content = item.text?.resolvedData?.html ?? '';
    const images = item.text?.images ?? [];
    const links = item.text?.links ?? [];
    return (<div className={className}>
      <div className="stories__anchor" id={item.codename}/>
      <div className="stories__item-outer">
        <div className="stories__item-inner">
          <h2 className="stories__item-title">{formatTitle(item.title)}</h2>
          <div className="stories__item-content">
            <RichContentWithComponents content={content} images={images} linkedItems={[]} links={links} preventAnyLayout={true}/>
          </div>
          {item.ctaText && item.ctaTo && (<div className="custom-section__cta">
              <Link eventName={item.ctaEventName} isExternal={item.ctaIsExternal} text={item.ctaText} to={item.ctaTo} variant={ButtonVariant.OrangeSolid}/>
            </div>)}
          {item.quotes &&
        item.quotes.map((quote, index) => (<Quote key={index} text={quote.text} name={quote.name} photo={quote.photo} position={quote.position} company={quote.company} codename={quote.codename}/>))}
        </div>
      </div>
    </div>);
};
const StoryTableOfContentsItem = ({ item, tocActiveAnchor, setTocActiveAnchor, setActiveAnchor, }) => {
    return (<li className="table-of-contents__item">
      <span className={classNames({
        'table-of-contents__link': true,
        'table-of-contents__link--active': tocActiveAnchor === item.codename,
    })} onClick={() => {
        if (!setActiveAnchor || !setTocActiveAnchor)
            return;
        setActiveAnchor(item.codename);
        setTocActiveAnchor(item.codename);
    }}>
        {item.title}
      </span>
    </li>);
};
const Stories = ({ items }) => {
    const [tocFixed, setTocFixed] = useState(false);
    const [tocBottom, setTocBottom] = useState(false);
    const [activeAnchor, setActiveAnchor] = useState('');
    const [tocActiveAnchor, setTocActiveAnchor] = useState('');
    useEffect(() => {
        const tocHandleScroll = () => {
            const toc = document.querySelector('.table-of-contents');
            const tocList = toc?.querySelector('.table-of-contents__list');
            const header = document.querySelector('.header');
            const tocOffset = (toc?.getBoundingClientRect().top || 0) - (header?.offsetHeight || 0);
            if (tocOffset < 0) {
                setTocFixed(true);
            }
            else {
                setTocFixed(false);
            }
            const gridColumn = toc.closest('.grid-column');
            const gridColumnOffset = (gridColumn?.getBoundingClientRect().top || 0) +
                (gridColumn?.offsetHeight || 0) -
                (header?.offsetHeight || 0) -
                (tocList?.offsetHeight || 0);
            if (gridColumnOffset < 0) {
                setTocBottom(true);
            }
            else {
                setTocBottom(false);
            }
            const headings = document.querySelectorAll('.stories__item-title');
            const headingsOffset = Array.from(headings).map(heading => heading?.getBoundingClientRect().top || 0);
            let foundAffixItem = false;
            for (let i = 0; i < headingsOffset.length; i++) {
                if (headingsOffset[i] > 0 && !foundAffixItem) {
                    const affixParent = headings[i]
                        ? headings[i].closest('.stories__item')
                        : null;
                    const affixId = affixParent
                        ? affixParent.querySelector('.stories__anchor')?.id
                        : '';
                    setTocActiveAnchor(affixId || '');
                    foundAffixItem = true;
                }
            }
            return;
        };
        window.addEventListener('scroll', tocHandleScroll, { passive: true });
        return () => window.removeEventListener('scroll', tocHandleScroll);
    }, []);
    useEffect(() => {
        setTimeout(() => {
            if (!activeAnchor)
                return;
            // window.history.replaceState({}, '', `#${activeAnchor}`);
            const elem = document.querySelector(`#${activeAnchor}`);
            if (!elem)
                return;
            elem.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        }, 100);
    }, [activeAnchor]);
    return (<SectionWrapper padding={false} margin={false}>
      <Grid layout={GridLayout.TWO_SIX}>
        <GridColumn className={classNames('table-of-contents__timeline', {
        'grid-column--content-to-bottom': tocBottom,
    })}>
          <div className="table-of-contents">
            <ul className={classNames({
        'table-of-contents__list': true,
        'table-of-contents__list--fixed': tocFixed,
    })}>
              {items.map(item => (<StoryTableOfContentsItem key={item.id} item={item} tocActiveAnchor={tocActiveAnchor} setTocActiveAnchor={setTocActiveAnchor} setActiveAnchor={setActiveAnchor}/>))}
            </ul>
          </div>
        </GridColumn>
        <GridColumn>
          <div className="stories">
            <div className="stories__items">
              {items.map(item => (<StoryItem key={item.id} item={item}/>))}
            </div>
          </div>
        </GridColumn>
      </Grid>
    </SectionWrapper>);
};
export default Stories;
