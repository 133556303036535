import { graphql } from 'gatsby';
import React from 'react';
import ProductOverviewLayout from '@presentation/ProductOverview';
import { ProductType, } from '@presentation/ProductOverview/ProductOverview.types';
import getCtaModel from '@utils/getCtaModel';
const ProductOverview = ({ data }) => {
    const productsData = (data.elements?.products?.linked_items ||
        []);
    const productItems = productsData && productsData.map(createProductModel);
    const fullWidth = data.elements?.full_width?.value?.[0]?.codename == 'yes';
    const title = data.elements?.title?.value;
    const content = data.elements?.content?.value;
    const ctaEventName = data.elements?.cta__cta_event_name?.value;
    const [ctaText, ctaTo, ctaIsExternal] = getCtaModel(data.elements?.cta__cta_text?.value, data.elements?.cta__cta_link__external?.value, data.elements?.cta__cta_link__internal?.linked_items?.[0]);
    return (<ProductOverviewLayout title={title} content={content} ctaText={ctaText} ctaTo={ctaTo} ctaIsExternal={ctaIsExternal} ctaEventName={ctaEventName} fullWidth={fullWidth} products={productItems}/>);
};
function createProductModel(data) {
    const [ctaText, ctaTo, ctaIsExternal] = getCtaModel(data.elements?.cta__cta_text?.value, data.elements?.cta__cta_link__external?.value, data.elements?.cta__cta_link__internal?.linked_items?.[0]);
    return {
        id: data.id,
        title: data.elements?.title?.value,
        subtitle: data.elements?.subtitle?.value,
        note: data.elements?.note?.value,
        logo: data.elements?.logo?.value?.[0]?.url,
        content: data.elements?.content?.value,
        features: data.elements?.features?.value,
        type: getProductType(data),
        ctaEventName: data.elements?.cta__cta_event_name?.value,
        ctaIsExternal: ctaIsExternal,
        ctaText: ctaText,
        ctaTo: ctaTo,
    };
}
/**
 * Get product type.
 * @param item
 */
function getProductType(item) {
    const type = item.elements?.type?.value?.[0]?.codename;
    switch (type) {
        case 'kontent':
            return ProductType.Kontent;
        case 'xperience':
            return ProductType.Xperience;
        default:
            return ProductType.Kontent;
    }
}
export default ProductOverview;
export const KontentItemProductOverviewFragment = graphql `
  fragment KontentItemProductOverviewFragment on KontentItemProductOverview {
    elements {
      full_width {
        value {
          codename
        }
      }
      title {
        value
      }
      content {
        value
      }
      cta__cta_event_name {
        value
      }
      cta__cta_link__external {
        value
      }
      cta__cta_link__internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      cta__cta_text {
        value
      }
      products {
        linked_items {
          ... on KontentItemProduct {
            id
            elements {
              content {
                value
              }
              cta__cta_event_name {
                value
              }
              cta__cta_link__external {
                value
              }
              cta__cta_link__internal {
                linked_items {
                  ... on NodeWithUrl {
                    url
                  }
                }
              }
              cta__cta_text {
                value
              }
              features {
                value
              }
              logo {
                value {
                  url
                }
              }
              title {
                value
              }
              subtitle {
                value
              }
              note {
                value
              }
              type {
                value {
                  codename
                }
              }
            }
          }
        }
      }
    }
  }
`;
