import React from 'react';
import ImageCarouselLayout from '@presentation/ImageCarousel';
import { graphql } from 'gatsby';
const ImageCarousel = ({ data }) => {
    const assets = data.elements?.images?.value || [];
    const items = assets.map((asset) => ({
        alt: asset.description,
        id: asset.url || '',
        image: asset.fluid,
    }));
    return <ImageCarouselLayout items={items}/>;
};
export default ImageCarousel;
export const KontentItemImageCarouselFragment = graphql `
  fragment KontentItemImageCarouselFragment on KontentItemImageCarousel {
    elements {
      images {
        value {
          fluid(maxWidth: 1000) {
            ...KontentAssetFluid_withWebp
          }
          url
        }
      }
    }
  }
`;
