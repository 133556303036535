import classNames from 'classnames';
import Image from 'gatsby-image';
import React, { Fragment, useRef } from 'react';
import useAoS from '@hooks/useAoS';
import useFadeUp from '@hooks/animation/useFadeUp';
import IconRing from '@icons/IconRing';
import MotionLink from '@presentation/MotionLink';
import Tag from '@presentation/Tag';
import Author from '@presentation/Author';
import formatDate from '@utils/formatDate';
import { CardType, CardDepartment, CardLayout } from './Card.types';
const Card = ({ author, authorAvatar, date, endDate, image, layout, location, logo, readTime, tag, title, type = CardType.Unknown, department = CardDepartment.Unknown, url, }) => {
    const elementRef = useRef(null);
    const animProps = useFadeUp({ ref: elementRef, delay: 0.1 });
    useAoS(elementRef);
    const cardClassName = getCardClassName(type, layout);
    const isEvent = type == CardType.Event;
    return (<MotionLink ref={elementRef} className={cardClassName} to={url} {...animProps}>
      <div className="card__inner ">
        {image && (<div className="card__media">
            <div className="card__media-holder">
              <Image className="card__media-element" alt={title} fluid={image}/>
            </div>
          </div>)}
        <div className="card__content-external">
          {tag && <Tag tag={tag} type={department}/>}
          <div className="card__content-internal">{renderContent(type)}</div>
          {isEvent && layout === CardLayout.FeaturedLandscape && <IconRing />}
        </div>
      </div>
      {!isEvent && layout === CardLayout.FeaturedLandscape && (<span className="circle-animation">
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
        </span>)}
    </MotionLink>);
    function renderContent(type) {
        switch (type) {
            case CardType.BlogPost:
            case CardType.News:
                return (<Fragment>
            <h4 className="card__title">{title}</h4>
            {author && <Author name={author} avatar={authorAvatar}/>}
            {(date || readTime) && (<p className="card__blog-detail">
                {date && (<Fragment>
                    {formatDate(date, {
                    month: 'short',
                })}
                    &nbsp;
                    {formatDate(date, {
                    day: 'numeric',
                })}
                    {endDate &&
                    -formatDate(endDate, {
                        day: 'numeric',
                    })}
                    &#44;&nbsp;
                    {formatDate(date, {
                    year: 'numeric',
                })}
                  </Fragment>)}
                {date && readTime && <span className="bullet">&#8226;</span>}
                {readTime}
              </p>)}
          </Fragment>);
            case CardType.Event:
                return (<Fragment>
            <h4 className="card__title">{title}</h4>
            {author && <Author name={author} avatar={authorAvatar}/>}
            {location && <p className="card__intro">{location}</p>}
            {date && (<div className="card__event-date-area">
                <div className="card__date-block">
                  <span className="card__date-block-month">
                    {formatDate(date, {
                    month: 'short',
                })}
                  </span>
                  <span className="card__date-block-day">
                    {formatDate(date, {
                    day: 'numeric',
                })}
                  </span>
                  <span className="card__date-block-year">
                    {formatDate(date, {
                    year: 'numeric',
                })}
                  </span>
                </div>
                <p className="card__event-date">
                  <span>
                    {formatDate(date, {
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                })}
                  </span>
                  {endDate && (<>
                      <br />
                      <span>
                        &mdash;&nbsp;
                        {formatDate(endDate, {
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                })}
                      </span>
                    </>)}
                </p>
              </div>)}
          </Fragment>);
            case CardType.Report:
                return (<Fragment>
            {logo && (<Image className="card__report-logo" alt={title} fluid={logo}/>)}
            <h4 className="card__title">{title}</h4>
            {author && <Author name={author} avatar={authorAvatar}/>}
          </Fragment>);
            default:
                return (<Fragment>
            <h4 className="card__title">{title}</h4>
            {author && <Author name={author} avatar={authorAvatar}/>}
          </Fragment>);
        }
    }
};
/**
 * Get the card class names for the specified article type.
 * @param type The article type
 * @param featured The featured state
 */
function getCardClassName(type, layout) {
    let typeClassName = '';
    switch (type) {
        case CardType.BlogPost:
            typeClassName = 'card--blog';
            break;
        case CardType.Event:
            typeClassName = 'card--event';
            break;
        case CardType.Report:
            typeClassName = 'card--reports card--small-ver';
            break;
        case CardType.Resource:
            typeClassName = 'card--reports card--small-ver';
            break;
        default:
            typeClassName = 'card--unknown';
            break;
    }
    if (type === CardType.Event) {
        return classNames({
            card: true,
            [typeClassName]: true,
            'card--featured': layout == CardLayout.Featured,
            'card--landscape': layout == CardLayout.Landscape,
            'card--events-featured': layout == CardLayout.FeaturedLandscape,
            'no-transition': true,
        });
    }
    else {
        return classNames({
            card: true,
            [typeClassName]: true,
            'card--featured': layout == CardLayout.Featured || layout == CardLayout.FeaturedLandscape,
            'card--landscape': layout == CardLayout.Landscape ||
                layout == CardLayout.FeaturedLandscape,
            'no-transition': true,
        });
    }
}
export default Card;
