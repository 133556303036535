import { motion } from 'framer-motion';
import React, { useRef } from 'react';
import useFadeUp from '@hooks/animation/useFadeUp';
import Image from 'gatsby-image';
import { ButtonVariant } from '@presentation/Button/Button.types';
import Link from '@presentation/Link';
import formatTitle from '@utils/formatTitle';
import classNames from 'classnames';
const Custom = ({ title, content, ctaEventName, ctaIsExternal, ctaText, ctaTo, image1, image2, codename, }) => {
    const containerRef = useRef(null);
    const titleProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    const contentProps = useFadeUp({ ref: containerRef, delay: 0.2 });
    const ctaProps = useFadeUp({ ref: containerRef, delay: 0.3 });
    const className = classNames('custom-section', {
        ['custom-section--cmsdxp']: codename === 'ChooseRightProductCMSDXP',
        ['custom-section--bestof']: codename === 'ChooseRightProductBestOf',
        ['custom-section--headlessdxp']: codename === 'ChooseRightProductHeadlessDXP',
        ['custom-section--discuss']: codename === 'ChooseRightProductDiscuss',
        ['custom-section--strongteam']: codename === 'PeopleStrongTeam',
        ['custom-section--thisisus']: codename === 'PeopleThisIsUs',
        ['custom-section--contacthero']: codename === 'ContactHero',
        ['custom-section--strongertogether']: codename === 'HomeStrongerTogether',
        ['custom-section--specialhero']: codename === 'SpecialHero',
        ['custom-section--helpyousucceed']: codename === 'SpecialHelpYouSucceed',
        ['custom-section--transparency']: codename === 'SpecialTransparency',
        ['custom-section--community']: codename === 'SpecialCommunity',
        ['custom-section--balance']: codename === 'SpecialBalance',
        ['custom-section--trees']: codename === 'SpecialTrees',
        ['custom-section--ourstoryhero']: codename === 'OurStroryHero',
        ['custom-section--dxphero']: codename === 'DXPHero',
        ['custom-section--headlesshero']: codename === 'HeadlessHero',
    });
    let CustomTag;
    switch (codename) {
        case 'PeopleThisIsUs':
        case 'ContactHero':
        case 'SpecialHero':
        case 'OurStroryHero':
        case 'DXPHero':
        case 'HeadlessHero':
            CustomTag = motion.h1;
            break;
        default:
            CustomTag = motion.h2;
    }
    return (<section className={className} ref={containerRef} id={codename}>
      <div className="custom-section__container">
        {image1 && (<div className="custom-section__image">
            <Image className="custom-section__media" alt={title} loading="lazy" fluid={image1} imgStyle={{ objectFit: 'contain' }}/>
          </div>)}
        <div className="custom-section__text">
          <CustomTag className="custom-section__heading" {...titleProps}>
            {formatTitle(title)}
          </CustomTag>
          <motion.div className="custom-section__content" {...contentProps} dangerouslySetInnerHTML={{ __html: content || '' }}/>
          {ctaText && ctaTo && (<motion.div className="custom-section__cta" {...ctaProps}>
              <Link eventName={ctaEventName} isExternal={ctaIsExternal} text={ctaText} to={ctaTo} variant={ButtonVariant.OrangeSolid}/>
            </motion.div>)}
        </div>
        {image2 && (<div className="custom-section__image">
            <Image className="custom-section__media" alt={title} loading="lazy" fluid={image2} imgStyle={{ objectFit: 'contain' }}/>
          </div>)}
      </div>
    </section>);
};
export default Custom;
