import React from 'react';
const Select = ({ inputProps, optionLabel, options, type, value, onChange, }) => {
    return (<select {...inputProps} className="form-input form-input--select" value={value} onChange={e => onChange(e, type)}>
      <option value="">{optionLabel}</option>
      {options &&
        options.map(option => (<option key={`${inputProps.id}-${option.value}`} value={option.value}>
            {option.label}
          </option>))}
    </select>);
};
export default Select;
