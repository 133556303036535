import classNames from 'classnames';
import { motion } from 'framer-motion';
import Image from 'gatsby-image';
import React, { useRef } from 'react';
import useFadeUp from '@hooks/animation/useFadeUp';
import IconRose from '@icons/IconRose';
import { ButtonVariant } from '@presentation/Button/Button.types';
import Link from '@presentation/Link';
import formatTitle from '@utils/formatTitle';
import { CtaCardTheme } from './CtaCard.types';
const CtaCard = ({ content, cta1EventName, cta1IsExternal, cta1Text, cta1To, cta2EventName, cta2IsExternal, cta2Text, cta2To, image, subtitle, footer, theme = CtaCardTheme.None, title, alignment, }) => {
    const containerRef = useRef(null);
    const animProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    const hasImage = !!image;
    const hasSubtitle = !!(subtitle && subtitle.length);
    const hasTheme = theme !== CtaCardTheme.None;
    const hasTwoCtas = cta1To && cta2To;
    const containerClassName = classNames({
        cta: true,
        [`cta--${theme}`]: !hasImage && hasTheme,
        'cta--image': hasImage,
        'cta--2-btn': hasTwoCtas,
        'cta--has-subtitle': hasSubtitle,
        [`cta--${alignment}`]: !!alignment,
    });
    title = title.trim();
    const titleHasInterpunction = title.endsWith('?') || title.endsWith('.') || title.endsWith('!');
    const titleClassName = classNames({
        /* eslint-disable @typescript-eslint/camelcase */
        cta__title: true,
        'remove-dot': titleHasInterpunction,
        'orange-dot': !titleHasInterpunction,
    });
    return (<motion.div ref={containerRef} className={containerClassName} {...animProps}>
      {image ? (<Image className="cta__image" alt="" fluid={image}/>) : (<IconRose />)}

      <div className="cta__inner cta__grid">
        <div className="cta__left">
          {title && <h2 className={titleClassName}>{formatTitle(title)}</h2>}
          {subtitle && (<h3 className="cta__subtitle">{formatTitle(subtitle)}</h3>)}
          {content && <p className="cta__intro">{content}</p>}
        </div>
        <div className="cta__right">
          {cta1Text && cta1To && (<Link eventName={cta1EventName} isExternal={cta1IsExternal} text={cta1Text} to={cta1To} variant={hasImage ? ButtonVariant.OrangeSolid : ButtonVariant.WhiteSolid}/>)}
          {cta2Text && cta2To && (<>
              <span className="cta__btn-break">or</span>
              <Link eventName={cta2EventName} isExternal={cta2IsExternal} text={cta2Text} to={cta2To} variant={hasImage
        ? ButtonVariant.OrangeBorder
        : ButtonVariant.WhiteBorder}/>
            </>)}
        </div>
        {footer && (<div className="cta__footer" dangerouslySetInnerHTML={{ __html: footer }}></div>)}
      </div>
    </motion.div>);
};
export default CtaCard;
