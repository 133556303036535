/**
 * Resolve the relative URL for the specified node type and slug.
 * @param {String} type
 * @param {String} slug
 */
export default function resolveUrl(type, slug) {
    switch (type) {
        case 'article':
            return `/articles/${slug}`;
        case 'content':
            return `/${slug}`;
        case 'home':
            return `/`;
        case 'profile':
            return `/profiles/${slug}`;
        default:
            return '';
    }
}
