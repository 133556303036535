import { motion } from 'framer-motion';
import React, { useRef } from 'react';
import useFadeUp from '@hooks/animation/useFadeUp';
import { ButtonVariant } from '@presentation/Button/Button.types';
import Link from '@presentation/Link';
import formatTitle from '@utils/formatTitle';
const Custom = ({ title, content, ctaEventName, ctaIsExternal, ctaText, ctaTo, image1, }) => {
    const containerRef = useRef(null);
    const titleProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    const contentProps = useFadeUp({ ref: containerRef, delay: 0.2 });
    const ctaProps = useFadeUp({ ref: containerRef, delay: 0.3 });
    const bgImageStyle = {
        backgroundImage: `url('${image1?.src}')`,
    };
    return (<div className="custom-hero" style={bgImageStyle} ref={containerRef}>
      <div className="custom-hero__container">
        <motion.h1 className="custom-hero__heading" {...titleProps}>
          {formatTitle(title)}
        </motion.h1>
        <motion.div className="custom-hero__content" {...contentProps} dangerouslySetInnerHTML={{ __html: content || '' }}/>
        {ctaText && ctaTo && (<motion.div className="custom-hero__cta" {...ctaProps}>
            <Link eventName={ctaEventName} isExternal={ctaIsExternal} text={ctaText} to={ctaTo} variant={ButtonVariant.WhiteSolid}/>
          </motion.div>)}
      </div>
    </div>);
};
export default Custom;
