import { useEffect, useState, useRef } from 'react';
export default function useInView(ref) {
    const [inView, setInView] = useState(false);
    const observerRef = useRef();
    useEffect(() => {
        if (!ref.current) {
            console.warn(`useInView was provided with a null ref.`);
            return;
        }
        observerRef.current = new IntersectionObserver(entries => {
            setInView(entries?.[0]?.isIntersecting ?? false);
        }, {
            root: null,
            rootMargin: '-20%',
        });
        observerRef.current.observe(ref.current);
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    });
    return inView;
}
