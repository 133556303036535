import useInViewAnimation from '@hooks/animation/useInViewAnimation';
export default function useFade({ delay, once, ref, }) {
    return useInViewAnimation({
        once,
        ref,
        variants: {
            visible: { opacity: 1, transition: { delay } },
            hidden: { opacity: 0, transition: { delay } },
        },
    });
}
