import { graphql } from 'gatsby';
import React from 'react';
import FormComponent from '@presentation/FormComponent';
import { FormProvider } from '@presentation/Form/FormProvider';
const Form = ({ data }) => {
    const targetSite = data.elements?.form_target_site?.value?.[0]?.codename || 'xperience';
    const formCodename = getFormCodename(data);
    const kontentPardotUrl = data.elements?.pardot_url_kontent?.value || null;
    return (<FormProvider formName={formCodename} additionalFormState={{
        PageUrl: typeof window !== 'undefined' ? window.location.pathname : '',
    }}>
      <FormComponent targetSite={targetSite} kontentPardotUrl={kontentPardotUrl} thankYouText={data.elements?.thank_you_text?.value || ''} formTitle={data.elements?.form_title?.value} submitText={data.elements?.submit_text?.value} styleCodename={data.elements?.codename?.value}/>
    </FormProvider>);
};
function getFormCodename(data) {
    return (data.elements?.form_codename_xperience?.value || 'ResourceDownloadForm');
}
export default Form;
export const KontentItemFormFragment = graphql `
  fragment KontentItemFormFragment on KontentItemForm {
    elements {
      form_title {
        value
      }
      form_target_site {
        value {
          codename
        }
      }
      form_codename_xperience {
        value
      }
      pardot_url_kontent {
        value
      }
      submit_text {
        value
      }
      thank_you_text {
        value
      }
      codename {
        value
      }
    }
  }
`;
