import useInViewAnimation from '@hooks/animation/useInViewAnimation';
export default function useFadeLeft({ delay, once, ref, }) {
    return useInViewAnimation({
        once,
        ref,
        variants: {
            visible: { opacity: 1, x: 0, transition: { delay } },
            hidden: { opacity: 0, x: 100, transition: { delay } },
        },
    });
}
