import React from 'react';
import IconGeneric from '@icons/IconGeneric';
const TabbedNavigation = ({ groups, activeId, onChange, }) => (<nav className="tabbed-section__nav tabbed-section__nav--features js-tabbed-nav">
    <ul className="tabbed-section__nav-list">
      {groups.map(group => (<li key={group.id} className="tabbed-section__nav-list-item">
          <button className={`btn btn--clear btn--small ${activeId === group.id ? 'is-active' : ''}`} data-tracking-code={group.eventName} onClick={event => {
    event.preventDefault();
    onChange(group.id);
}} type="button">
            {group.icon && (<>
                <IconGeneric iconName={group.icon}/>
                &nbsp;
              </>)}
            {group.name}
          </button>
        </li>))}
    </ul>
  </nav>);
export default TabbedNavigation;
