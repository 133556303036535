import { graphql } from 'gatsby';
import React from 'react';
import PageBanner from '@presentation/PageBanner';
import { PageBannerType } from '@presentation/PageBanner/PageBanner.types';
import getCtaModel from '@utils/getCtaModel';
const PageBannerThick = ({ data }) => {
    const [ctaText, ctaTo, ctaIsExternal] = getCtaModel(data.elements?.cta__cta_text?.value, data.elements?.cta__cta_link__external?.value, data.elements?.cta__cta_link__internal?.linked_items?.[0]);
    return (<PageBanner backgroundImage={data.elements?.background_image?.value?.[0]?.fluid} content={data.elements?.content?.value} ctaEventName={data.elements?.cta__cta_event_name?.value} ctaIsExternal={ctaIsExternal} ctaText={ctaText} ctaTo={ctaTo} foregroundImage={data.elements?.foreground_image?.value?.[0]?.fluid} title={data.elements?.title?.value} type={PageBannerType.Thick}/>);
};
export default PageBannerThick;
export const KontentItemPageBannerThickFragment = graphql `
  fragment KontentItemPageBannerThickFragment on KontentItemPageBannerThick {
    elements {
      background_image {
        value {
          fluid(maxWidth: 1920) {
            ...KontentAssetFluid_withWebp
          }
        }
      }
      content {
        value
      }
      cta__cta_event_name {
        value
      }
      cta__cta_link__external {
        value
      }
      cta__cta_link__internal {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      cta__cta_text {
        value
      }
      foreground_image {
        value {
          fluid(maxWidth: 1600) {
            ...KontentAssetFluid_withWebp
          }
        }
      }
      title {
        value
      }
    }
  }
`;
