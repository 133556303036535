import React, { Fragment, useState } from 'react';
import { FormFieldType } from '../Form.types';
const RadioButton = ({ inputProps, options, type, value, onChange, }) => {
    const [selectedOption, setSelectedOption] = useState('');
    const fieldName = inputProps.name;
    return (<Fragment>
      {options &&
        options.map(option => (<div key={`${inputProps.id}-${option.value}`} className={`form-input-container form-input-container--clickable${selectedOption === option.value
            ? ' form-input-container--checked'
            : ''}`}>
            <input id={`${inputProps.id}-${option.value}`} checked={value === option.value} className="form-clickable" name={inputProps.id} type="radio" value={option.value} onChange={e => {
            setSelectedOption(e.target.value);
            const fakeEvent = {};
            fakeEvent.target = {};
            fakeEvent.target.name = fieldName || '';
            fakeEvent.target.value = e.target.value;
            onChange(fakeEvent, FormFieldType.RadioButton);
        }}/>

            <label className="form-label" htmlFor={`${inputProps.id}-${option.value}`}>
              {option.label}
            </label>
          </div>))}
    </Fragment>);
};
export default RadioButton;
