import React from 'react';
import SectionWrapper from '@presentation/SectionWrapper';
import Link from '@presentation/Link';
import { ButtonVariant } from '@presentation/Button/Button.types';
import { Grid, GridColumn } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import formatTitle from '@utils/formatTitle';
const StaggeredCardList = ({ children, content, ctaEventName, ctaIsExternal, ctaText, ctaTo, title, }) => {
    return (<SectionWrapper>
      <div className="reports-listing">
        <div className="grid-container grid-container--half grid-container--indent">
          <div className="grid-column reports-listing__intro">
            {title && <h2>{formatTitle(title)}</h2>}
            {content && <p>{content}</p>}
          </div>

          {ctaText && ctaTo && (<div className="grid-column reports-listing__link">
              <Link eventName={ctaEventName} isExternal={ctaIsExternal} small text={ctaText} to={ctaTo} variant={ButtonVariant.OrangeSolid}/>
            </div>)}
        </div>

        <Grid className="reports-listing__stacked" layout={GridLayout.HALF_INDENT}>
          {renderChildren()}
        </Grid>
      </div>
    </SectionWrapper>);
    function renderChildren() {
        if (!children || !children.length) {
            return null;
        }
        const leftColumn = [];
        const rightColumn = [];
        children.forEach((child, index) => {
            if (index % 2 === 0) {
                rightColumn.push(child);
            }
            else {
                leftColumn.push(child);
            }
        });
        return (<>
        <GridColumn>{leftColumn}</GridColumn>
        <GridColumn>{rightColumn}</GridColumn>
      </>);
    }
};
export default StaggeredCardList;
