import React, { useState } from 'react';
import { useCountUp } from 'react-countup';
import { useIntersectionObserver } from '@netc/hooks';
const StatValue = ({ value }) => {
    const [hasStarted, setHasStarted] = useState(false);
    const { countUp, start } = useCountUp({
        end: value,
    });
    const { elementRef } = useIntersectionObserver(entries => {
        if (hasStarted || !entries?.[0]?.isIntersecting) {
            return;
        }
        setHasStarted(true);
        start();
    }, {});
    return <span ref={elementRef}>{countUp}</span>;
};
export default StatValue;
