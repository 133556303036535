import { motion } from 'framer-motion';
import Image from 'gatsby-image';
import React, { useRef } from 'react';
import useFadeUp from '@hooks/animation/useFadeUp';
import SectionWrapper from '@presentation/SectionWrapper';
import formatTitle from '@utils/formatTitle';
import Link from '@presentation/Link';
import { ButtonVariant } from '@presentation/Button/Button.types';
const LogoCarousel = ({ content, items, itemsStatic, title, ctaEventName, ctaText, ctaTo, ctaIsExternal, }) => {
    const containerRef = useRef(null);
    const titleProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    const contentProps = useFadeUp({ ref: containerRef, delay: 0.2 });
    const carouselProps = useFadeUp({ ref: containerRef, delay: 0.3 });
    const staticItemsProps = useFadeUp({ ref: containerRef, delay: 0.4 });
    const ctaProps = useFadeUp({ ref: containerRef, delay: 0.5 });
    const duplicated = ensureItemCount(20, items);
    const multiplier = Math.max(duplicated.length / items.length, 2);
    const speed = duplicated.length * 1250;
    const transformPercent = 100 / multiplier;
    return (<SectionWrapper fullWidth>
      <div ref={containerRef} className="ticker">
        <div className="site-wide ticker__intro">
          {title && <motion.h2 {...titleProps}>{formatTitle(title)}</motion.h2>}
          {content && <motion.p {...contentProps}>{content}</motion.p>}
        </div>

        <motion.div className="ticker__container vert-padding-50" {...carouselProps}>
          <div className="ticker__track" style={{
        '--speed': `${speed}ms`,
        '--transform': `-${transformPercent}%`,
    }}>
            {duplicated.map((item, index) => (<div key={index} className="ticker__item">
                <div className="logo-card">
                  <div className="logo-card__inner">
                    <div className="logo-card__media-holder">
                      <Image className="logo-card__media-element" alt={item.alt} draggable={false} fixed={item.image} loading="eager"/>
                    </div>
                  </div>
                </div>
              </div>))}
          </div>
        </motion.div>
        {itemsStatic && (<motion.div {...staticItemsProps}>
            <div className="ticker__static">
              {itemsStatic.map((item, index) => (<div key={index} className="ticker__item">
                  <div className="logo-card">
                    <div className="logo-card__inner">
                      <div className="logo-card__media-holder">
                        <Image className="logo-card__media-element" alt={item.alt} fluid={item.image} loading="eager"/>
                      </div>
                    </div>
                  </div>
                </div>))}
            </div>
          </motion.div>)}
        {ctaText && ctaTo && (<motion.div className="ticker__cta" {...ctaProps}>
            <Link eventName={ctaEventName} isExternal={ctaIsExternal} text={ctaText} to={ctaTo} variant={ButtonVariant.OrangeSolid}/>
          </motion.div>)}
      </div>
    </SectionWrapper>);
};
export default LogoCarousel;
function ensureItemCount(count, items) {
    if ((items?.length ?? 0) === 0) {
        return [];
    }
    let output = [...items, ...items];
    while (output.length < count) {
        output = [...output, ...items];
    }
    return output;
}
