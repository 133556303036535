import React from 'react';
import TestimonialCarouselLayout from '@presentation/TestimonialCarousel';
import { graphql } from 'gatsby';
const TestimonialCarousel = ({ data }) => {
    const itemData = (data.elements?.testimonials?.linked_items ||
        []);
    const items = itemData.map(createItem);
    if (!items.length) {
        return null;
    }
    return <TestimonialCarouselLayout items={items}/>;
    function createItem(item) {
        return {
            citation: item.elements?.citation?.value || '',
            content: item.elements?.content?.value || '',
            id: item.system?.id || '',
            image: item.elements?.image?.value?.[0]?.fixed,
            logo: item.elements?.logo?.value?.[0]?.fixed,
            thumbnail: item.elements?.thumbnail?.value?.[0]?.fixed,
            title: item.elements?.title?.value || '',
        };
    }
};
export default TestimonialCarousel;
export const KontentItemTestimonialCarouselFragment = graphql `
  fragment KontentItemTestimonialCarouselFragment on KontentItemTestimonialCarousel {
    elements {
      testimonials {
        linked_items {
          system {
            id
          }
          ... on KontentItemTestimonial {
            elements {
              citation {
                value
              }
              content {
                value
              }
              image {
                value {
                  fixed(height: 400, width: 400, fit: "crop") {
                    ...KontentAssetFixed_withWebp_noBase64
                  }
                }
              }
              logo {
                value {
                  fixed(width: 120) {
                    ...KontentAssetFixed_withWebp_noBase64
                  }
                }
              }
              thumbnail {
                value {
                  fixed(height: 82, width: 82, fit: "crop") {
                    ...KontentAssetFixed_withWebp_noBase64
                  }
                }
              }
              title {
                value
              }
            }
          }
        }
      }
    }
  }
`;
