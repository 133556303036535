import { useLayoutEffect, useRef } from 'react';
function usePlyr(options = {}) {
    const containerRef = useRef(null);
    const instanceRef = useRef();
    useLayoutEffect(() => {
        if (!containerRef.current) {
            return;
        }
        // Async load tns to avoid server-render errors.
        import('plyr').then(({ default: Plyr }) => {
            if (!containerRef.current) {
                return;
            }
            instanceRef.current = new Plyr(containerRef.current, options);
        });
        return () => {
            instanceRef.current?.destroy();
        };
    }, []);
    return [containerRef, instanceRef];
}
export default usePlyr;
